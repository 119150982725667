import { useState } from 'react';

import Card from '@mui/material/Card';

import {
  StyledDashboard,
  StyledDragDrop,
} from '@components/pages/knowledge-base/components/uppy-upload/styles';
import CONFIG from '@components/search-form/react-hook-form-upload-select/components/file-upload/config';
import ImageUpload from '@components/search-form/react-hook-form-upload-select/components/file-upload/image';

import { storage } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import useAuth from '@hooks/useAuth';
import { useCreateOneSearchThread } from '@hooks/useCreateOneThread';
import useQueryParams from '@hooks/useQueryParams';

import { imageUploaded } from '@state/slices/search-result';

import { useTranslation } from '@desygner/ui-common-translation';

import { HistoryBlocksDataType } from '@shared-types/search-result/history-blocks';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import { toast } from 'react-toastify';

type ContextType =
  | {
      entityType: string;
      entityId: string;
      usage: string;
      saveAsSource: boolean;
    }
  | undefined;

type Props = {
  handleCloseModal: (value: boolean) => void;
};

const DRAG_DROP_HEIGHT = 300;
const DASHBOARD_HEIGHT = 270;

export default function FileUpload({ handleCloseModal }: Props) {
  const { userSpecificSettings } = useAppSettings();
  const { t } = useTranslation();

  const { getQueryParamByKey } = useQueryParams();

  const dispatch = useAppDispatch();

  const { me } = useAuth();

  const { mutateAsync: createOneSearchThreadAsync } =
    useCreateOneSearchThread();

  async function getThreadId() {
    const data = await createOneSearchThreadAsync({
      type: 'search',
      name: 'image',
    });

    return data?.data.id;
  }

  const imageUpload = new ImageUpload('', []);
  let threadId = '';

  const uploadedImages = useAppSelector((state) => state.searchResult.image);

  function onUploadComplete() {
    let updatedImagePreviews: ImageUpload['previews'];

    if (uploadedImages) {
      updatedImagePreviews = [
        ...uploadedImages.previews,
        ...imageUpload.previews,
      ];
    } else {
      updatedImagePreviews = imageUpload.previews;
    }

    dispatch(
      imageUploaded({
        threadId: threadId,
        currentImageKey: updatedImagePreviews[0].key,
        previews: updatedImagePreviews,
      }),
    );
  }

  const [uppy] = useState(() => {
    const uppyInstance = new Uppy({
      id: 'file-upload',
      restrictions: {
        maxNumberOfFiles: CONFIG.MAX_NUMBER_OF_FILES,
        allowedFileTypes: CONFIG.FILE_TYPES,
      },
      autoProceed: false,
    })
      .use(Webcam, {
        modes: ['picture'],
        videoConstraints: {
          facingMode: 'user', //? prefer front-facing camera on mobile
        },
      })
      .on('file-added', (file) => {
        if (uploadedImages?.previews.length === CONFIG.MAX_NUMBER_OF_FILES) {
          toast.error(
            t('response.error.maxNumberOfFiles', {
              defaultValue: 'You have reached the maximum number of files.',
            }),
          );
          uppy.removeFile(file.id);
        }
        if (
          uploadedImages?.previews.some((image) => image.name === file.name)
        ) {
          toast.error(
            t('response.error.fileAlreadyUploaded', {
              defaultValue: 'This file has already been uploaded.',
            }),
          );
          uppy.removeFile(file.id);
        }
      })
      .use(AwsS3, {
        getUploadParameters: async (file) => {
          const size = file.data.size;
          const sizeInKB = size / 1024;
          const name = file.data.name ?? file.name;
          const type = file.type;

          threadId =
            getQueryParamByKey('t') ??
            uploadedImages?.threadId ??
            (await getThreadId());

          if (!me || userSpecificSettings?.[me.uuid] == null) return;
          const { data } = await storage.createPolicy<ContextType>({
            name,
            size: sizeInKB,
            mimeType: type,
            context: {
              entityType: 'thread',
              entityId: threadId,
              usage: 'image_search',
              saveAsSource:
                userSpecificSettings[me.uuid]!.shouldSaveUploadsToKnowledgeBase,
            },
          });

          queryClient.setQueryData(
            ['uploadImage', threadId],
            (oldHistoryBlocks: HistoryBlocksDataType) => {
              const newHistoryBlocks =
                oldHistoryBlocks == null ? [] : oldHistoryBlocks;
              const historyBlocksData = newHistoryBlocks;
              return [
                ...historyBlocksData,
                {
                  role: 'user',
                  file: {
                    key: data.key,
                  },
                  preview: file.preview,
                },
              ];
            },
          );

          const imagePreview = {
            name: name,
            size: sizeInKB,
            type: type,
            preview: file.preview ?? '',
            key: data.key,
          };

          const isNameExists = imageUpload.previews.some(
            (preview) => preview.name === name,
          );
          if (!isNameExists) {
            imageUpload.previews.push(imagePreview);
          }

          return {
            method: 'PUT',
            fields: [],
            headers: {
              'Content-Type': type,
            },
            url: data.url,
          };
        },
      })
      .on('file-removed', (file) => {
        imageUpload.previews = imageUpload.previews.filter(
          (preview) => preview.name !== file.name,
        );
      })
      .on('complete', () => {
        onUploadComplete();
        handleCloseModal(false);
        toast.success(
          t('response.success.uploadedSource', {
            defaultValue: 'You have successfully uploaded a source.',
          }),
        );
      });

    return uppyInstance;
  });

  return (
    <Card sx={{ width: '100%' }}>
      <StyledDragDrop
        note={`${t('page.knowledgeBase.upload.note', {
          defaultValue: 'images, up to',
        })} 5 MB`}
        uppy={uppy as never}
        allowMultipleFiles
        locale={
          {
            strings: {
              dropHereOr: 'Drop files here or %{browse}',
              browse: t('page.knowledgeBase.upload.browseFiles', {
                defaultValue: 'browse files',
              }),
            },
          } as never
        }
        width="100%"
        height={DRAG_DROP_HEIGHT}
      />
      <StyledDashboard
        uppy={uppy as never}
        proudlyDisplayPoweredByUppy={false}
        width="100%"
        height={DASHBOARD_HEIGHT}
      />
    </Card>
  );
}

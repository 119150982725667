import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledToolbarExtensionToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: theme.spacing(1),
  marginLeft: theme.spacing(3),
  zIndex: theme.zIndex.fab,
}));

export default StyledToolbarExtensionToggleButton;

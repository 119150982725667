import { Fragment, useState } from 'react';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import CONFIG from '@components/pages/live/config';
import useAutoSelectAllTagsOnMount from '@components/pages/live/hooks/useAutoSelectAllTagsOnMount';
import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';
import TagListItem from '@components/pages/live/sections/cards/smart-tags-card/components/tag-list-item';
import Drawer from '@components/pages/live/sections/cards/smart-tags-card/kinds/category/drawer';
import {
  StyledCloseDrawerStack,
  StyledSkeleton,
  StyledSmartTagCardStack,
  StyledWrapperStack,
} from '@components/pages/live/sections/cards/smart-tags-card/kinds/category/styles';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';

import { groupedTrainingSetsUpdated } from '@state/slices/live';

import { useTranslation } from '@desygner/ui-common-translation';

import { SmartTagGroupIdType } from '@shared-types/live';

export default function Category() {
  const [openDrawerByCategoryId, setOpenDrawerByCategoryId] =
    useState<SmartTagGroupIdType | null>(null);

  const groupedTrainingSets = useAppSelector(
    (state) => state.live.groupedTrainingSets,
  );

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { collectedSmartTags, areCollectedSmartTagsSuccessfullyLoaded } =
    useCollectAllSmartTags();

  function handleCloseDrawer() {
    setOpenDrawerByCategoryId(null);
  }

  function handleOpenDrawer(category: SmartTagGroupIdType) {
    setOpenDrawerByCategoryId(category);
  }

  useAutoSelectAllTagsOnMount();

  function handleCategoryToggle({
    category,
    checked,
  }: {
    category: SmartTagGroupIdType;
    checked: boolean;
  }) {
    const ids = collectedSmartTags[category].items?.map((item) => item.id);
    if (!ids) return null;
    dispatch(
      groupedTrainingSetsUpdated({
        ...groupedTrainingSets,
        [category]: checked ? ids : [],
      }),
    );
  }

  function handleItemToggle({
    category,
    checked,
    itemId,
  }: {
    category: SmartTagGroupIdType;
    itemId: number;
    checked: boolean;
  }) {
    dispatch(
      groupedTrainingSetsUpdated({
        ...groupedTrainingSets,
        [category]: checked
          ? [...groupedTrainingSets[category], itemId]
          : groupedTrainingSets[category].filter((id) => id !== itemId),
      }),
    );
  }

  return (
    <Stack
      direction="row"
      gap={4}
      sx={{ flexWrap: 'wrap' }}
    >
      {Object.entries(collectedSmartTags).map((collectedSmartTag) => {
        const category = collectedSmartTag[0] as SmartTagGroupIdType;
        const { items: categoryItems, title: categoryTitle } =
          collectedSmartTag[1];
        return (
          <Drawer
            key={category}
            title={categoryTitle}
            onClick={() => handleOpenDrawer(category)}
            checkbox={
              areCollectedSmartTagsSuccessfullyLoaded ? (
                <Checkbox
                  icon={<StyledCheckboxIcon />}
                  checkedIcon={<StyledCheckbox />}
                  indeterminateIcon={<StyledIntermittentCheckbox />}
                  checked={
                    groupedTrainingSets[category].length ===
                    categoryItems?.length
                  }
                  indeterminate={
                    groupedTrainingSets[category].length > 0 &&
                    groupedTrainingSets[category].length !==
                      categoryItems?.length
                  }
                  onChange={(_event, checked) =>
                    handleCategoryToggle({ category, checked })
                  }
                />
              ) : (
                <StyledSkeleton
                  variant="rectangular"
                  width={18}
                  height={18}
                />
              )
            }
          />
        );
      })}
      <MuiDrawer
        open={!!openDrawerByCategoryId}
        onClose={handleCloseDrawer}
        anchor="right"
        keepMounted
      >
        {openDrawerByCategoryId && (
          <StyledWrapperStack>
            <StyledCloseDrawerStack direction="row">
              <Text
                variant="textSm"
                weight="semibold"
              >
                {collectedSmartTags[openDrawerByCategoryId].title}
              </Text>
              <IconButton
                color="tertiarySecondary"
                onClick={handleCloseDrawer}
              >
                <ArrowForwardOutlinedIcon />
              </IconButton>
            </StyledCloseDrawerStack>
            <StyledSmartTagCardStack direction="row">
              <Text
                variant="textXs"
                weight="semibold"
                color="text.disabled"
              >
                {t(
                  'page.live.grids.mode.cards.knowzAssistant.drawer.selectAll',
                  {
                    defaultValue: 'Select all',
                  },
                )}
              </Text>
              <Checkbox
                onChange={(_event, checked) =>
                  handleCategoryToggle({
                    category: openDrawerByCategoryId,
                    checked,
                  })
                }
                checked={
                  collectedSmartTags[openDrawerByCategoryId].items.length ===
                  groupedTrainingSets?.[openDrawerByCategoryId].length
                }
                indeterminate={
                  groupedTrainingSets[openDrawerByCategoryId].length > 0 &&
                  !(
                    collectedSmartTags[openDrawerByCategoryId].items.length ===
                    groupedTrainingSets[openDrawerByCategoryId].length
                  )
                }
                value={openDrawerByCategoryId}
                icon={<StyledCheckboxIcon />}
                checkedIcon={<StyledCheckbox />}
                indeterminateIcon={<StyledIntermittentCheckbox />}
              />
            </StyledSmartTagCardStack>
            <Divider />
            <Box>
              {collectedSmartTags[openDrawerByCategoryId].items.map(
                (item, index, originalItems) => {
                  const { id, name } = item as {
                    id: number;
                    name: keyof typeof CONFIG.SMART_TAGS;
                  };

                  return (
                    <Fragment key={name}>
                      <TagListItem
                        checkbox={{
                          Component: (
                            <Checkbox
                              onChange={(_event, checked) => {
                                handleItemToggle({
                                  category: openDrawerByCategoryId!,
                                  itemId: id,
                                  checked,
                                });
                              }}
                              checked={groupedTrainingSets[
                                openDrawerByCategoryId
                              ].includes(id)}
                              icon={<StyledCheckboxIcon />}
                              checkedIcon={<StyledCheckbox />}
                              indeterminateIcon={<StyledIntermittentCheckbox />}
                            />
                          ),
                          position: 'end',
                        }}
                        title={{
                          text: name,
                          variant: 'textMd',
                          weight: 'semibold',
                        }}
                        subtitle={CONFIG.SMART_TAGS[name].description}
                      />
                      {index !== originalItems.length - 1 && <Divider />}
                    </Fragment>
                  );
                },
              )}
            </Box>
          </StyledWrapperStack>
        )}
      </MuiDrawer>
    </Stack>
  );
}

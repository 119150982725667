import { useMemo, useState } from 'react';

import { useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import Input from '@components/input';
import Text from '@components/text';

import useLocalStorage from '@hooks/useLocalStorage';

import { WorkspaceResponseType } from '@shared-types/workspace';

import CheckRoundedIcon from '~icons/knowz-iconify/check-rounded';
import ChevDownIcon from '~icons/knowz-iconify/chev-down';
import ChevTopIcon from '~icons/knowz-iconify/chev-up';

import usePopover from '@layouts/main/sections/toolbar/components/workspaces-popover/hooks/usePopover';
import CONFIG from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/config';
import { StyledChip } from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/styles';
import {
  StyledBox,
  StyledMenuItem,
  StyledSelect,
  StyledStack,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/dropdown/styles';
import {
  DefaultWorkspaceType,
  MappedOptionsType,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/dropdown/types';
import StyledAvatar from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/styles';

type Props = {
  options: WorkspaceResponseType[];
  children: React.ReactElement;
};

const SHARED_PROPS: MenuItemProps = {
  disableGutters: true,
  disableRipple: true,
  disableTouchRipple: true,
  sx: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const DEFAULT_WORKSPACE: DefaultWorkspaceType = {
  name: 'Your Knowledge',
  id: 'default-workspace-id',
  profilePicture: null,
  createdAt: '',
  role: 'owner',
  totalUsers: 1,
} as const;

export default function Dropdown({ options = [], children }: Props) {
  const theme = useTheme();
  const { handleSetSelectedWorkspaceId } = usePopover();
  const { value: workspaceInLS } = useLocalStorage<WorkspaceResponseType>(
    'workspace',
    null,
  );

  const allWorkspaces = [DEFAULT_WORKSPACE, ...options];

  const [selected, setSelected] = useState<
    (typeof DEFAULT_WORKSPACE)['id'] | string
  >(() => {
    if (typeof workspaceInLS?.id === 'string') {
      return workspaceInLS.id;
    }

    return 'default-workspace-id';
  });

  const mappedOptionsToId = useMemo(
    () =>
      allWorkspaces.reduce(
        (acc: MappedOptionsType, { id, name, profilePicture }) => {
          acc[id] = {
            id,
            profilePicture,
            name,
          };
          return acc;
        },
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length],
  );

  return (
    <StyledSelect
      variant="outlined"
      MenuProps={{
        PaperProps: {
          sx: {
            marginBlockStart: 4,
            border: (theme) => `1px solid ${theme.palette.border.secondary}`,
            padding: 2,
          },
        },
        sx: {
          height: CONFIG.DROPDOWN.HEIGHT,
        },
      }}
      value={selected ?? ''}
      IconComponent={(props) => (
        <StyledBox>
          {props.className.match(/open/gi) ? <ChevTopIcon /> : <ChevDownIcon />}
        </StyledBox>
      )}
      input={<Input />}
      onChange={(e) => {
        handleSetSelectedWorkspaceId(e.target.value as string);
        return setSelected(e.target.value as string);
      }}
      renderValue={(selected) => {
        const renderedValue =
          mappedOptionsToId[selected as string] || workspaceInLS;

        return (
          <MenuItem
            value={selected as string}
            {...SHARED_PROPS}
          >
            <Stack direction="row">
              <ListItemIcon>
                <StyledAvatar
                  src={renderedValue?.profilePicture ?? undefined}
                />
              </ListItemIcon>
              <Text variant="textSm">{renderedValue.name}</Text>
            </Stack>
          </MenuItem>
        );
      }}
    >
      {allWorkspaces.map((option) => {
        const { id, name, profilePicture, totalUsers } = option;
        const isItSelected = selected === id;
        return (
          <StyledMenuItem
            key={id}
            value={id}
            {...SHARED_PROPS}
            sx={{
              borderRadius: isItSelected ? 1.5 : undefined,
            }}
          >
            <StyledStack direction="row">
              <ListItemIcon>
                <StyledAvatar src={profilePicture ?? undefined} />
              </ListItemIcon>
              <StyledStack direction="row">
                <Text variant="textSm">{name}</Text>
                <Stack
                  direction="row"
                  gap={3}
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  {/* it needs to modify MUI */}
                  <StyledChip
                    size="small"
                    label={totalUsers}
                  />
                  {isItSelected && (
                    <CheckRoundedIcon color={theme.palette.primary.main} />
                  )}
                </Stack>
              </StyledStack>
            </StyledStack>
          </StyledMenuItem>
        );
      })}
      {children}
    </StyledSelect>
  );
}

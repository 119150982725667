import { useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import Text from '@components/text';
import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import CONFIG from '@components/workspace/setup/config';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import ImagePreview from '@components/workspace/setup/sections/steps/components/image-preview';
import {
  StyledImg,
  StyledStack,
  StyledStackWrapper,
} from '@components/workspace/setup/sections/steps/cropper/styles';

import { storage } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import 'cropperjs/dist/cropper.css';

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import ReactCropper, { ReactCropperElement } from 'react-cropper';

const AVATAR_SIZE = 48;
const CROPPER_HEIGHT = 200;
const CROPPER_WIDTH = 284;

type Kind = 'image' | 'icon';

export default function Cropper() {
  const { t } = useTranslation();
  const {
    handleGoToPreviousStep,
    handleGoToStep,
    uploadedAvatar,
    selectedUploadOption,
    selectedIcon,
  } = useWorkspaceSetup();
  const [isUploadPending, setIsUploadPending] = useState(false);
  const whatIsUserPreferance: Kind = uploadedAvatar ? 'image' : 'icon';
  const cropperRef = useRef<ReactCropperElement>(null);
  const [cropper, setCropper] = useState<string>();
  const [uppy] = useState(() =>
    new Uppy().use(AwsS3, {
      async getUploadParameters(file) {
        const name = file.data.name;
        const size = file.data.size;
        const sizeInKB = size / 1024;
        const type = file.type;

        const { data } = await storage.createPolicy<{
          usage: 'profile_picture';
        }>({
          size: sizeInKB,
          mimeType: type,
          name,
          context: {
            usage: 'profile_picture',
          },
        });
        return {
          method: 'PUT',
          fields: [],
          headers: {
            'Content-Type': type,
          },
          url: data.url,
        };
      },
    }),
  );
  function handleBackRedirectToPreviousStep() {
    if (selectedUploadOption == null) return;
    handleGoToPreviousStep();
  }
  function handleCrop(type: Kind) {
    const cropper = cropperRef.current?.cropper;
    return () => {
      if (type === 'image') {
        const dataUrl = cropper
          ?.getCroppedCanvas()
          .toDataURL(CONFIG.AVATAR.FORMAT, CONFIG.AVATAR.QUALITY);
        setCropper(dataUrl);
        cropper?.getCroppedCanvas().toBlob(
          async (blob) => {
            if (!blob || !uploadedAvatar) return;
            uppy.addFile({
              data: blob,
              name: uploadedAvatar.name,
              type: CONFIG.AVATAR.FORMAT,
            });
          },
          CONFIG.AVATAR.FORMAT,
          CONFIG.AVATAR.QUALITY,
        );
        return;
      }

      // const dataUrl = cropper?.getCroppedCanvas().toDataURL();
      // .toBlob((blob) => {
      //   console.log(blob);
      // });
      // console.log(dataUrl);
      // .toDataURL(CONFIG.AVATAR.FORMAT, CONFIG.AVATAR.QUALITY);
      // setCropper(dataUrl);
    };
  }

  function handleInitCropper(type: Kind) {
    return (_instance: Cropper) => {
      //? upload an imane
      if (type === 'image' && uploadedAvatar) {
        setCropper(URL.createObjectURL(uploadedAvatar));
        return;
      }

      //? select an icon
      setCropper(selectedIcon);
    };
  }

  async function handleNext() {
    setIsUploadPending(true);
    await uppy.upload();
    setIsUploadPending(false);
    handleGoToStep('invitation');
  }

  return (
    <>
      <Header
        Icon={
          cropper ? (
            <StyledImg
              src={cropper}
              alt="avatar preview"
              width={AVATAR_SIZE}
              height={AVATAR_SIZE}
            />
          ) : (
            <ImagePreview />
          )
        }
        title={t('page.workspace.createWorkspaceModal.step2.title', {
          defaultValue: 'Customize your Knowledge Base',
        })}
        subtitle={t('page.workspace.createWorkspaceModal.step2.description', {
          defaultValue:
            'Make your Knowledge Base instantly recognizable with a unique icon - use your logo, branding elements, a photo, or let Knowz create one for you.',
        })}
      />
      <StyledStackWrapper>
        <StyledStack gap={2.5}>
          <Text
            variant="textMd"
            weight="semibold"
          >
            {t(
              'page.workspace.createWorkspaceModal.step2.subSteps.step3.title',
              {
                defaultValue: 'Crop Image',
              },
            )}
          </Text>
          <ReactCropper
            checkCrossOrigin={false}
            onInitialized={handleInitCropper(whatIsUserPreferance)}
            src={cropper}
            style={{ height: CROPPER_HEIGHT, width: CROPPER_WIDTH }}
            initialAspectRatio={16 / 9}
            ref={cropperRef}
          />
          <Button
            fullWidth
            color="inherit"
            size="small"
            variant="outlined"
            onClick={handleCrop(whatIsUserPreferance)}
          >
            {t(
              'page.workspace.createWorkspaceModal.step2.subSteps.step3.buttons.apply',
              {
                defaultValue: 'Apply',
              },
            )}
          </Button>
        </StyledStack>
      </StyledStackWrapper>
      <ButtonWrapper>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ButtonBase
            disableRipple
            disableTouchRipple
          >
            {t('page.workspace.createWorkspaceModal.skipButton', {
              defaultValue: 'Skip Step',
            })}
          </ButtonBase>
          <Stack
            direction="row"
            gap={3}
          >
            <Button
              size="large"
              onClick={handleBackRedirectToPreviousStep}
            >
              {t('page.workspace.createWorkspaceModal.backButton', {
                defaultValue: 'Back',
              })}
            </Button>
            <LoadingButton
              onClick={handleNext}
              disabled={isUploadPending}
              loading={isUploadPending}
              size="large"
              variant="contained"
            >
              {t('page.workspace.createWorkspaceModal.nextButton', {
                defaultValue: 'Next',
              })}
            </LoadingButton>
          </Stack>
        </Stack>
      </ButtonWrapper>
    </>
  );
}

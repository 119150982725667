import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  marginInline: theme.spacing(6),
  marginBlockStart: theme.spacing(5),
}));

export const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(4),
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
}));

export const StyledStackIcons = styled(Stack)(({ theme }) => ({
  flex: 1,
  height: 'auto',
  gap: theme.spacing(6.25),
  flexDirection: 'column-reverse',
  [theme.breakpoints.up('md')]: {
    height: theme.spacing(82),
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
}));

export const StyledStackDescriptionField = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(117.5),
  },
}));

export const StyledUploadBox = styled(Box)(({ theme }) => ({
  paddingBlockStart: theme.spacing(4),
  paddingInlineStart: theme.spacing(4),
}));

export const StyledHeaderBox = styled(Box)(() => ({
  flex: 1,
}));

import Stack from '@mui/material/Stack';

import Text from '@components/text';

export default function GroupWrapper({
  title,
  children,
}: React.PropsWithChildren<{ title: React.ReactNode }>) {
  return (
    <Stack sx={{ marginBlockEnd: 1 }}>
      <Text
        variant="textSm"
        color="text.disabled"
      >
        {title}
      </Text>
      {children}
    </Stack>
  );
}

import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.text.secondary,
  justifyContent: 'start',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  marginBlockStart: theme.spacing(5),
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.utility.gray50,
  color: theme.palette.utility.gray800,
  borderColor: theme.palette.utility.gray200,
  border: '1px solid',
  minWidth: theme.spacing(7),
  minHeight: theme.spacing(7),
}));

import { useState } from 'react';

import { SelectChangeEvent, useTheme } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Input from '@components/input';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledListItem,
  StyledListItemText,
  StyledPaper,
  StyledPopper,
  StyledRoleItemStack,
  StyledSubtitleText,
  StyledTitleText,
  SytyledBoxWrapper,
} from '@components/invitation-form/components/dropdown/styles';
import { StyledCheckboxIcon } from '@components/pages/styles';

import { RoleType } from '@shared-types/memberships';

import CheckRounded from '~icons/knowz-iconify/check-rounded';
import ChevDownIcon from '~icons/knowz-iconify/chev-down';
import ChevUpIcon from '~icons/knowz-iconify/chev-up';

type Props = {
  role: RoleType;
  roleItems: Array<{
    value: RoleType;
    label: string;
    subtitle: string;
    content: string[];
  }>;
  disabled?: boolean;
  onChange?: (role: RoleType) => void;
};

export default function Dropdown({
  role,
  roleItems,
  disabled = false,
  onChange,
}: Props) {
  const [value, setValue] = useState<RoleType>(role);
  const [isExpanded, setIsExpanded] = useState<RoleType | boolean>(role);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  function handleValueChange(role: RoleType) {
    return (_event: SelectChangeEvent | React.MouseEvent<unknown>) => {
      setValue(role);
      handleAccordionChange(role)(null, true);
      onChange && onChange(role);
    };
  }

  function handleCloseDropdown() {
    setAnchorEl(null);
  }

  function handleOpenDropdown(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleAccordionChange(panel: RoleType) {
    return (_event: React.SyntheticEvent | null, isExpanded: boolean) => {
      setIsExpanded(isExpanded ? panel : false);
    };
  }

  const isItOpen = Boolean(anchorEl);

  return (
    <SytyledBoxWrapper>
      <Input
        fullWidth
        readOnly
        inputProps={{
          sx: {
            textTransform: 'capitalize',
          },
        }}
        onClick={handleOpenDropdown}
        value={value}
        endAdornment={<ChevDownIcon fontSize="0.7em" />}
        disabled={disabled}
      />
      <StyledPopper
        anchorEl={anchorEl}
        open={isItOpen}
      >
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <StyledPaper>
            {roleItems.map(
              ({ value: itemValue, subtitle, content, label }, index) => (
                <StyledRoleItemStack
                  key={index}
                  component={Paper}
                  direction="row"
                  sx={{
                    backgroundColor:
                      itemValue === value
                        ? 'utility.brand100Alt'
                        : 'transpanret',
                  }}
                  square={true}
                >
                  <StyledAccordion
                    square={true}
                    defaultExpanded={true}
                    elevation={0}
                    disableGutters
                    expanded={isExpanded === itemValue}
                    onChange={handleAccordionChange(itemValue)}
                  >
                    <StyledAccordionSummary
                      disableRipple
                      disableTouchRipple
                      expandIcon={null}
                    >
                      <Stack
                        direction="column"
                        gap={2}
                      >
                        <StyledTitleText
                          variant="textSm"
                          weight="semibold"
                        >
                          {isExpanded === itemValue ? (
                            <ChevUpIcon />
                          ) : (
                            <ChevDownIcon />
                          )}
                          &nbsp;
                          {label}
                        </StyledTitleText>
                        <StyledSubtitleText variant="textSm">
                          {subtitle}
                        </StyledSubtitleText>
                      </Stack>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      <List>
                        {content.map((li, index) => (
                          <StyledListItem
                            disableGutters
                            disablePadding
                            key={index}
                          >
                            <StyledListItemText
                              primaryTypographyProps={{ variant: 'textSm' }}
                            >
                              {li}
                            </StyledListItemText>
                          </StyledListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </StyledAccordion>
                  <ButtonBase
                    disableRipple
                    disableTouchRipple
                    onClick={handleValueChange(itemValue)}
                  >
                    {itemValue === value ? (
                      <CheckRounded
                        fontSize="1.3em"
                        color={theme.palette['Colors/Border/border-brand']}
                      />
                    ) : (
                      <StyledCheckboxIcon />
                    )}
                  </ButtonBase>
                </StyledRoleItemStack>
              ),
            )}
          </StyledPaper>
        </ClickAwayListener>
      </StyledPopper>
    </SytyledBoxWrapper>
  );
}

import Field from '@components/invitation-form/components/dropdown-list-items/field';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

type Props = {
  handleDisableSendInvitesButtonImparatively: (value: boolean) => void;
};

export default function DropdownListItems({
  handleDisableSendInvitesButtonImparatively,
}: Props) {
  const { membersToInvite } = useWorkspaceSetup();

  return membersToInvite.map((member) => (
    <Field
      handleDisableSendInvitesButtonImparatively={
        handleDisableSendInvitesButtonImparatively
      }
      key={member.id}
      {...member}
    />
  ));
}

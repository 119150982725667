import { alpha, Theme } from '@mui/material';

const TODO_DESIGN_SYSTEM = {
  // TODO: design system
  light: {
    secondaryBg: '#FFFFFF',
    secondaryFg: '#414651',
    secondaryBorder: '#D5D7DA',
    shadowColor: '#0A0D12',
    secondaryBgHover: '#FAFAFA',
    secondaryBorderHover: '#D5D7DA',
  },

  dark: {
    secondaryBg: '#13161B',
    secondaryFg: '#CECFD2',
    secondaryBorder: '#373A41',
    shadowColor: '#0A0D12',
    secondaryBgHover: '#22262F',
    secondaryBorderHover: '#373A41',
  },
};

export default function Button(theme: Theme) {
  const isLight = theme.palette.mode === 'light';
  const ds = TODO_DESIGN_SYSTEM[theme.palette.mode];

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: {
                color: 'secondary',
              },
              style: {
                background: ds.secondaryBg,
                border: `1px solid ${ds.secondaryBorder}`,
                color: ds.secondaryFg,
                boxShadow: `0px 1px 2px 0px ${alpha(ds.shadowColor, 0.05)},
                  0px -2px 0px 0px ${alpha(ds.shadowColor, 0.05)} inset,
                  0px 0px 0px 1px ${alpha(ds.shadowColor, 0.18)} inset !important`,

                '&:hover': {
                  background: ds.secondaryBgHover,
                  borderColor: ds.secondaryBorderHover,
                },
              },
            },
            {
              props: {
                size: 'xxlarge',
              },
              style: {
                fontSize: theme.typography.textLg.fontSize,
                lineHeight: theme.typography.textLg.lineHeight,
                minHeight: 60,
                padding: theme.spacing(4, 5.5),
              },
            },
            {
              props: { size: 'xlarge' },
              style: {
                fontSize: theme.typography.textMd.fontSize,
                lineHeight: theme.typography.textMd.lineHeight,
                minHeight: 48,
                padding: theme.spacing(3, 4.5),
              },
            },
            {
              props: { size: 'large' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 44,
                padding: theme.spacing(2.5, 4),
              },
            },
            {
              props: { size: 'medium' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 40,
                padding: theme.spacing(2.5, 3.5),
              },
            },
            {
              props: { size: 'small' },
              style: {
                fontSize: theme.typography.textSm.fontSize,
                lineHeight: theme.typography.textSm.lineHeight,
                minHeight: 36,
                padding: theme.spacing(2, 3),
              },
            },
          ],
          fontWeight: 600,
          borderRadius: theme.shape.borderRadius * 2,
          textTransform: 'none',

          '&, &:hover, &:focus': {
            boxShadow: 'none',
          },

          // TODO: replace classNames with imported variables from mui
          '&.MuiButton-text, &.MuiButton-outlined': {
            '&.MuiButton-colorPrimary': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            },
            '&.MuiButton-colorError': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.error.main, 0.08),
              },
            },
            '&.MuiButton-colorSuccess': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.success.main, 0.08),
              },
            },
            '&.MuiButton-colorWarning': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.warning.main, 0.08),
              },
            },
            '&.MuiButton-colorInfo': {
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.info.main, 0.08),
              },
            },
            '&.MuiButton-colorInherit': {
              backgroundColor: alpha(
                isLight
                  ? theme.palette.common.black
                  : theme.palette.common.white,
                0.05,
              ),
              borderColor: alpha(theme.palette.text.primary, 0.23),
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
  };
}

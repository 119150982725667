import { createContext, useCallback, useMemo, useReducer } from 'react';

import ModalWrapper from '@components/workspace/setup/sections/modal-stepper';
import Steps from '@components/workspace/setup/sections/steps';
import { ActiveStepType } from '@components/workspace/setup/types';

import { RoleType } from '@shared-types/memberships';
import { ActionMap } from '@shared-types/utils';

import { Meta, UppyFile } from '@uppy/core';

type MemberToInviteType = {
  id: string;
  email: string;
  role: RoleType;
  isInvitationSent: boolean;
};

type MembersToInviteType = Array<MemberToInviteType>;

type WorkspaceDetailsType = {
  activeStep: ActiveStepType;
  historySteps: ActiveStepType[];
  currentCreatedWorkspaceDetails: Partial<{
    id: string | null;
    name: string | null;
    avatarBlob: UppyFile<Meta, Record<string, never>> | null;
  }> | null;
  membersToInvite: MembersToInviteType;
  uploadedAvatar: File | null;
  selectedUploadOption: 'upload' | 'generateIcon' | null;
  generatedIcons: string[];
  generatedIconsDescription: string;
  iconGenerationStatus: 'pending' | 'finished' | 'idle';
  selectedIcon: string;
};

type ContextType = {
  activeStep: ActiveStepType;
  historySteps: WorkspaceDetailsType['historySteps'];
  handleGoToStep: (activeStep: ActiveStepType) => void;
  handleGoToPreviousStep: VoidFunction;
  handleReset: VoidFunction;
  handleSetUploadedAvatar: (file: File | null) => void;
  uploadedAvatar: WorkspaceDetailsType['uploadedAvatar'];
  selectedUploadOption: 'upload' | 'generateIcon' | null;
  generatedIcons: WorkspaceDetailsType['generatedIcons'];
  selectedIcon: WorkspaceDetailsType['selectedIcon'];
  generatedIconsDescription: WorkspaceDetailsType['generatedIconsDescription'];
  iconGenerationStatus: WorkspaceDetailsType['iconGenerationStatus'];
  handleSetCurrentCreatedWorkspaceDetails: (
    details: WorkspaceDetailsType['currentCreatedWorkspaceDetails'],
  ) => void;
  currentCreatedWorkspaceDetails: WorkspaceDetailsType['currentCreatedWorkspaceDetails'];
  membersToInvite: WorkspaceDetailsType['membersToInvite'];
  handleSelectedUploadOption: (option: 'upload' | 'generateIcon') => void;
  handleDeleteMember: (email: string) => void;
  handleSetMemberToInvite: (payload: MemberToInviteType) => void;
  handleUpdateMember: (payload: MemberToInviteType) => void;
  handleSetGeneratedIcon: (generatedIcons: string[]) => void;
  handleSetGeneratedIconDescription: (description: string) => void;
  handleSetIconGenerationStatus: (
    status: WorkspaceDetailsType['iconGenerationStatus'],
  ) => void;
  handleSetSelectedIcon: (icon: string) => void;
};

export const WorkspaceSetupContext = createContext<ContextType | null>(null);

enum Types {
  RESET = 'SET_RESET',
  STEP = 'SET_STEP',
  HISTORY_STEPS = 'SET_HISTORY_STEPS',
  UPLOADED_AVATAR = 'SET_UPLOADED_AVATAR',
  SELECTED_UPLOAD_OPTION = 'SET_SELECTED_UPLOAD_OPTION',
  GENERATED_ICON_DESCRIPTION = 'SET_GENERATED_ICON_DESCRIPTION',
  GENERATED_ICONS = 'SET_GENERATED_ICONS',
  ICON_GENERATION_STATUS = 'SET_ICON_GENERATION_STATUS',
  SELECTED_ICON = 'SET_SELECTED_ICON',
  CROPPED_ICON = 'SET_CROPPED_ICON',
  CURRENT_CREATED_WORKSPACE_DETAILS = 'SET_CURRENT_CREATED_WORKSPACE_DETAILS',
  SET_MEMBER = 'SET_MEMBER',
  EDIT_MEMBER = 'SET_EDIT_MEMBER',
  DELETE_MEMBER = 'SET_DELETE_MEMBER',
  UPDATE_MEMBER = 'SET_UPDATE_MEMBER',
  CLOSE_MODAL = 'SET_CLOSE_MODAL',
  OPEN_MODAL = 'SET_OPEN_MODAL',
}

type WorkspaceDetailsPayload = {
  [Types.RESET]: undefined;
  [Types.STEP]: {
    activeStep: WorkspaceDetailsType['activeStep'];
  };
  [Types.HISTORY_STEPS]: {
    historySteps: WorkspaceDetailsType['historySteps'];
  };
  [Types.UPLOADED_AVATAR]: {
    file: File | null;
  };
  [Types.GENERATED_ICONS]: {
    generatedIcons: string[];
  };
  [Types.GENERATED_ICON_DESCRIPTION]: {
    generatedIconsDescription: string;
  };
  [Types.SELECTED_UPLOAD_OPTION]: {
    selectedUploadOption: 'upload' | 'generateIcon';
  };
  [Types.ICON_GENERATION_STATUS]: WorkspaceDetailsType['iconGenerationStatus'];
  [Types.CURRENT_CREATED_WORKSPACE_DETAILS]: WorkspaceDetailsType['currentCreatedWorkspaceDetails'];
  [Types.SELECTED_ICON]: string;
  [Types.DELETE_MEMBER]: {
    email: string;
  };
  [Types.SET_MEMBER]: MemberToInviteType;
  [Types.UPDATE_MEMBER]: MemberToInviteType;
  [Types.CLOSE_MODAL]: VoidFunction;
  [Types.OPEN_MODAL]: VoidFunction;
};

export type WorkspaceDetailsActions =
  ActionMap<WorkspaceDetailsPayload>[keyof ActionMap<WorkspaceDetailsPayload>];

const initialState: WorkspaceDetailsType = {
  activeStep: 'createWorkspace',
  historySteps: [],
  currentCreatedWorkspaceDetails: null,
  membersToInvite: [],
  uploadedAvatar: null,
  selectedUploadOption: null,
  generatedIcons: [],
  generatedIconsDescription: '',
  iconGenerationStatus: 'idle',
  selectedIcon: '',
};

export default function WorkspaceSetupProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleReset = useCallback(() => {
    dispatch({
      type: Types.RESET,
    });
  }, []);

  const handleSetCurrentCreatedWorkspaceDetails = useCallback(
    (details: WorkspaceDetailsType['currentCreatedWorkspaceDetails']) => {
      dispatch({
        type: Types.CURRENT_CREATED_WORKSPACE_DETAILS,
        payload: details,
      });
    },
    [],
  );

  const handleDeleteMember = useCallback((email: string) => {
    dispatch({
      type: Types.DELETE_MEMBER,
      payload: {
        email,
      },
    });
  }, []);

  const handleSetMemberToInvite = useCallback((payload: MemberToInviteType) => {
    dispatch({
      type: Types.SET_MEMBER,
      payload,
    });
  }, []);

  const handleUpdateMember = useCallback((payload: MemberToInviteType) => {
    dispatch({
      type: Types.UPDATE_MEMBER,
      payload,
    });
  }, []);

  const handleSetUploadedAvatar = useCallback((file: File | null) => {
    dispatch({
      type: Types.UPLOADED_AVATAR,
      payload: {
        file,
      },
    });
  }, []);

  const handleSetGeneratedIcon = useCallback((generatedIcons: string[]) => {
    dispatch({
      type: Types.GENERATED_ICONS,
      payload: {
        generatedIcons,
      },
    });
  }, []);

  const handleSelectedUploadOption = useCallback(
    (option: 'upload' | 'generateIcon') => {
      dispatch({
        type: Types.SELECTED_UPLOAD_OPTION,
        payload: {
          selectedUploadOption: option,
        },
      });
    },
    [],
  );

  const handleSetGeneratedIconDescription = useCallback(
    (description: string) => {
      dispatch({
        type: Types.GENERATED_ICON_DESCRIPTION,
        payload: {
          generatedIconsDescription: description,
        },
      });
    },
    [],
  );

  const handleSetIconGenerationStatus = useCallback(
    (status: WorkspaceDetailsType['iconGenerationStatus']) => {
      dispatch({
        type: Types.ICON_GENERATION_STATUS,
        payload: status,
      });
    },
    [],
  );

  const handleSetSelectedIcon = useCallback((icon: string) => {
    dispatch({
      type: Types.SELECTED_ICON,
      payload: icon,
    });
  }, []);

  const handleGoToStep = useCallback(
    (step: ActiveStepType) => {
      //? add steps to history
      dispatch({
        type: Types.HISTORY_STEPS,
        payload: {
          historySteps: [...state.historySteps, state.activeStep],
        },
      });
      dispatch({
        type: Types.STEP,
        payload: {
          activeStep: step,
        },
      });
    },
    [state.activeStep, state.historySteps],
  );

  const handleGoToPreviousStep = useCallback(() => {
    const lastStep = state.historySteps[state.historySteps.length - 1];
    const lastStepIndex = state.historySteps.indexOf(lastStep);
    const stepToGo = lastStepIndex === -1 ? 'createWorkspace' : lastStep;

    handleGoToStep(stepToGo);
    dispatch({
      type: Types.HISTORY_STEPS,
      payload: {
        historySteps: state.historySteps.slice(0, lastStepIndex),
      },
    });
  }, [handleGoToStep, state.historySteps]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      handleReset,
      handleSetCurrentCreatedWorkspaceDetails,
      handleDeleteMember,
      handleSetMemberToInvite,
      handleUpdateMember,
      handleSetUploadedAvatar,
      handleSelectedUploadOption,
      handleSetGeneratedIcon,
      handleSetGeneratedIconDescription,
      handleSetIconGenerationStatus,
      handleSetSelectedIcon,
      handleGoToStep,
      handleGoToPreviousStep,
    }),
    [
      state,
      handleDeleteMember,
      handleSelectedUploadOption,
      handleReset,
      handleSetCurrentCreatedWorkspaceDetails,
      handleSetMemberToInvite,
      handleSetUploadedAvatar,
      handleUpdateMember,
      handleSetGeneratedIcon,
      handleSetGeneratedIconDescription,
      handleSetIconGenerationStatus,
      handleSetSelectedIcon,
      handleGoToStep,
      handleGoToPreviousStep,
    ],
  );

  return (
    <WorkspaceSetupContext.Provider value={memoizedValue}>
      {children}
    </WorkspaceSetupContext.Provider>
  );
}

WorkspaceSetupProvider.ModalWrapper = ModalWrapper;
WorkspaceSetupProvider.Steps = Steps;

function reducer(state: WorkspaceDetailsType, action: WorkspaceDetailsActions) {
  switch (action.type) {
    case Types.RESET:
      return initialState;
    case Types.STEP:
      return { ...state, activeStep: action.payload.activeStep };
    case Types.HISTORY_STEPS:
      return { ...state, historySteps: action.payload.historySteps };
    case Types.UPLOADED_AVATAR:
      return { ...state, uploadedAvatar: action.payload.file };
    case Types.SELECTED_UPLOAD_OPTION:
      return {
        ...state,
        selectedUploadOption: action.payload.selectedUploadOption,
      };
    case Types.GENERATED_ICONS:
      return { ...state, generatedIcons: action.payload.generatedIcons };
    case Types.GENERATED_ICON_DESCRIPTION:
      return {
        ...state,
        generatedIconsDescription: action.payload.generatedIconsDescription,
      };
    case Types.CURRENT_CREATED_WORKSPACE_DETAILS:
      return {
        ...state,
        currentCreatedWorkspaceDetails: {
          id: action.payload?.id,
          name: action.payload?.name,
          avatarBlob: action.payload?.avatarBlob,
        },
      };
    case Types.ICON_GENERATION_STATUS:
      return { ...state, iconGenerationStatus: action.payload };
    case Types.SELECTED_ICON:
      return { ...state, selectedIcon: action.payload };
    case Types.DELETE_MEMBER:
      return {
        ...state,
        membersToInvite: state.membersToInvite.filter(
          (m) => m.email !== action.payload.email,
        ),
      };
    case Types.SET_MEMBER:
      return {
        ...state,
        membersToInvite: [action.payload, ...state.membersToInvite],
      };
    case Types.UPDATE_MEMBER: {
      const index = state.membersToInvite.findIndex(
        (member) => member.id === action.payload.id,
      );

      if (index === -1) return state;
      return {
        ...state,
        membersToInvite: [
          ...state.membersToInvite.slice(0, index),
          action.payload,
          ...state.membersToInvite.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}

import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

export const StyledText = styled(Text)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
}));

import { Link, useNavigate } from 'react-router-dom';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Theme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import SimpleDropdown from '@components/dropdown/simple';
import { BreadcrumbItemType } from '@components/pages/knowledge-base/components/breadcrumb/types';
import Text from '@components/text';

import useQueryParams from '@hooks/useQueryParams';
import useSearchParamsState from '@hooks/useSearchParamsState';

import paths from '@router/paths';
import qs from 'qs';

type Props = {
  items: BreadcrumbItemType[];
};

const NUMBER_OF_CONDENSED_ITEMS = 2;

const START_TO_CONDENSED_ITEMS = NUMBER_OF_CONDENSED_ITEMS - 1;

export default function CondensedBreadcrumb({ items }: Props) {
  const [searchParamsPath] = useSearchParamsState('path', '');

  const { allQueryParams } = useQueryParams();

  const firstItem = items[0];

  const navigateTo = useNavigate();

  function calculateBreadcrumb({ path }: { path: string }) {
    const arrayOfPaths = searchParamsPath.split('/');

    if (arrayOfPaths.includes(path)) {
      const index = arrayOfPaths.indexOf(path);
      const slicedArray = arrayOfPaths.slice(0, index + 1);
      const concatenatedPath = slicedArray.join('/');

      return concatenatedPath;
    } else {
      return ''; //? we are at the root folder
    }
  }

  return (
    <Breadcrumbs
      separator={<ChevronRightOutlinedIcon color="disabled" />}
      aria-label="breadcrumbs"
    >
      <MuiLink
        component={Link}
        sx={{ cursor: 'pointer' }}
        color="text.disabled"
        to={{
          pathname: paths.knowledgeBase.pathname,
          search: qs.stringify({
            page: 1, //? reset page upon clicking on breadcrumb
            path: calculateBreadcrumb({ path: firstItem.path }),
          }),
        }}
      >
        <Text
          variant="textSm"
          component={Stack}
          direction="row"
          gap={2}
          color="text.disabled"
        >
          {firstItem.icon}
          {firstItem.name}
        </Text>
      </MuiLink>
      <SimpleDropdown
        sx={{
          paperSx: {
            backgroundColor: 'background.card.main',
            boxShadow: (theme) => (theme as Theme).customShadows.card,
          },
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        trigger={{
          element: (
            <IconButton
              color="outlinedSecondary"
              size="small"
            >
              <MoreHorizIcon />
            </IconButton>
          ),
        }}
        options={items
          .slice(
            START_TO_CONDENSED_ITEMS,
            items.length - NUMBER_OF_CONDENSED_ITEMS,
          )
          .map((breadcrumb) => ({
            text: breadcrumb.name,
            value: breadcrumb.path!,
            onClick: () => {
              const concatenatedPath = calculateBreadcrumb({
                path: breadcrumb.path,
              });
              navigateTo(
                paths.knowledgeBase.pathname +
                  '?' +
                  qs.stringify({
                    page: 1, //? reset page upon clicking on breadcrumb
                    path: concatenatedPath,
                  }),
              );
            },
          }))}
      />
      {items
        .slice(items.length - NUMBER_OF_CONDENSED_ITEMS)
        .map((breadcrumb, i, slicedItems) => (
          <MuiLink
            sx={{
              cursor: 'pointer',
              pointerEvents: i === slicedItems.length - 1 ? 'none' : 'auto',
            }}
            key={breadcrumb.name}
            component={Link}
            to={{
              pathname: paths.knowledgeBase.pathname,
              search: qs.stringify({
                ...allQueryParams,
                path: calculateBreadcrumb({ path: breadcrumb.path }),
              }),
            }}
          >
            <Text
              variant="textSm"
              component={Stack}
              direction="row"
              gap={2}
              color={
                i === slicedItems.length - 1 ? 'text.primary' : 'text.disabled'
              }
            >
              {breadcrumb.name}
            </Text>
          </MuiLink>
        ))}
    </Breadcrumbs>
  );
}

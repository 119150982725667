import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingInline: theme.spacing(3.5),
}));

export const StyledSelect = styled(Select)(() => ({
  width: '100%',
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: theme.spacing(9.5),
  marginBlock: theme.spacing(0.25),
}));

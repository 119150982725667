import React from 'react';

import useThemeSettings from '@hooks/useThemeSettings';

import { ToastContainer } from 'react-toastify';

export default function ToastifyContainer({
  children,
}: React.PropsWithChildren) {
  const { themeMode } = useThemeSettings();
  const isThemeModeDark = themeMode === 'dark';

  return (
    <>
      <ToastContainer theme={isThemeModeDark ? 'dark' : 'light'} />
      {children}
    </>
  );
}

import { storage } from '@lib/agent';

import { UploadByLinksRequestType } from '@shared-types/images';

import { useMutation } from '@tanstack/react-query';

export default function useUploadByLinks() {
  return useMutation({
    mutationKey: ['uploadByLinks'],
    mutationFn: async (params: UploadByLinksRequestType) => {
      const { data } = await storage.uploadByLinks(params);
      return data;
    },
  });
}

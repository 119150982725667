import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  boxSizing: 'border-box',
  boxShadow: `inset 0 0 0 0.5px ${alpha(theme.palette['Component colors/Components/Avatars/avatar-contrast-border'], 0.12)}`,
  background: theme.palette['Component colors/Components/Avatars/avatar-bg'],
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default StyledBox;

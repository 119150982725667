import Box from '@mui/material/Box';

type Props = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      style={{ flex: 1 }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

export function allyProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  CreateStyleParamsType,
  CreateStyleResponseType,
} from '@shared-types/styles';

export const styles = {
  create: (body: CreateStyleParamsType) =>
    requests(axiosApiInstance).post<CreateStyleResponseType>(
      'v1/entities/styles',
      body,
    ),
  updateOne: (id: number, body: Partial<CreateStyleParamsType>) =>
    requests(axiosApiInstance).patch<CreateStyleParamsType>(
      `/v1/entities/styles/${id}`,
      body,
    ),
};

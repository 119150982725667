import useGetInvitedWorkspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/hooks/useGetInvitedWorkspaces';
import LoadingDropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/LoadingDropdown';
import Dropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/invited-to-workspaces/workspaces/dropdown';

export default function InvitedToWorkspaces() {
  const { invitedWorkspacesData, isWorkspaceFetching } =
    useGetInvitedWorkspaces();

  if (isWorkspaceFetching) {
    return <LoadingDropdown />;
  }

  return <Dropdown options={invitedWorkspacesData} />;
}

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';

import Logo from '@components/logo';
import UserAuthentication from '@components/user-authentication';

import useAuth from '@hooks/useAuth';
import usePortal from '@hooks/usePortal';
import useResponsive from '@hooks/useResponsive';
import useWorkspace from '@hooks/useWorkspace';

import HeaderWrapper from '@layouts/components/header-wrapper';
import CONFIG from '@layouts/main/sections/header/config';
import DesktopNav from '@layouts/main/sections/header/desktop-nav';
import SetupMode from '@layouts/main/sections/header/sections/setup-mode';
import UserAccountPopover from '@layouts/main/sections/header/sections/user-account-popover';
import {
  StyledBox,
  StyledStack,
  StyledStaticBox,
  StyledStaticStack,
} from '@layouts/main/sections/header/styles';
import paths from '@router/paths';

export default function Header({
  ToolbarToggle,
}: {
  ToolbarToggle: React.ReactNode;
}) {
  const isItDesktop = useResponsive('up', 'md');

  const { me } = useAuth();

  const { isWorkspaceAdmin } = useWorkspace();

  const hasSecuredAccount =
    me && (me.auth_type === 'email' || me.auth_type === 'oauth2');

  const mainPortal = useRef(null);

  const { handleSetNavbarMain } = usePortal();

  const { pathname } = useLocation();

  const commandPath = paths.editCommand.pathname.replace(':id', '\\d+');

  const commandPathRegex = new RegExp(commandPath);

  const isCommandsEditor = commandPathRegex.test(pathname);

  const menuType = !isItDesktop || isCommandsEditor ? 'clean' : 'default';

  useEffect(
    () => handleSetNavbarMain(mainPortal?.current),
    [handleSetNavbarMain, mainPortal],
  );

  return (
    <HeaderWrapper isDetailedMode={!isCommandsEditor}>
      <StyledStack
        direction="row"
        sx={{
          gap: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {ToolbarToggle}
          {menuType === 'default' && (
            <StyledStaticBox>
              <DesktopNav navListItems={CONFIG.DESKTOP_NAV_ITEMS} />
            </StyledStaticBox>
          )}
        </Stack>
        <StyledBox>
          <Logo />
        </StyledBox>

        {isCommandsEditor && (
          <Box
            ref={mainPortal}
            sx={{ flexGrow: 1 }}
          />
        )}
        <StyledStaticStack
          direction="row"
          gap={4}
        >
          {isWorkspaceAdmin && !isCommandsEditor && <SetupMode />}

          {!hasSecuredAccount && (
            <StyledStack
              direction="row"
              gap={2}
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <UserAuthentication />
            </StyledStack>
          )}

          <UserAccountPopover />
        </StyledStaticStack>
      </StyledStack>
    </HeaderWrapper>
  );
}

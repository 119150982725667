import * as Yup from 'yup';

const MAX_CHARACTERS = 50;

const userFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required.')
    .max(MAX_CHARACTERS, 'First name is too long'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required.')
    .max(MAX_CHARACTERS, 'Last name is too long'),
});

export default userFormSchema;

import Text from '@components/text';

import { useTranslation } from '@desygner/ui-common-translation';

import StyledBox from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/header/styles';

export default function Header() {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <Text
        variant="textMd"
        weight="semibold"
      >
        {t('layouts.toolbar.popover.title', {
          defaultValue: 'Select the Knowledge Base you want to use',
        })}
      </Text>
      <Text variant="textSm">
        {t('layouts.toolbar.popover.subtitle', {
          defaultValue:
            'This will effect the entire platforms Knowledge, including Search and Live, switch at anytime here.',
        })}
      </Text>
    </StyledBox>
  );
}

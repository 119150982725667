import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

export const StyledContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  marginBlock: theme.spacing(1),
}));

export const StarsWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.3),
  marginTop: theme.spacing(2),
}));

export const StyledTextName = styled(Text)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

export const StyledTextDescription = styled(Text)(({ theme }) => ({
  textAlign: 'left',
  textWrap: 'wrap',
  color: theme.palette.text.secondary,
  width: '100%',
}));

export const StyledStackMode = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  paddingInline: theme.spacing(2),
  alignItems: 'center',
}));

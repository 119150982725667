import { Box, useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

type Props = {
  ConnectorIcon: React.ReactNode;
  onClick: () => void;
  label: string;
};

export default function ConnectorButton({
  ConnectorIcon,
  label,
  onClick,
}: Props) {
  const theme = useTheme();

  return (
    <Stack
      component={ButtonBase}
      onClick={onClick}
      disableRipple
      disableTouchRipple
      gap={1.5}
      sx={{
        minWidth: 90,
        svg: {
          width: 48,
          height: 48,
        },
      }}
    >
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'border.secondary',
          borderRadius: 2,
          width: 52,
          height: 52,
        }}
      >
        {ConnectorIcon}
      </Box>
      <Text
        variant="textSm"
        weight="semibold"
        color={
          theme.palette[
            'Component colors/Components/Buttons/Tertiary/button-tertiary-fg'
          ]
        }
      >
        {label}
      </Text>
    </Stack>
  );
}

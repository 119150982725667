import Input from '@components/input';

import { useTranslation } from '@desygner/ui-common-translation';

import ChevTopIcon from '~icons/knowz-iconify/chev-up';

import StyledAvatar from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/styles';

export default function LoadingDropdown() {
  const { t } = useTranslation();

  return (
    <Input
      fullWidth
      endAdornment={<ChevTopIcon />}
      startAdornment={<StyledAvatar />}
      readOnly
      disabled
      aria-busy
      aria-label={'loading'}
      value={t('common.loading', {
        defaultValue: 'Loading...',
      })}
    />
  );
}

import { alpha, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  pointerEvents: 'none',
  svg: {
    color: `${theme.palette.forground.secondary}!important`,
  },
  background: theme.palette.createBrandGradients.secondary({
    alpha: alpha(theme.palette['Component colors/Alpha/alpha-white-100'], 0.6),
  }),
}));

export default StyledIconButton;

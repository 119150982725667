import { styled } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

import CONFIG from '@layouts/main/sections/header/sections/user-account-popover/config';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  boxShadow: theme.customShadows.card,
  width: CONFIG.POPOVER_WIDTH,
}));

export const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

//? .active => https://reactrouter.com/start/framework/navigating
export const StyledActiveListItemButton = styled(ListItemButton)(
  ({ theme }) => ({
    '&.active': { backgroundColor: theme.palette.background.card.main },
  }),
) as typeof ListItemButton;

import Tooltip from '@mui/material/Tooltip';

import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import CONFIG from '@layouts/main/sections/toolbar/config';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';

export default function Items() {
  const { me } = useAuth();
  const { setupMode } = useWorkspace();
  const { t } = useTranslation();

  return CONFIG.TOOLBAR_ITEMS.filter(({ isAlpha }) => me?.alpha || !isAlpha)
    .filter(({ setupMode: singleSetupMode }) =>
      singleSetupMode.includes(setupMode),
    )
    .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
      <Tooltip
        key={i}
        arrow
        placement="right"
        title={t(mappedLabel, { defaultValue: defaultLabel })}
      >
        <StyledListItem
          disablePadding
          disableGutters
          component={StyledNavLink}
          to={url}
        >
          {icon}
        </StyledListItem>
      </Tooltip>
    ));
}

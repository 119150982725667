import ConnectorButton from '@components/workspace/setup/sections/steps/connectors/connector-button';
import WithStarIcon from '@components/workspace/setup/sections/steps/connectors/tabs/panels/components/with-star-icon';
import { StyledStack } from '@components/workspace/setup/sections/steps/connectors/tabs/panels/styles';

import SlackIcon from '~icons/knowz-iconify/slack-color-tone';
import TeamsIcon from '~icons/knowz-iconify/teams-color-tone';

export default function Num3() {
  return (
    <StyledStack>
      <WithStarIcon>
        <ConnectorButton
          ConnectorIcon={<SlackIcon />}
          label="Slack"
          onClick={() => {
            alert('Slack');
          }}
        />
      </WithStarIcon>
      <WithStarIcon>
        <ConnectorButton
          ConnectorIcon={<TeamsIcon />}
          label="Teams"
          onClick={() => {
            alert('Teams');
          }}
        />
      </WithStarIcon>
    </StyledStack>
  );
}

const en = {
  layout: {
    main: {
      sections: {
        header: {
          secureAccount: 'Secure your account',
          navigation: {
            enterpriseSolution: 'Enterprise Solutions',
            tos: 'Terms of Service',
            pp: 'Privacy Policy',
          },
          theme: {
            title: 'Theme',
            modes: {
              light: 'Light',
              dark: 'Dark',
              system: 'System',
            },
          },
          setupMode: {
            label: 'Setup Mode',
            description:
              'Setup Mode puts you in control. Manage user access, customize tools, and oversee your workspace effortlessly. Optimize functionality and security with powerful controls designed for efficient team management.',
            alert:
              'You are using  Knowz In Set up Mode, any configuration changes will affect members',
          },
          exitSetupMode: 'Exit Setup mode',
        },
        userAccount: {
          createYourAccount: 'Create your account',
          butMoreCredits: 'Buy more credits',
          settings: 'Settings',
          tos: 'Terms of Service',
        },
      },
    },
    upgrade: {
      title: {
        segment1: 'A plan for',
        segment2: 'every need',
      },
      subtitle: 'Pick a plan today to see why Knowz better',
      mostPopular: 'Most popular',
      extraInfo: {
        segment1:
          '*Purchase additional storage at 1 credit per 10MB, for larger needs, inquire about our',
        segment2: 'Enterprise Packages',
      },
      plans: {
        currency: 'US',
        creditPack: {
          title: '5,000 Credit Pack',
          subtitle: 'Top up your credits anytime to keep exploring smarter',
          priceInfo: {
            priceLine: '$19.95',
            creditsLine: '5,000 Credits',
          },
          keyFeatures: {
            0: '5,000 credits top-up to use Knowz as you please',
            1: 'Pay as you go, no commitment',
            2: 'Credits never expire!',
          },
          button: 'Buy Now',
        },
        monthlyGeniusSearch: {
          title: 'Genius Search Plan',
          subtitle:
            'Subscribe to incredible AI value with 5,000 credits per month',
          keyFeatures: {
            0: '5,000 credits top up each month',
            1: '50MB Storage*',
            2: 'Most popular plan',
            3: 'More precise results with Genius',
            4: 'Monthly or annual plans',
            5: 'First access to new features',
          },
          button: 'Subscribe and Save',
          priceInfo: {
            priceLine: '$7.95',
            creditsLine: '/Month + 5,000 Credits',
          },
        },
        yearlyGeniusSearch: {
          title: 'Genius Search Plan',
          subtitle:
            'Subscribe to incredible AI value with 60,000 credits per year!',
          keyFeatures: {
            0: '60,000 credits to use when and how you please',
            1: '50MB Storage*',
            2: 'Best value & most popular plan',
            3: 'More precise results with Genius',
            4: 'Monthly or annual plans',
            5: 'First access to new features',
          },
          button: 'Subscribe and Save',
          priceInfo: {
            priceLine: '$85.85',
            creditLine: '/Year + 60,000 Credits',
          },
        },
        geniusUnlimited: {
          title: 'Genius Unlimited',
          subtitle:
            'Get ultimate AI value with unlimited credits to use as you please!',
          priceInfo: {
            priceLine: '$199.95',
            creditLine: '/Year + UNLIMITED Credits',
          },
          keyFeatures: {
            0: 'UNLIMITED credits to use Knowz',
            1: '50MB Storage*',
            2: 'Advanced smart search tools',
            3: 'More precise results with Genius',
            4: 'Free 3-day trial, no risk',
            5: 'Input into future platform features',
          },
          button: 'Join us Today',
        },
      },
    },
    auth: {
      logout: 'Logout',
      mobile: {
        buttons: {
          primary: 'Login',
        },
      },
      desktop: {
        buttons: {
          primary: 'Sign Up',
          secondary: 'Login',
        },
        modal: {
          steps: {
            enterEmail: {
              label: 'Get started with email',
              anon: 'Continue without an account',
              description:
                'We’ll check if you have an account, and help create one if you don’t.',
              form: {
                email: {
                  required: 'Email is required',
                  invalid: 'Invalid email',
                  placeholder: 'Email',
                },
                button: 'Continue',
              },
            },
            verifyEmail: {
              label: 'Sign up code',
              description: {
                segment1:
                  'Check your email. We have sent you a temporary code to',
                segment2: 'to continue signing up.',
              },
              form: {
                code: {
                  required: 'Code is required',
                  placeholder: 'Code',
                },
                button: 'Verify',
              },
              resendCode: {
                segment1: 'Didn’t get the code?',
                segment2: 'Resend code',
              },
            },
            setupPassword: {
              label: 'Welcome to Knowz',
              description: 'Create a password which is hard to guess',
              form: {
                subscribe:
                  'I agree to Knowz sending marketing communications about Knowz to me.',
                button: 'Continue',
                password: {
                  minChar: 'Password must be at least 8 characters',
                  required: 'Password is required',
                  label: 'Set a password',
                  placeholder: 'Password',
                },
                TOS: {
                  segment1: 'By signing up, you agree to Knowz’s',
                  segment2: 'Terms of User',
                  segment3: 'and',
                  segment4: 'Privacy Policy',
                },
              },
            },
            setupName: {
              label: ' Welcome to Knowz',
              description: 'Tell us a bit about yourself.',
              form: {
                name: {
                  required: 'Name is required',
                  placeholder: 'Your Name',
                },
                button: 'Create your account',
              },
            },
            enterPassword: {
              label: 'Welcome Back!',
              form: {
                password: {
                  required: 'Password is required',
                  label: 'Enter your password',
                  placeholder: 'Password',
                },
                button: 'login',
              },
            },
            resetPassword: {
              label: 'Reset Your Password',
              form: {
                password: {
                  required: 'Password is required',
                  minChar: 'Password must be at least 8 characters',
                  placeholder: 'New Password',
                },
                confirmPassword: {
                  required: 'Confirm your password',
                  match: 'Passwords must match',
                  placeholder: 'Confirm New Password',
                },
                button: 'Reset Password',
              },
              response: {
                success: 'Password has been successfully reset.',
              },
            },
            sendResetToken: {
              response: {
                success: 'Your code has been successfully sent.',
                error: 'Too many requests. Please try again in 10 minutes.',
              },
              form: {
                code: {
                  length: 'Code must be exactly 6 digits',
                  required: 'Code is required',
                },
              },
            },
            verifyCode: {
              form: {
                label: 'Enter code',
                description: {
                  segment1: 'We sent a code to your email',
                  segment2:
                    'Please enter your 6-digit code to verify your identity. Your code will expire in 10 minutes.',
                },
                button: 'Verify',
              },
              toast: {
                success: 'Code has been successfully validated.',
                error: 'Invalid code. Please enter the correct code.',
              },
            },
          },
        },
      },
    },
    toolbar: {
      popover: {
        title: 'Select the Knowledge Base you want to use',
        subtitle:
          'This will effect the entire platforms Knowledge, including Search and Live, switch at anytime here.',
        tabs: {
          ownedKnowledgeBases: {
            title: 'Your knowledge bases',
          },
          invitedTo: {
            empty: 'nothing here yet',
            title: 'Invited to',
            search: 'Search invited knowledge bases',
            noResult: 'No knowledge bases found',
          },
        },
        buttons: {
          goToKnowledgeBase: 'Go to Knowledge Base',
          done: 'done',
        },
      },
      buttons: {
        expand: 'Expand Toolbar',
        collapse: 'Collapse Toolbar',
        search: 'Search',
        history: 'History',
        live: 'Live',
        commands: 'Commands',
        assistants: 'Assistants',
        knowledgeBase: 'Knowledge Base',
        users: 'Users',
        tos: 'Terms of Service',
        pp: 'Privacy Policy',
        enterpriseSolutions: 'Enterprise Solutions',
      },
      buyAICredit: {
        cta: {
          title: {
            visitor: 'Credits power Knowz, login or sign up for more',
            free: 'Credits power Knowz, upgrade now for more',
            pack: 'Credits power Knowz, upgrade now for more',
            subscribed: 'Credits refill on the {{date}} of each month',
          },
          body: {
            visitor:
              'To make the most of Knowz sign up now and buy a credit Pack or select a plan for the best value.',
            free: 'Work with family, friends or small groups and access all the platforms features by buying a credit pack or upgrade to a plan for the best value.',
            pack: 'Top up anytime with a credit pack or upgrade to a plan for the best value.',
            subscribed: 'Top up at anytime with a credit pack.',
          },
          button: {
            login: 'Login or Sign up',
            plan: 'Pick a Plan',
            credits: 'Get Credits',
            more: 'Get More Credits',
          },
        },
        label: {
          low: 'Nearly out',
          out: 'All out',
        },
        modal: {
          title: 'Get More AI Credits!',
          subtitle:
            'You’re on the FREE plan. Upgrade now to receive more credits and keep using our powerful AI features!',
          button: 'Upgrade Now!',
        },
        credits: 'Credits',
      },
      tooltipBuyCredit: {
        title: 'Credits',
        subtitle:
          'Use credits for tools like Smart Search—credits reset every month. Run out? Just grab a payment plan to keep going strong!',
      },
    },
    assistantSession: {
      header: {
        tooltip: {
          history: 'History',
          newThread: 'New Thread',
        },
      },
    },
  },
  page: {
    home: {
      //TODO: we do not have a home page in our app change to search later in separate JIRA Card and PR
      hero: {
        title: 'Unleash AI Insights',
        subtitle: 'Search no more, Knowz better',
        overlineButton: 'Enterprise Solutions',
        search: {
          genius: {
            title: 'Genius mode',
            subtitle: 'Sign up now to use our most advanced mode.',
            premiumFeature: 'Premium Feature',
          },
          placeholder: 'Ask anything...',
          required: 'This field is required',
          advancedSearch: 'Genius mode',
        },
      },
      footer: {
        copyright: 'Copyright',
      },
    },
    live: {
      grids: {
        mode: {
          title: 'Configure your session',
          cards: {
            knowzAssistant: {
              summonKnowz: {
                segment1: 'Summon Knowz AI anytime during sessions by saying',
                segment2: 'or just',
              },
              title: 'Include KnowZ Assistant',
              customizeBtn: 'Customize',
              description:
                'Add KnowZ AI Assistant to any live meeting for expert advice, real-time suggestions, note-taking, and guidance. With direct access to your workspace knowledge base, KnowZ makes organizing and updating data easy.',
              drawer: {
                customize: {
                  title: 'Customize KnowZ Assistant',
                  subtitle:
                    'Select how you want KnowZ to interact during live recordings.',
                },
                selectAll: 'Select All',
              },
            },
            smartTagsCard: {
              title: 'Use Smart Tags',
              description:
                'Automatically flag words and phrases based on pre-set criteria, like customer support needs, workplace compliance, NSFW content and more.',
            },
          },
        },
        transcribe: {
          title: 'Live',
          body: {
            title: 'Record smarter, get AI insights instantly!',
            subtitle:
              'Tap to record and let our assistant turn your words into valuable insights. Speak freely, and see your thoughts come to life!',
            button: 'Start Smart Recording',
          },
        },
      },
      recorder: {
        button: 'Start Now',
      },
    },
    liveSession: {
      tokenNotFound: 'You need to provide a token',
      stopRecording: 'Stop Recording',
      waitingForParticipants: 'Connecting to the room...',
      transcriptSetting: {
        voiceMatch: {
          title: 'Voice Match Speaker',
          subtitle: {
            segment1:
              'Use this to match the voice of the speaker to a member of your workspace or to a guest speaker.',
            segment2: 'Make sure you have the speakers permission first.',
          },
          buttons: {
            confirm: 'Match Voice',
            cancel: 'Cancel',
            continue: 'Continue',
          },
          whoMatched: 'Voice is matched to',
          editMatch: 'Edit Voice Match',
          removeMatch: 'Remove match',
          rematch: 'Rematch to another user',
        },
      },
      feed: {
        title: 'Feed',
      },
      mic: {
        denied: 'Your mic access denied please allow it first',
        prompt: 'Waiting for permission to access your microphone...',
        support: 'Browser does not support getUserMedia please use chrome',
      },
      confirmLeave: {
        title: 'Leave Recording Session',
        content: 'Are you sure you want to leave recording?',
        no: 'No, I want to continue',
        yes: 'Yes, I want to leave',
      },
      confirmStopRecording: {
        title: 'Stop Recording',
        content: 'Are you sure you want to stop recording?',
        no: 'No, I want to continue',
        yes: 'Yes, I want to stop',
      },
      recorder: {
        recording: 'Recording',
      },
    },
    commands: {
      title: 'Command Center',
      subtitle:
        'Create a reusable workflow with fine-tuned control over the actions that are executed.',
      button: 'Create AI Command',
    },
    ai: {
      defaultIntroMsg:
        'Hello! I am a multilingual document assistant here to help you with any questions you may have regarding the uploaded document. Ask me any questions about this workspace that interest you.',
      threadHistory: {
        button: 'New Conversation',
        deleteConfirmation: {
          title: 'Delete Thread',
          content: 'Are you sure you want to delete this thread?',
          no: "No, I'm good",
          yes: 'Yes, please',
        },
        emptyHistory: 'No threads found.',
      },
      threadArea: {
        form: {
          required: 'This field is required',
          placeholder: 'Ask any question about this workspace...',
        },
      },
    },
    search: {
      sections: {
        answer: 'Answer',
        relatedQuestions: 'Related Questions',
        sources: 'Sources',
        knowledgeSource: 'knowledge_source',
      },
    },

    error: {
      title: "Uh oh!  There's a glitch in the matrix.",
      subtitle:
        "We're working to fix it. Check back soon for everything to be back to normal.",
      cta: 'Back to Home',
    },
    history: {
      tabs: {
        search: 'Search',
        transcript: 'Transcript (coming soon)',
      },
    },
    assistants: {
      title: 'Assistants',
      button: 'Add Assistants',
      addNewAssistants: 'Add New Assistants',
      startConversation: 'Start Conversation',
      options: {
        placeholder: 'Search for assistants...',
        noOptions: 'No assistants found',
      },
      drawer: {
        integratedAssistants: {
          title: 'Integrate Assistants',
          subtitle: 'Copy and past this code into your site.',
          embeddedCode: 'Embedded code',
        },
        addAssistants: {
          title: 'Create an assistants',
          form: {
            name: {
              label: 'Name',
              placeholder: 'Assistant Name',
              max: 'Assistant name must be less than 60 characters',
              required: 'Name is required',
            },
            description: {
              label: 'Description',
              placeholder: 'Assistant Description',
              max: 'Assistant description must be less than 255 characters',
              required: 'Description is required',
            },
            fallbackSupport: {
              label:
                'Automatically fallback to public knowledge when the answer can not be found in the provided knowledge.',
            },
            publicDefault: {
              label:
                'Allow the user to enquire public knowledge when the answer can not be found in the provided knowledge.',
            },
            communicationStyles: {
              title: 'Communication style',
              description:
                "Personalis your AI's communication style and response structure to align perfectly with your preferences and needs",
            },
            submitButton: 'Add Assistant',
          },
        },
      },
    },
    assistantsSession: {
      drawer: {
        title: 'Conversations',
      },
      messageField: {
        placeholder: 'Type your message here...',
        required: 'This field is required',
      },
    },
    knowledgeBase: {
      bulkActions: {
        button: 'Delete All',
        delete: {
          title: 'Delete sources',
          content: 'Are you sure you want to delete these sources?',
        },
        toast: {
          success: 'Sources have been deleted successfully.',
        },
      },
      capacityIndicator: {
        tooltip: {
          heading: 'Available Knowledge Capacity',
          content: {
            segment1:
              'This is the combined size of the files you can store in Knowledge Base.',
            segment2:
              ' Free accounts get 50MB platform storage, upgrade now for more.',
          },
        },
      },
      table: {
        capacity: 'Knowledge Capacity',
        search: {
          placeholder: 'Search sources by name',
        },
      },
      upload: {
        note: 'audio, text and docs, up to',
        browseFiles: 'Click to upload',
        dropHereOr: 'Drop files here or',
        upgradeAccountCTA: 'Upgrade for larger files, storage & more',
        sources: {
          tooltip: {
            heading: 'Connect to',
            content:
              'Free accounts can only connect to one external source, upgrade now to connect to all your data sources.',
            label: 'Upgrade now',
          },
        },
      },
      modals: {
        createFolder: {
          title: 'Create folder',
          form: {
            name: {
              label: 'Folder name',
              placeholder: 'Enter title',
              required: 'Name is required',
              max: 'Folder name must be less than 60 characters',
            },
            buttons: {
              submitButton: 'Save',
              cancelButton: 'Cancel',
            },
          },
        },
        rename: {
          title: 'Rename',
          form: {
            name: {
              label: 'Rename',
              placeholder: 'Enter a name',
              required: 'Name is required',
              max: 'Name must be less than 60 characters',
            },
            buttons: {
              submitButton: 'Save',
              cancelButton: 'Cancel',
            },
          },
        },
      },
      admin: {
        header: {
          title: 'Knowledge Base',
          buttons: {
            primary: {
              label: 'Add',
            },
            secondary: {
              label: 'Create a folder',
            },
          },
          actionDrawer: {
            tab1: {
              details: 'Details',
              name: 'Name',
              format: 'Format',
              location: 'Location',
              createdBy: 'Created By',
              size: 'Size',
              updatedAt: 'Last Updated',
              createdAt: 'Date Created',
            },
            tab2: {
              summary: 'Summary',
              placeholder: 'Waiting for summary',
            },
            tab3: {
              permission: 'Permission',
            },
          },
        },
        actions: {
          rename: {
            toast: {
              success: 'Source has been renamed successfully.',
            },
          },
          delete: {
            source: {
              title: 'Delete Source',
              content: 'Are you sure you want to delete this source?',
              no: "No, I'm good",
              yes: 'Yes, please',
              toast: 'Source has been deleted successfully.',
            },
            folder: {
              title: 'Delete Folder',
              content: 'Are you sure you want to delete this folder?',
              no: "No, I'm good",
              yes: 'Yes, please',
              toast: {
                success: 'Folder has been deleted successfully.',
                deleteConstraint: 'Folder has got some sources in it.',
              },
            },
          },
          dragAndDrop: {
            success: {
              folder: {
                segment1: 'Folder',
                segment2: 'successfully moved',
              },
              source: {
                segment1: 'Source',
                segment2: 'successfully moved',
              },
            },
            error: {
              folder: {
                segment1: 'Folder',
                segment2: 'could not be moved, please try again',
              },
              source: {
                segment1: 'Source',
                segment2: 'could not be moved, please try again',
              },
            },
          },
        },
      },
      member: {
        membership: {
          invalid: 'You do not have permission to accept this invitation.',
          accepted: 'You have successfully accepted this invitation.',
          alreadyAccepted: 'You have already accepted this invitation.',
        },
      },
    },
    workspace: {
      createButton: {
        dropdown: 'Create New',
        panel: 'New Knowledge Base',
      },
      createWorkspaceModal: {
        backButton: 'Back',
        skipButton: 'Skip Step',
        nextButton: 'Next',
        cancelButton: 'Cancel',
        finishButton: 'Finish',
        step1: {
          toast: {
            create: {
              success: {
                segment1: 'Your Knowledge Base',
                segment2:
                  'was created successfully, you can go there now, or continue to customize it and invite users.',
              },
            },
            edit: {
              success: 'Your Knowledge Base name was updated successfully to',
            },
          },
          title: 'Create your Knowledge Base',
          description:
            'Knowledge Bases define what Knowz knows, each one can be a rich source of customized data you can share with others or keep just to yourself.',
          form: {
            button: {
              create: 'Create & Continue',
              tooltip: 'Give your Knowledge Base a name first',
            },
            name: {
              label: 'Knowledge Base Name*',
              placeholder: 'Identify your Knowledge Base',
              required: 'Workspace name is required',
              max: 'Workspace name must be less than 60 characters',
            },
          },
        },
        step2: {
          title: 'Customize your Knowledge Base',
          description:
            'Make your Knowledge Base instantly recognizable with a unique icon - use your logo, branding elements, a photo, or let Knowz create one for you.',
          subSteps: {
            step1: {
              uploadZone: {
                toast: {
                  tooLarge: {
                    title: 'Image upload failed',
                    content:
                      'Please upload a JPEG, PNG, WEBP, GIF, or SVG up to 20MB.',
                  },
                },
                buttonToUpload: {
                  segment1: 'Click to upload',
                  segment2: 'or drag and drop',
                },
                fileRestriction: 'JPEG, PNG, WEBP, GIF, or SVG up to 20MB',
              },
              generateIcon: {
                segment1: 'Generate an icon',
                segment2:
                  'Describe what your icon to look like, this will use credits.',
              },
            },
            step2: {
              form: {
                title: 'Generate an icon',
                description:
                  'Describe what your icon to look like, this will use credits.',
                placeholder:
                  'Add description and press enter or just press generate and Knowz will create an image to use as your icon...',
              },
              buttons: {
                generate: 'Generate',
                upload: 'Upload an icon instead',
              },
            },
            step3: {
              title: 'Crop Image',
              buttons: {
                apply: 'Apply',
              },
            },
          },
          form: {
            file: {
              label: 'Your workspace image',
              uploadImage: 'Upload image',
              chooseImage: 'Choose image',
            },
            button: {
              remove: 'Remove',
            },
          },
        },
        step3: {
          title: 'Knowledge Base Invitations',
          subtitle:
            'Knowz makes collaboration easy- invite coworkers, family, or friends to create and share Knowledge Bases tailored to your needs.',
          owner: 'Owner',
          form: {
            title: 'Invite team members',
            description:
              'Get your projects up and running faster by inviting your team to collaborate.',
            email: {
              label: 'Invite Team members',
              placeholder: 'Ex: member@gmail.com',
              required: 'Email is required',
              duplication: 'Member is already in the list',
              invalid: 'Invalid email',
              max: 'You can only add emails up to 5 members at a time',
            },
            button: {
              sendInvites: 'Send Invites',
              invite: 'Invite',
            },
          },
        },
        step4: {
          title: 'Knowledge Base Connectors',
          subtitle:
            'Set up your connectors now, or later, these can be used to upload and keep files synced from your external platforms as well as to *export Knowz Ai to MS Teams & Slack to communicate with on those platforms. *(Requires a Teams Plan)',
          connectors: {
            title: 'Import or sync your data with external connectors',
          },
        },
      },
      header: {
        button: 'Invite',
      },
    },
  },
  flow_editor: {
    add_option: 'Add more',
    placeholder: {
      sourceConfiguration: 'Select a source handler',
      folder: 'Select a folder',
      source: 'Select data source',
      user: 'Select a user',
      group: 'Select group',
      collection: 'Select collection',
    },
  },
  getTheApp: {
    segment1: 'Get Started with AI:',
    segment2: 'Unlock the Power of Conversation',
  },
  response: {
    errors: {
      code: {
        429: 'Too many requests. Please try again later.',
        400: 'Invalid request. Please try again.',
        500: 'Internal server error. Please try again later.',
        520: 'Unknown error. Please try again later.',
        409: 'User already exists. Please try to login.',
        401: 'Invalid credentials. Please try again.',
        404: 'Resource not found.',
        403: 'Not allowed to access this resource.',
      },
      wrongCodeVerification: 'Invalid code. Please try again.',
      createFolder: {
        duplicate: 'A folder with this name already exists.',
      },
      pages: {
        notFound: {
          title: 'Page not found',
          subtitle: 'The page you are looking for does not exist.',
        },
        unauthorized: {
          title: 'Access Denied. You are not authorized.',
          subtitle:
            'Looks like you do not have permission to access this page.',
        },
        serverError: {
          title: 'Uh oh! There is a glitch in the matrix.',
          subtitle:
            'We are working to fix it. Check back soon for everything to be back to normal.',
        },
      },
    },
    success: {
      resendCode: 'Code sent successfully. Please check your email.',
      signUp: 'You account created successfully.',
      login: 'You have successfully logged in.',
      logout: 'You have successfully logged out.',
      createAssistants: 'You have successfully created an assistant.',
      createFolder: 'You have successfully created a folder.',
      uploadedSource: 'You have successfully uploaded a source.',
      inviteMember: 'Invitation sent successfully.',
      subscription:
        "Thanks for subscribing! Please verify your email so you don't mistakenly loose your account",
    },
    credit: {
      stripe: 'Redirecting to checkout...',
      insufficient: 'Insufficient credits to perform this action.',
    },
  },
  emptyState: {
    transcript: 'Transcript will start generating as you are speaking',
    historyThreads: 'No threads found. start a new thread',
  },
  comingSoon: 'Coming Soon!',
  roles: {
    protectedRoutes: {
      title: 'Permission Denied',
      description: 'You do not have permission to access this page',
    },
  },
  components: {
    knowledgeInfoCard: {
      dismiss: 'Dismiss',
      source: 'Source',
      goToFile: 'Go to File',
    },
    settings: {
      general: {
        updateProfile: {
          avatar: {
            message: 'Visible to your teammates in workspaces',
          },
          response: {
            success: 'Your profile has been updated',
            error: 'An error occurred while updating your profile',
          },
        },
      },
    },
  },
  common: {
    loading: 'Loading...',
  },
};

export default en;

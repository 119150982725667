import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  background: 'rgba(0,0,0, 0.5)',
  left: 0,
  width: '100%',
  height: '100%',
})) as typeof Box;

export const StyledDot = styled('span')(({ theme }) => ({
  width: 4,
  height: 4,
  background: theme.palette.forground.quaternary,
  borderRadius: '50%',
}));

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const NAV_HEIGHTS = {
  FULL: '100%',
  CONTENT: '90%',
  FOOTER: '10%',
};

const NAV_WIDTHS = {
  CONTENT: '85%',
  BUTTONS: '15%',
};

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledStack2 = styled(Stack)(({ theme }) => ({
  gap: 2,
  border: 1,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  height: NAV_HEIGHTS.FULL,
  flex: 1,
  backgroundColor: theme.palette.background.card.light,
}));

export const StyledBox2 = styled(Box)(({ theme }) => ({
  height: NAV_HEIGHTS.CONTENT,
  flex: 1,
  overflowY: 'auto',
  scrollbarGutter: 'stable',
  backgroundColor: theme.palette.background.card.light,
}));

export const StyledBox3 = styled(Box)(({ theme }) => ({
  height: NAV_HEIGHTS.FOOTER,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const StyledContentBox = styled(Box)(({ theme }) => ({
  width: NAV_WIDTHS.CONTENT,
}));

export const StyledButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: NAV_WIDTHS.BUTTONS,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 2,
  marginBlockEnd: theme.spacing(2),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.card.light,
  '&:hover': {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
}));

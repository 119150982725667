import { chipClasses, Theme } from '@mui/material';

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: {
                size: 'small',
                color: 'grey',
              },
              style: {
                fontSize: theme.typography.textXs.fontSize,
                lineHeight: theme.typography.textXs.lineHeight,
                padding: 0,
                color: 'rgba(250, 250, 250, 1)', //TODO: design system
                border: '1px solid',
                borderColor: 'rgba(233, 234, 235, 1)', //TODO: design system
                [`.${[chipClasses.label]}`]: {
                  padding: theme.spacing(0.5, 2),
                },
              },
            },
          ],
        },
      },
    },
  };
}

import Text from '@components/text';

export default function Num0() {
  return (
    <Text
      variant="textSm"
      weight="semibold"
    >
      All
    </Text>
  );
}

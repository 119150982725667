import { BlockStep } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';

import { commandBlocks, CommandBlockType, storage } from '@lib/agent';

import useAuth from '@hooks/useAuth';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';

import { TFunction, useTranslation } from '@desygner/ui-common-translation';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const MAX_COMMAND_BLOCK_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

export function useCreateOneCommandBlock() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createOneCommandBlock,
    mutationKey: ['createOneCommandBlock'],
    onError: (error) => onError(t, error),
  });
}

async function createOneCommandBlock({
  name,
  description,
  icon,
  packages,
}: CommandBlockType) {
  return await commandBlocks.createOneCommandBlock({
    name: sanitizeName(name),
    description,
    icon,
    packages,
  });
}

function sanitizeName(name: string) {
  return name.length > MAX_COMMAND_BLOCK_NAME_LENGTH
    ? name.slice(0, MAX_COMMAND_BLOCK_NAME_LENGTH - DOTS_LENGTH) + '...'
    : name;
}

export function useSaveCommandBlockDefinition() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: saveCommandBlockDefinition,
    onError: (error) => onError(t, error),
  });
}

async function saveCommandBlockDefinition(block: BlockStep) {
  const body = JSON.stringify(block);
  const size = new Blob([body]).size;
  const { data } = await storage.createPolicy({
    mimeType: 'application/json',
    name: 'definitions.json',
    size: size,
    context: {
      entityType: 'command_block',
      entityId: block.blockId,
    },
  });

  return await storage.uploadFile({
    url: data.url,
    body,
    contentType: 'application/json',
  });
}

export function useUpdateOneCommandBlock() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: updateOneCommandBlock,
    onError: (error) => onError(t, error),
  });
}

async function updateOneCommandBlock(
  payload: Partial<CommandBlockType> & { id: number },
) {
  const { id, ...rest } = payload;

  if (rest.name) {
    rest.name = sanitizeName(rest.name);
  }

  return await commandBlocks.updateOneCommandBlock(id, rest);
}

export function useGetCommandBlockDefinitions() {
  const { t } = useTranslation();
  const { isLoaded: isWorkspaceLoaded } = useWorkspace();
  const { isUserAuthenticated } = useAuth();
  const { workspace } = useWorkspace();

  return useMutation({
    mutationFn: (blocks: CommandBlockType[]) =>
      fetchCommandBlockDefinitions(
        blocks,
        workspace!.id,
        isWorkspaceLoaded,
        isUserAuthenticated,
      ),
    onError: (error) => onError(t, error),
  });
}

export async function fetchCommandBlockDefinitions(
  blocks: CommandBlockType[],
  workspaceId: string,
  isLoaded: boolean,
  isAuthenticated: boolean,
) {
  if (!isLoaded || !isAuthenticated) return [];
  return await Promise.allSettled(
    blocks.map(async (block) => {
      const s3Key = `owners/${workspaceId}/commands/blocks/${block.id}/definition.json`;
      const { data: credentials } = await storage.getCredentials(s3Key);
      return await commandBlocks.getCommandBlockDefinition(credentials.url);
    }),
  );
}

export function useGetCommandBlockDefinition(blockId: number | string) {
  const { workspace } = useWorkspace();
  const { t } = useTranslation();

  const { data: blockDefinition, isLoading: isLoadingBlockDefinition } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['blockDefinition', blockId],
      queryFn: async () => {
        try {
          const s3Key = `owners/${
            workspace!.id
          }/commands/blocks/${blockId}/definition.json`;
          const { data: credentials } = await storage.getCredentials(s3Key);
          const { data } = await commandBlocks.getCommandBlockDefinition(
            credentials.url,
          );

          return data;
        } catch (error) {
          onError(t, error);
        }
      },
    });

  return { blockDefinition, isLoadingBlockDefinition };
}

export function useGetCommandBlockPackages() {
  const { t } = useTranslation();

  const { data: blockPackages, isLoading: isLoadingBlockPackages } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['blockPackages'],
      queryFn: async () => {
        try {
          return await commandBlocks.getAllPackages();
        } catch (error) {
          onError(t, error);
        }
      },
    });

  return { blockPackages, isLoadingBlockPackages };
}

export function useGetCommandBlocksPerPackage() {
  const { t } = useTranslation();

  const fetchBlocksPerPackage = async (): Promise<
    Record<string, CommandBlockType[]>
  > => {
    const packages = await commandBlocks.getAllPackages();
    const blocksPerPackage: Record<string, CommandBlockType[]> = {};

    if (packages.data.length === 0) return blocksPerPackage;
    await Promise.all(
      packages.data.map(async (pkg: string) => {
        const itemsPerPage = 10;
        const maxPagesToFetch = 10;
        let page = 1;
        let totalBlocks: CommandBlockType[] = [];
        let totalCount = 0;

        do {
          const response = await commandBlocks.getAllCommandBlocks({
            package: pkg,
            page,
            limit: itemsPerPage,
          });

          const blocks = response.data;
          totalBlocks = totalBlocks.concat(blocks);

          if (totalCount === 0) {
            totalCount = parseInt(response.headers['x-total-count']);
          }

          page++;
        } while (totalBlocks.length < totalCount && page < maxPagesToFetch);

        blocksPerPackage[pkg] = totalBlocks;
      }),
    );
    return blocksPerPackage;
  };

  const {
    data: blockPackages,
    isLoading: isLoadingBlockPackages,
    error,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['blockPackages'],
    queryFn: fetchBlocksPerPackage,
  });

  if (error) {
    onError(t, error);
  }

  return { blockPackages, isLoadingBlockPackages };
}

function onError(t: TFunction, error: unknown) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}

import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStackFields = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-end',
  marginBlockStart: theme.spacing(5),
  marginBlockEnd: theme.spacing(4),
}));

export const StyledStackButtons = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'space-between',
}));

export const StyledListItemsStack = styled(Stack)(({ theme }) => ({
  maxHeight: theme.spacing(63.75),
  overflow: 'auto',
}));

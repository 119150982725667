import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import Text from '@components/text';

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    border: '1px solid',
    borderColor: theme.palette.border.secondary,
    marginInline: theme.spacing(9),
  },
  marginInline: theme.spacing(4),
  marginBlockStart: theme.spacing(5),

  borderRadius: theme.shape.borderRadius * 3,
  paddingBlockEnd: theme.spacing(4),
  textAlign: 'center',
  overflow: 'hidden',
}));

export const StyledText = styled(Text)(({ theme }) => ({
  paddingBlockEnd: theme.spacing(3),
  paddingBlockStart: theme.spacing(4),
}));

/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  AssistantsType,
  CreateAssistantParamsType,
  GetAllAssistantsResponseType,
} from '@shared-types/assistants';

export const assistants = {
  getAll: () =>
    requests(axiosApiInstance).get<GetAllAssistantsResponseType>(
      'v1/entities/bots',
    ),
  createNew: (body: CreateAssistantParamsType) =>
    requests(axiosApiInstance).post('v1/entities/bots', body),
  getOne: (id: string) =>
    requests(axiosApiInstance).get<AssistantsType>(`v1/entities/bots/${id}`),
};

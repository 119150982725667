import Box from '@mui/material/Box';

import { StyledBox } from '@components/workspace/setup/sections/steps/connectors/tabs/panels/styles';

type Props = {
  index: number;
  value: number;
};

export default function Panels({
  index,
  value,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <StyledBox
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`tab-${index}`}
    >
      <Box>{children}</Box>
    </StyledBox>
  );
}

import StyledSvg from '@components/bg-gradient/styles';

export default function BgGradient() {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
      fill="none"
      preserveAspectRatio="xMidYMid slice"
    >
      <g opacity="0.7">
        <mask
          id="mask0_2102_347781"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="-1001"
          y="-535"
          width="4170"
          height="1825"
        >
          <rect
            x="-1000.5"
            y="-535"
            width="4169"
            height="1825"
            fill="url(#paint0_linear_2102_347781)"
          />
        </mask>
        <g mask="url(#mask0_2102_347781)">
          <g opacity="0.9">
            <g opacity="0.6">
              <g
                opacity="0.33"
                filter="url(#filter0_f_2102_347781)"
              >
                <path
                  d="M2232.07 483.773C2073.16 483.773 1944.34 238.797 1944.34 -63.3933C1944.34 -233.551 1985.19 -385.571 2049.25 -485.925C1956.62 -459.191 1823.15 -564.84 1770.64 -462.212C1728.83 -380.505 1758 -100.804 1758 6.55859C1758 284.523 1922.47 509.856 2125.35 509.856C2166.6 509.856 2206.26 500.543 2243.27 483.365C2239.55 483.636 2235.82 483.773 2232.07 483.773Z"
                  fill="url(#paint1_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.33"
                filter="url(#filter1_f_2102_347781)"
              >
                <path
                  d="M1901.29 117.381C1901.29 453.794 1994.7 726.51 2109.92 726.51C2225.15 726.51 2518.49 506.743 2518.49 170.33C2518.49 28.0582 2460.24 -360.358 2432.25 -464.036C2394.04 -605.512 2176.42 -491.749 2109.92 -491.749C2043.43 -491.749 1871.64 -605.512 1833.44 -464.036C1805.44 -360.358 1901.29 -24.891 1901.29 117.381Z"
                  fill="url(#paint2_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.33"
                filter="url(#filter2_f_2102_347781)"
              >
                <path
                  d="M2095 471.933C2103.14 474.285 2111.41 475.492 2119.79 475.492C2242.06 475.492 2341.17 218.689 2341.17 -98.0921C2341.17 -272.912 2310.98 -429.462 2263.38 -534.669C2384.17 -474.691 2473.17 -269.13 2473.17 -24.7628C2473.17 266.622 2346.63 502.835 2190.53 502.835C2157 502.835 2124.84 491.935 2095 471.933Z"
                  fill="url(#paint3_linear_2102_347781)"
                />
              </g>
            </g>
            <g opacity="0.6">
              <g
                opacity="0.33"
                filter="url(#filter3_f_2102_347781)"
              >
                <path
                  d="M1072.4 817.477C1257.25 817.477 1407.1 572.447 1407.1 270.189C1407.1 99.9932 1359.59 -52.0599 1285.07 -152.437C1392.81 -125.697 1548.08 -231.37 1609.16 -128.718C1657.8 -46.9929 1623.87 232.77 1623.87 340.156C1623.87 618.182 1432.54 843.566 1196.54 843.566C1148.56 843.566 1102.42 834.25 1059.36 817.069C1063.69 817.34 1068.03 817.477 1072.4 817.477Z"
                  fill="url(#paint4_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.22"
                filter="url(#filter4_f_2102_347781)"
              >
                <path
                  d="M1190.8 645.079C1182.66 647.432 1174.38 648.638 1166 648.638C1043.74 648.638 944.624 391.835 944.624 75.0544C944.624 -99.7652 974.809 -256.316 1022.41 -361.522C901.619 -301.544 595.163 -133.922 595.163 110.445C595.163 401.829 939.162 675.981 1095.26 675.981C1128.79 675.981 1160.96 665.082 1190.8 645.079Z"
                  fill="url(#paint5_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.44"
                filter="url(#filter5_f_2102_347781)"
              >
                <path
                  d="M1332.29 358.634C1332.29 695.177 1216.84 968 1074.42 968C932.003 968 816.55 695.177 816.55 358.634C816.55 216.307 641.422 -119.29 676.026 -223.008C723.247 -364.539 992.233 -250.731 1074.42 -250.731C1156.61 -250.731 1368.94 -364.539 1416.17 -223.008C1450.77 -119.29 1332.29 216.307 1332.29 358.634Z"
                  fill="url(#paint6_linear_2102_347781)"
                />
              </g>
            </g>
            <g opacity="0.6">
              <g
                opacity="0.33"
                filter="url(#filter6_f_2102_347781)"
              >
                <path
                  d="M157.722 593.636C-1.18346 593.636 49.4376 245.532 49.4376 -56.6584C49.4376 -226.816 -89.1586 -275.707 -25.0981 -376.061C-117.72 -349.327 -251.194 -454.977 -303.709 -352.348C-345.518 -270.641 -613.506 -112.089 -613.506 -4.72699C-613.506 273.237 -412.633 854.246 -209.75 854.246C-168.502 854.246 131.914 610.406 168.927 593.229C165.209 593.5 -0.15448 632.017 157.722 593.636Z"
                  fill="url(#paint7_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.33"
                filter="url(#filter7_f_2102_347781)"
              >
                <path
                  d="M4.94208 117.381C4.94208 453.793 98.3493 726.51 213.573 726.51C328.797 726.51 422.204 453.793 422.204 117.381C422.204 -24.891 563.892 -360.358 535.895 -464.036C497.691 -605.512 280.068 -491.749 213.573 -491.749C147.078 -491.749 -24.7109 -605.512 -62.9151 -464.036C-90.9121 -360.358 4.94208 -24.891 4.94208 117.381Z"
                  fill="url(#paint8_linear_2102_347781)"
                />
              </g>
              <g
                opacity="0.33"
                filter="url(#filter8_f_2102_347781)"
              >
                <path
                  d="M198.646 471.933C206.787 474.285 215.061 475.492 223.444 475.492C345.706 475.492 444.819 218.689 444.819 -98.0921C444.819 -272.912 414.635 -429.462 367.032 -534.669C487.824 -474.691 576.823 -269.13 576.823 -24.7628C576.823 266.622 450.281 502.835 294.183 502.835C260.653 502.835 228.487 491.935 198.646 471.933Z"
                  fill="url(#paint9_linear_2102_347781)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2102_347781"
          x="1355.11"
          y="-901.041"
          width="1281.15"
          height="1803.88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="196.494"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter1_f_2102_347781"
          x="1337.08"
          y="-1026.24"
          width="1672.65"
          height="2243.98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="245.617"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter2_f_2102_347781"
          x="1740.95"
          y="-888.712"
          width="1086.26"
          height="1745.59"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="177.022"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter3_f_2102_347781"
          x="666.376"
          y="-567.557"
          width="1361.99"
          height="1804.11"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="196.494"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter4_f_2102_347781"
          x="241.12"
          y="-715.566"
          width="1303.72"
          height="1745.59"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="177.022"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter5_f_2102_347781"
          x="228.945"
          y="-736.554"
          width="1636.11"
          height="2147.11"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="221.277"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter6_f_2102_347781"
          x="-1006.49"
          y="-791.177"
          width="1568.41"
          height="2038.41"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="196.494"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter7_f_2102_347781"
          x="-559.274"
          y="-1026.24"
          width="1590.07"
          height="2243.98"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="245.617"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <filter
          id="filter8_f_2102_347781"
          x="-155.397"
          y="-888.712"
          width="1086.26"
          height="1745.59"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="177.022"
            result="effect1_foregroundBlur_2102_347781"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2102_347781"
          x1="1084.5"
          y1="-59"
          x2="1084.56"
          y2="881"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop
            offset="0.980694"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2102_347781"
          x1="1995.69"
          y1="-508.053"
          x2="1995.69"
          y2="509.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D444F1" />
          <stop
            offset="1"
            stopColor="#6938EF"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2102_347781"
          x1="2142.53"
          y1="626.459"
          x2="2102.21"
          y2="-533.962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#36BFFA" />
          <stop
            offset="1"
            stopColor="#026AA2"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2102_347781"
          x1="2227.58"
          y1="502.835"
          x2="2788.94"
          y2="-540.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D46A" />
          <stop
            offset="1"
            stopColor="#F2FD72"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2102_347781"
          x1="1347.37"
          y1="-174.569"
          x2="1347.37"
          y2="843.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D444F1" />
          <stop
            offset="1"
            stopColor="#6938EF"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2102_347781"
          x1="1058.21"
          y1="675.981"
          x2="496.856"
          y2="-367.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D46A" />
          <stop
            offset="1"
            stopColor="#F2FD72"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2102_347781"
          x1="1034.12"
          y1="867.91"
          x2="1066.78"
          y2="-293.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#36BFFA" />
          <stop
            offset="1"
            stopColor="#026AA2"
          />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2102_347781"
          x1="6.7002"
          y1="-466.414"
          x2="6.7002"
          y2="551.495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D444F1" />
          <stop
            offset="1"
            stopColor="#6938EF"
          />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2102_347781"
          x1="246.181"
          y1="626.459"
          x2="205.862"
          y2="-533.962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#36BFFA" />
          <stop
            offset="1"
            stopColor="#026AA2"
          />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2102_347781"
          x1="331.232"
          y1="502.835"
          x2="892.588"
          y2="-540.989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D46A" />
          <stop
            offset="1"
            stopColor="#F2FD72"
          />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
}

import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// TODO: this should be badge based on the design not an iconButton
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: `${theme.spacing(6.25)}!important`,
  height: `${theme.spacing(6.25)}!important`,
  backgroundColor: theme.palette.utility.brand50,
  border: `1px solid ${theme.palette.utility.brand200}`,
  '&.active': {
    visibility: 'hidden',
  },
})) as typeof IconButton;

export default StyledIconButton;

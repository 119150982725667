import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledBoxButtons = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
  },
}));

export const StyledBoxWrapper = styled(Box)(({ theme }) => ({
  paddingBlockStart: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    paddingBlockStart: theme.spacing(8),
  },
}));

import Card from '@mui/material/Card';
import Grid2 from '@mui/material/Grid2';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';

import Markdown from '@components/markdown';
import SectionHeading from '@components/pages/search/components/SectionHeading';

const ORB_ICON_ELEMENT = (
  <Icon>
    <img
      src="https://assets.knowz.com/assets/svg/orb---small.png"
      alt="answer section"
    />
  </Icon>
);

type Props = {
  content: string;
  heading: string;
};

export default function GeneralMessage({ content, heading }: Props) {
  return (
    <Grid2
      container
      spacing={5}
      mb={5}
    >
      <Grid2 size={12}>
        <Stack gap={5}>
          <Card>
            <SectionHeading
              title={heading}
              icon={ORB_ICON_ELEMENT}
            >
              <Markdown>{content}</Markdown>
            </SectionHeading>
          </Card>
        </Stack>
      </Grid2>
    </Grid2>
  );
}

import StyledText from '@components/ellipsis-typography/styles';
import Text from '@components/text';

type Props = React.ComponentProps<typeof Text> & {
  lineClamp: number;
};

export default function EllipsisText({
  children,
  lineClamp,
  sx,
  title,
  ...otherProps
}: Props) {
  return (
    <StyledText
      title={title}
      sx={{
        ...sx,
        WebkitLineClamp: lineClamp,
      }}
      {...otherProps}
    >
      {children}
    </StyledText>
  );
}

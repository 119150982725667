/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosAuthInstance from '@lib/agent/services/auth/axiosInstance';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import type {
  AuthResponseType,
  EmailLookupBodyType,
  EmailLookupResponseType,
  LoginBodyType,
  ResetPasswordType,
  SendEmailVerificationBodyType,
  SendEmailVerificationResponseType,
  SendResetTokenType,
  SignUpRequestType,
  TransparentLoginBodyType,
  TransparentSignupBodyType,
  ValidateResetToken,
  VerifyEmailBodyType,
  VerifyEmailResponseType,
  UpdateMeRequestType,
} from '@shared-types/auth';
import { AccessConfigurationMinType } from '@shared-types/sso';

export const auth = {
  signUp: (body: TransparentSignupBodyType) =>
    requests(axiosAuthInstance).post<AuthResponseType>('/api/signup', body),
  promotiveSignUp: (body: SignUpRequestType, token: string) =>
    requests(axiosAuthInstance).post<AuthResponseType>(
      '/api/add-auth-method',
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  login: (body: TransparentLoginBodyType | LoginBodyType) =>
    requests(axiosAuthInstance).post<AuthResponseType>('/api/login', body),
  emailLookup: (body: EmailLookupBodyType) =>
    requests(axiosAuthInstance).post<EmailLookupResponseType>(
      '/api/lookup',
      body,
    ),
  sendEmailVerification: (body: SendEmailVerificationBodyType) =>
    requests(axiosAuthInstance).post<SendEmailVerificationResponseType>(
      '/api/email/send-validation-code',
      body,
    ),
  verifyEmail: (body: VerifyEmailBodyType) =>
    requests(axiosAuthInstance).post<VerifyEmailResponseType>(
      '/api/email/validate',
      body,
    ),
  refreshToken: (token: string) =>
    requests(axiosAuthInstance).post<AuthResponseType>(
      '/api/refresh-token',
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  sendResetToken: (body: SendResetTokenType) =>
    requests(axiosAuthInstance).post('/api/password/send-reset-token', body),

  validateResetToken: (body: ValidateResetToken) =>
    requests(axiosAuthInstance).post('/api/password/token/validate', body),

  resetPassword: (body: ResetPasswordType) =>
    requests(axiosAuthInstance).post('/api/password/reset', body),

  getAccessConfigurations: () =>
    requests(axiosAuthInstance).get<AccessConfigurationMinType[]>(
      '/api/access-configurations',
    ),
  updateMe: (body: UpdateMeRequestType) =>
    requests(axiosApiInstance).patch('/v1/entities/users/me', body),
};

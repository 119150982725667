import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import {
  StyledBox,
  StyledText,
} from '@components/workspace/setup/sections/steps/connectors/styles';
import Tabs from '@components/workspace/setup/sections/steps/connectors/tabs';

import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import CubeIcon from '~icons/knowz-iconify/cube';

export default function Connectors() {
  const { handleGoToPreviousStep, handleReset } = useWorkspaceSetup();
  const { t } = useTranslation();
  const { removeQueryParamByKey } = useQueryParams();

  function handleFinishWorkspaceSetup() {
    removeQueryParamByKey('action');
    handleReset();
  }

  return (
    <>
      <Header
        Icon={
          <FeaturedIcon>
            <CubeIcon />
          </FeaturedIcon>
        }
        title={t('page.workspace.createWorkspaceModal.step4.title', {
          defaultValue: 'Knowledge Base Connectors',
        })}
        subtitle={t('page.workspace.createWorkspaceModal.step4.subtitle', {
          defaultValue:
            'Set up your connectors now, or later, these can be used to upload and keep files synced from your external platforms as well as to *export Knowz Ai to MS Teams & Slack to communicate with on those platforms. *(Requires a Teams Plan)',
        })}
      />
      <StyledBox>
        <StyledText
          variant="textSm"
          weight="semibold"
        >
          {t('page.workspace.createWorkspaceModal.step4.connectors.title', {
            defaultValue: 'Import or sync your data with external connectors',
          })}
        </StyledText>
        <Tabs />
      </StyledBox>
      <ButtonWrapper>
        <Stack
          direction="row"
          gap={3}
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="inherit"
            onClick={handleGoToPreviousStep}
          >
            {t('page.workspace.createWorkspaceModal.backButton', {
              defaultValue: 'Back',
            })}
          </Button>
          <Button
            onClick={handleFinishWorkspaceSetup}
            size="large"
            variant="contained"
          >
            {t('page.workspace.createWorkspaceModal.finishButton', {
              defaultValue: 'Finish',
            })}
          </Button>
        </Stack>
      </ButtonWrapper>
    </>
  );
}

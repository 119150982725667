import { SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import StyledLink from '@components/logo/styles';

import useAppDispatch from '@hooks/useAppDispatch';
import useWorkspace from '@hooks/useWorkspace';

import { imageUploaded } from '@state/slices/search-result';

import KnowzIcon from '~icons/knowz-iconify/knowz-monotone';

import paths from '@router/paths';

type Props = Partial<{
  variant: 'mini' | 'full';
  sx: SxProps;
}>;

const MINI_LOGO_SIZE = 45;
const KNOWZ_LOGO_SIZE = 32;

export default function Logo({ variant, sx }: Props) {
  const isMini = variant === 'mini';
  const theme = useTheme();

  const { setupMode } = useWorkspace();

  const dispatch = useAppDispatch();

  let logo;

  if (isMini) {
    logo = (
      <Box
        sx={sx}
        component="img"
        width={MINI_LOGO_SIZE}
        height={MINI_LOGO_SIZE}
        alt="knowz"
        src="https://assets.knowz.com/assets/svg/knowz-favicon---gradient.svg" //TODO: use icons
      />
    );
  }

  if (!isMini) {
    logo = (
      <KnowzIcon
        style={{
          color:
            theme.palette.mode === 'light'
              ? theme.palette.common.black
              : theme.palette.common.white,
          fontSize: KNOWZ_LOGO_SIZE,
        }}
      />
    );
  }

  function handleClearImageData() {
    dispatch(
      imageUploaded({
        threadId: null,
        currentImageKey: null,
        previews: [],
      }),
    );
  }

  return (
    <StyledLink
      to={
        setupMode === 'on' ? paths.knowledgeBase.pathname : paths.home.pathname
      }
      onClick={handleClearImageData}
    >
      {logo}
    </StyledLink>
  );
}

import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingInline: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingInline: theme.spacing(6),
  },
  paddingBlockStart: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingBlockStart: theme.spacing(5),
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: theme.spacing(71),
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledImg = styled('img')(() => ({
  objectFit: 'cover',
  borderRadius: '50%',
}));

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import Logo from '@components/logo';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import {
  StyledIconButton,
  StyledImage,
} from '@components/user-authentication/sections/modal-stepper/desktop/styles';
import ModalWrapper from '@components/user-authentication/sections/modal-stepper/ModalWrapper';

import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

const MODAL_WIDTH = 848;
const MODAL_HEIGHT = 600;

const IMAGE_PLACEHOLDER_WIDTH = 424;
const IMAGE_PLACEHOLDER_HEIGHT = 600;

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { removeQueryParamByKey } = useQueryParams();

  const theme = useTheme();

  const { resetForm } = useUserAuth();

  const { handleAttemptedToLogin } = useAuth();

  const { resetWorkspaceToIndividualRole } = useWorkspace();

  function closeModal() {
    resetForm();
    handleAttemptedToLogin(true);
    removeQueryParamByKey('action');
    resetWorkspaceToIndividualRole();
  }

  return (
    <ModalWrapper
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
    >
      <StyledIconButton
        color="outlinedSecondary"
        onClick={closeModal}
      >
        <CloseIcon />
      </StyledIconButton>
      <Stack sx={{ p: 8, width: '100%' }}>
        <Logo />
        {children}
      </Stack>
      <StyledImage
        src={`https://assets.knowz.com/assets/images/login-${theme.palette.mode}.jpg`}
        alt="placeholder"
        width={IMAGE_PLACEHOLDER_WIDTH}
        height={IMAGE_PLACEHOLDER_HEIGHT}
        loading="lazy"
      />
    </ModalWrapper>
  );
}

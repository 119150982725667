import { useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Modal from '@components/modal';
import FileUpload from '@components/search-form/react-hook-form-upload-select/components/file-upload';
import CONFIG from '@components/search-form/react-hook-form-upload-select/components/file-upload/config';

import useAppSelector from '@hooks/useAppSelector';

import { useTranslation } from '@desygner/ui-common-translation';

import PlusSquareIcon from '~icons/knowz-iconify/plus-square';

import '@uppy/webcam/dist/style.min.css';

import { toast } from 'react-toastify';

export default function RHFUploadSelect() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const uploadedImages = useAppSelector(
    (state) => state.searchResult.image?.previews,
  );

  function handleIsFileUploadModalOpen() {
    if (
      uploadedImages &&
      uploadedImages?.length >= CONFIG.MAX_NUMBER_OF_FILES
    ) {
      toast.error(
        t('response.error.maxNumberOfFiles', {
          defaultValue: 'You have reached the maximum number of files.',
        }),
      );
      return;
    }
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  return (
    <Box>
      <IconButton
        size="small"
        onClick={handleIsFileUploadModalOpen}
        color="tertiarySecondary"
      >
        <PlusSquareIcon />
      </IconButton>

      <Modal
        width={{
          xs: '90%',
          md: '50%',
        }}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <FileUpload handleCloseModal={handleCloseModal} />
      </Modal>
    </Box>
  );
}

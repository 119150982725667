import { useMemo } from 'react';

import { TabPanel } from '@components/settings/components/tab-panel';
import General from '@components/settings/components/tabs/general';
import Search from '@components/settings/components/tabs/search';
import CONFIG from '@components/settings/config';

export type Props = {
  onClose: () => void;
  tab: number | false;
};

export default function Desktop({ onClose, tab }: Props) {
  const tabs = useMemo(
    () => [
      //TODO: add new tab Item here
      <General onClose={onClose} />,
      <Search onClose={onClose} />,
    ],
    [onClose],
  );
  return CONFIG.TAB_ITEMS.map((item, index) => (
    <TabPanel
      key={item.id}
      value={tab || 0}
      index={index}
    >
      {tabs[index]}
    </TabPanel>
  ));
}

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

export default function CloseButton() {
  const { closeSourcePreviewCard } = useSourcePreview();

  return (
    <IconButton
      color="tertiarySecondary"
      size="small"
      onClick={() => {
        closeSourcePreviewCard();
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}

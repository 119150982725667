import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  borderRadius: '50%',
  backgroundColor: alpha('#A4A4A433', 0.5),
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default StyledBox;

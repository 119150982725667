import WorkspaceSetupProvider from '@components/workspace/setup/providers/WorkspaceSetupProvider';

export default function WorkspaceSetup() {
  return (
    <WorkspaceSetupProvider>
      <WorkspaceSetupProvider.ModalWrapper>
        <WorkspaceSetupProvider.Steps
          createWorkspace={<WorkspaceSetupProvider.Steps.CreateWorkspace />}
          upload={<WorkspaceSetupProvider.Steps.Upload />}
          generateIcon={<WorkspaceSetupProvider.Steps.GenerateIcon />}
          cropper={<WorkspaceSetupProvider.Steps.Cropper />}
          invitation={<WorkspaceSetupProvider.Steps.InviteMembers />}
          connectors={<WorkspaceSetupProvider.Steps.Connectors />}
        />
      </WorkspaceSetupProvider.ModalWrapper>
    </WorkspaceSetupProvider>
  );
}

import Container from '@mui/material/Container';

import CONFIG from '@components/workspace/setup/config';
import StyledModal from '@components/workspace/setup/sections/modal-stepper/styles';

import useQueryParams from '@hooks/useQueryParams';

export default function ModalWrapper({ children }: React.PropsWithChildren) {
  const { getQueryParamByKey } = useQueryParams();
  const actionQueryParam = getQueryParamByKey<'setup-workspace' | null>(
    'action',
  );

  return (
    <StyledModal
      open={actionQueryParam === 'setup-workspace'}
      width={{
        xs: CONFIG.MODAL.MOBILE.WIDTH,
        md: CONFIG.MODAL.DESKTOP.WIDTH,
      }}
      height={{
        xs: CONFIG.MODAL.MOBILE.HEIGHT,
        md: CONFIG.MODAL.DESKTOP.HEIGHT,
      }}
      disableRestoreFocus
    >
      <Container disableGutters>{children}</Container>
    </StyledModal>
  );
}

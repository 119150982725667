import { useState } from 'react';

import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Panels from '@components/workspace/setup/sections/steps/connectors/tabs/panels';
import TabPanelNumber0 from '@components/workspace/setup/sections/steps/connectors/tabs/panels/num-0';
import TabPanelNumber1 from '@components/workspace/setup/sections/steps/connectors/tabs/panels/num-1';
import TabPanelNumber2 from '@components/workspace/setup/sections/steps/connectors/tabs/panels/num-2';
import TabPanelNumber3 from '@components/workspace/setup/sections/steps/connectors/tabs/panels/num-3';
import {
  StyledStack,
  StyledTab,
  StyledTabs,
} from '@components/workspace/setup/sections/steps/connectors/tabs/styles';
import TabItemNumber0 from '@components/workspace/setup/sections/steps/connectors/tabs/tab-item/num-0';
import TabItemNumber1 from '@components/workspace/setup/sections/steps/connectors/tabs/tab-item/num-1';
import TabItemNumber2 from '@components/workspace/setup/sections/steps/connectors/tabs/tab-item/num-2';
import TabItemNumber3 from '@components/workspace/setup/sections/steps/connectors/tabs/tab-item/num-3';

import useResponsive from '@hooks/useResponsive';

const TABS = [
  <TabItemNumber0 />,
  <TabItemNumber1 />,
  <TabItemNumber2 />,
  <TabItemNumber3 />,
];

const TAB_PANELS: Record<number, JSX.Element> = {
  0: <TabPanelNumber0 />,
  1: <TabPanelNumber1 />,
  2: <TabPanelNumber2 />,
  3: <TabPanelNumber3 />,
};

export default function Tabs() {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isItMobile = useResponsive('down', 'sm');

  function handleChangeDropdown(event: SelectChangeEvent) {
    setValue(parseInt(event.target.value));
  }

  return (
    <StyledStack gap={6}>
      {isItMobile ? (
        <Select
          fullWidth
          value={String(value)}
          onChange={handleChangeDropdown}
        >
          <MenuItem value={0}>
            <TabItemNumber0 />
          </MenuItem>
          <MenuItem value={1}>
            <TabItemNumber1 />
          </MenuItem>
          <MenuItem value={2}>
            <TabItemNumber2 />
          </MenuItem>
          <MenuItem value={3}>
            <TabItemNumber3 />
          </MenuItem>
        </Select>
      ) : (
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          TabIndicatorProps={{
            sx: {
              backgroundColor: 'background.primaryAlt',
              border: '1px solid',
              borderColor: 'border.primary',
              height: '100%',
              borderRadius: 2,
            },
          }}
        >
          {TABS.map((tab, index) => (
            <StyledTab
              key={index}
              label={tab}
            />
          ))}
        </StyledTabs>
      )}
      <Panels
        index={value}
        value={value}
      >
        {TAB_PANELS[value]}
      </Panels>
    </StyledStack>
  );
}

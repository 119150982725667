import { Theme, useTheme } from '@mui/material';
import MuiPopover from '@mui/material/Popover';

import CONFIG from '@layouts/main/sections/toolbar/components/workspaces-popover/config';
import usePopover from '@layouts/main/sections/toolbar/components/workspaces-popover/hooks/usePopover';
import Content from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content';
import Footer from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/footer';
import Header from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/header';

export default function Popover({ children }: React.PropsWithChildren) {
  const { isPopoverOpen, handleClose, anchorEl } = usePopover();
  const theme = useTheme();

  return (
    <MuiPopover
      open={isPopoverOpen}
      aria-label="knowledge base popover"
      onClose={handleClose}
      anchorEl={anchorEl}
      slotProps={{
        paper: {
          sx: {
            width: CONFIG.POPOVER.WIDTH,
            padding: 0,
            ...generateArrow(theme, 'left'),
          },
        },
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: -20,
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      {children}
    </MuiPopover>
  );
}

Popover.Header = Header;
Popover.Content = Content;
Popover.Footer = Footer;

function generateArrow(
  theme: Theme,
  position: 'left' | 'right' | 'top' | 'bottom',
) {
  const positionMap = {
    left: {
      transform: 'translate(-50%, 50%) rotate(225deg)',
    },
    right: {},
    top: {},
    bottom: {},
  };

  return {
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      bottom: 0,
      left: 0,
      top: '50%',
      width: 14,
      height: 14,
      backgroundColor: theme.palette.background.card.light,
      boxShadow: theme.shadows[1],
      ...positionMap[position],
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  };
}

import { forwardRef } from 'react';

import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';

import useResponsive from '@hooks/useResponsive';

type Props = React.PropsWithChildren<{ sx?: SxProps }>;

const FieldSetWrapper = forwardRef<HTMLDivElement, Props>(
  ({ children, sx }, ref) => {
    const isItMobile = useResponsive('down', 'sm');

    return (
      <Stack
        ref={ref}
        direction={isItMobile ? 'column' : 'row'}
        gap={4}
        sx={sx}
      >
        {children}
      </Stack>
    );
  },
);

export default FieldSetWrapper;

import { auth } from '@lib/agent';
import { useMutation } from '@tanstack/react-query';

type Props = {
  onSuccess:
    | ((data: unknown, context: unknown) => Promise<unknown> | unknown)
    | undefined;
  onError:
    | ((error: Error, context: unknown) => Promise<unknown> | unknown)
    | undefined;
};

export default function useMutateMe({ onSuccess, onError }: Props) {
  return useMutation({
    mutationKey: ['me'],
    mutationFn: async ({
      firstName,
      lastName,
      profilePicture,
    }: {
      firstName?: string;
      lastName?: string;
      profilePicture?: null;
    }) =>
      await auth.updateMe({
        first_name: firstName,
        last_name: lastName,
        profile_picture: profilePicture,
      }),
    onSuccess,
    onError,
  });
}

import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Modal from '@components/modal';
import StyledBox from '@components/settings/components/modal-wrapper/styles';
import CONFIG from '@components/settings/config';

import CloseIcon from '~icons/knowz-iconify/cross';

export type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  tabs: React.ReactNode;
};

export const MAX_MODAL_HEIGHT = '80vh';
export const HEADER_HEIGHT = '72px';

export default function ModalWrapper({ open, onClose, children, tabs }: Props) {
  const theme = useTheme();

  return (
    <Modal
      width={{
        xs: '100%',
        md: 680,
      }}
      height={{
        xs: '100%',
        md: 'auto',
      }}
      open={open}
      onClose={onClose}
      sx={{
        maxHeight: MAX_MODAL_HEIGHT,
        borderRadius: {
          xs: 0,
          md: 3,
        },
      }}
    >
      <StyledBox>
        <CONFIG.FormHeader title={'Settings'}>
          <IconButton
            color="tertiarySecondary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </CONFIG.FormHeader>
        {tabs}
      </StyledBox>
      {children}
    </Modal>
  );
}

import Stack from '@mui/material/Stack';

import Text from '@components/text';

type Props = {
  icon?: React.ReactNode;
  title: string;
  description?: string;
  children?: React.ReactNode;
};

export default function Row({ icon, title, description, children }: Props) {
  return (
    <Stack
      width="100%"
      gap={2}
      direction="row"
      alignItems="start"
    >
      <Stack
        direction="row"
        gap={2}
        alignItems="flex-start"
        flexGrow={1}
      >
        {icon}

        <Stack
          gap={1}
          flex={1}
        >
          <Text variant="textMd">{title}</Text>
          {description && (
            <Text
              variant="textSm"
              color="grey"
            >
              {description}
            </Text>
          )}
        </Stack>
      </Stack>

      <Stack alignItems="flex-end">{children}</Stack>
    </Stack>
  );
}

import Divider from '@mui/material/Divider';

import {
  StyledBoxButtons,
  StyledBoxWrapper,
} from '@components/workspace/setup/components/button-wrapper/styles';

export default function ButtonWrapper({ children }: React.PropsWithChildren) {
  return (
    <StyledBoxWrapper>
      <Divider />
      <StyledBoxButtons>{children}</StyledBoxButtons>
    </StyledBoxWrapper>
  );
}

import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2.5,
  border: '1px solid',
  borderColor: theme.palette.border.secondary,
  height: theme.spacing(44),
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
}));

export const StyledUploadZoneStack = styled(Stack)(({ theme }) => ({
  paddingInline: theme.spacing(6),
  paddingBlockStart: theme.spacing(5),
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

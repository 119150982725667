import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import useCreateWorkspace from '@components/workspace/setup/hooks/useCreateWorkspace';
import useUpdateWorkspaceById from '@components/workspace/setup/hooks/useUpdateWorkspaceById';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import createWorkspaceSchema from '@components/workspace/setup/schema';
import {
  StyledFormWrapper,
  StyledText,
} from '@components/workspace/setup/sections/steps/create-workspace/form/styles';

import { queryClient } from '@providers/ReactQueryProvider';

import useQueryParams from '@hooks/useQueryParams';
import useResponsive from '@hooks/useResponsive';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { WorkspaceResponseType } from '@shared-types/workspace';

import { yupResolver } from '@hookform/resolvers/yup';
import paths from '@router/paths';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export default function Form() {
  const { t } = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { getNamespacedQueryKey } = useWorkspace();
  const {
    // handleGoToStep,
    currentCreatedWorkspaceDetails,
    handleSetCurrentCreatedWorkspaceDetails,
    handleReset,
  } = useWorkspaceSetup();
  const isItDesktop = useResponsive('up', 'md');
  const { removeQueryParamByKey } = useQueryParams();
  const { handleSetWorkspace, handleSetupMode } = useWorkspace();
  const navigateTo = useNavigate();

  function updateState(data: WorkspaceResponseType) {
    // handleGoToStep('upload'); //TODO: we will uncomment this line after we fix image gen issue and upload by link
    navigateTo(paths.knowledgeBase.pathname);
    handleReset();
    handleSetWorkspace(data);
    handleSetupMode('on');
    handleSetCurrentCreatedWorkspaceDetails({
      ...currentCreatedWorkspaceDetails,
      name: data.name,
      id: data.id,
    });
  }

  function handleTooltipMouseOver() {
    setIsTooltipOpen(true);
  }

  function handleTooltipMouseLeave() {
    setIsTooltipOpen(false);
  }

  const { isCreateWorkspacePending, mutateCreateWorkspaceAsync } =
    useCreateWorkspace((data) => {
      toast.success(
        `${t(
          'page.workspace.createWorkspaceModal.step1.toast.create.success.segment1',
          {
            defaultValue: 'Your Knowledge Base',
          },
        )} ${data.name} ${t(
          'page.workspace.createWorkspaceModal.step1.toast.create.success.segment2',
          {
            defaultValue:
              'was created successfully, you can go there now, or continue to customize it and invite users.',
          },
        )}`,
      );
      updateState(data);
    });

  const { isUpdateWorkspacePending, mutateUpdateWorkspaceAsync } =
    useUpdateWorkspaceById((data) => {
      toast.success(
        `${t('page.workspace.createWorkspaceModal.step1.toast.edit.success', {
          defaultValue: `Your Knowledge Base name was updated successfully to`,
        })} ${data.name}`,
      );
      //TODO: we need to revalidate react query data as well
      updateState(data);
    });

  const defaultValues = {
    name: currentCreatedWorkspaceDetails?.name ?? '',
  };

  const methods = useForm({
    resolver: yupResolver(createWorkspaceSchema),
    defaultValues,
  });

  const { handleSubmit, watch } = methods;
  const isFieldEmpty = watch('name').length === 0;

  async function onSubmit(data: { name: string }) {
    const name = data.name;

    //? it has already created with same name
    //TODO: we will uncomment this line after we fix image gen issue and upload by link
    // if (name === currentCreatedWorkspaceDetails?.name) {
    //   handleGoToStep('upload');
    //   return;
    // }

    //? trying to edit the workspace that just created
    if (currentCreatedWorkspaceDetails) {
      await mutateUpdateWorkspaceAsync({
        name,
        id: currentCreatedWorkspaceDetails.id!,
      });
      return;
    }

    await mutateCreateWorkspaceAsync({ name });

    const workspaces = getNamespacedQueryKey('workspaces');
    queryClient.invalidateQueries({
      queryKey: workspaces,
    });
  }

  function handleCloseWorkspaceSetup() {
    removeQueryParamByKey('action');
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledFormWrapper>
        <Stack
          direction={isItDesktop ? 'row' : 'column'}
          gap={2.5}
        >
          <StyledText
            variant="textSm"
            weight="medium"
          >
            {t('page.workspace.createWorkspaceModal.step1.form.name.label', {
              defaultValue: 'Name your Knowledge Base',
            })}
          </StyledText>
          <RHFInputField
            autoFocus
            fullWidth
            name="name"
            placeholder={t(
              'page.workspace.createWorkspaceModal.step1.form.name.placeholder',
              {
                defaultValue: 'Identify your Knowledge Base',
              },
            )}
          />
        </Stack>
      </StyledFormWrapper>
      <ButtonWrapper>
        <Stack
          direction={isItDesktop ? 'row-reverse' : 'column'}
          gap={3}
        >
          <Tooltip
            arrow
            placement="top"
            enterTouchDelay={0}
            open={isFieldEmpty && isTooltipOpen}
            onMouseOver={handleTooltipMouseOver}
            onMouseLeave={handleTooltipMouseLeave}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 7],
                    },
                  },
                ],
              },
            }}
            title={t(
              'page.workspace.createWorkspaceModal.step1.form.button.tooltip',
              {
                defaultValue: 'Name your Knowledge Base to continue',
              },
            )}
          >
            <span>
              <LoadingButton
                loading={isCreateWorkspacePending || isUpdateWorkspacePending}
                disabled={isFieldEmpty}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                size="large"
                fullWidth={!isItDesktop}
              >
                {t(
                  'page.workspace.createWorkspaceModal.step1.form.button.create',
                  {
                    defaultValues: 'Create & Continue',
                  },
                )}
              </LoadingButton>
            </span>
          </Tooltip>
          <Button
            variant="outlined"
            fullWidth={!isItDesktop}
            onClick={handleCloseWorkspaceSetup}
          >
            {t('page.workspace.createWorkspaceModal.cancelButton', {
              defaultValue: 'Cancel',
            })}
          </Button>
        </Stack>
      </ButtonWrapper>
    </FormProvider>
  );
}

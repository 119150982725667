import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

const AVATAR_SIZE = 60;

export const StyledContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
});

export const StyledAvatarWrapper = styled(Box)({
  position: 'relative',
  borderRadius: '50%',
});

export const StyledAvatarImage = styled(Avatar)(() => ({
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
}));

// TODO: this is not an iconButton as design says, it is a badge
export const StyledEditButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -1,
  bottom: -1,
  width: '22px!important',
  height: '22px!important',
  '&, &:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? `${theme.palette.common.white}!important`
        : `${theme.palette.grey[800]}!important`,
    border: `1px solid ${theme.palette.border.secondary}`,
  },
}));

export const StyledControlsContainer = styled(Stack)({
  flexDirection: 'row',
  // justifyContent: 'space-between',
  // textAlign: 'left',
  width: '100%',
  height: 36,
});

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color:
    theme.palette[
      'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg'
    ],
  '.uppy-FileInput-btn': {
    display: 'none',
  },
})) as typeof LoadingButton;

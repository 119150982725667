import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import InvitationForm from '@components/invitation-form';
import Modal from '@components/modal';
import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import SkeletonLoading from '@components/pages/users/header/SkeletonLoading';
import useGetWorkspaceById from '@components/pages/users/hooks/useGetWorkspaceById';
import Text from '@components/text';
import WorkspaceSetupProvider from '@components/workspace/setup/providers/WorkspaceSetupProvider';

import { useTranslation } from '@desygner/ui-common-translation';

const MODAL_WIDTH = 600;

export default function Header() {
  const { t } = useTranslation();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { isWorkspaceDataLoading, workspaceData } = useGetWorkspaceById();

  function handleCloseModal() {
    setIsInviteModalOpen(!isInviteModalOpen);
  }

  return (
    <StyledStack>
      <Modal
        paddingType="sm"
        open={isInviteModalOpen}
        onClose={handleCloseModal}
        width={{
          xs: 320,
          sm: MODAL_WIDTH,
        }}
        height="auto"
      >
        <Stack
          sx={{ width: '100%' }}
          gap={4}
        >
          <Stack
            sx={{ justifyContent: 'space-between' }}
            direction="row"
          >
            <Text variant="displayXs">Invite Team members</Text>
            <IconButton
              color="tertiarySecondary"
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <WorkspaceSetupProvider>
            <InvitationForm onSubmitHandler={handleCloseModal} />
          </WorkspaceSetupProvider>
        </Stack>
      </Modal>

      {isWorkspaceDataLoading ? (
        <SkeletonLoading />
      ) : (
        <Text variant="displayXs">{workspaceData?.name}</Text>
      )}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleCloseModal}
      >
        {t('page.workspace.header.button', {
          defaultValue: 'Invite',
        })}
      </Button>
    </StyledStack>
  );
}

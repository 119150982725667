import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import RHFInputField from '@components/react-hook-form/RHFInputField';

export const STAR_SIZE = 26;

export const StyledStarStack = styled(Stack)(() => ({
  width: STAR_SIZE,
  height: STAR_SIZE,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledStarBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: STAR_SIZE,
  width: STAR_SIZE,
}));

export const StyledSearchButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: 'transparent !important',
  border: 'none !important',
  margin: theme.spacing(1),
  padding: '0 !important',
  minWidth: 40,
}));

export const StyledSearchFormWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid',
  borderColor: theme.palette.background.card.main,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 6,
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

export const StyledRHFInputField = styled(RHFInputField)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
  paddingInlineEnd: 0,
  paddingInlineStart: theme.spacing(2),
  height: theme.spacing(12),
  backgroundColor: theme.palette['Colors/Background/bg-active'],
  borderColor: theme.palette['Colors/Border/border-primary'],
}));

export const StyledPreviewContainer = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  marginBlockStart: theme.spacing(1),
  marginBlockEnd: theme.spacing(0.5),
  gap: theme.spacing(0.25),
}));

export const StyledImageBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: theme.spacing(35),
  height: theme.spacing(25),
  overflow: 'hidden',
  marginBlockStart: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 4,
  borderColor: alpha(theme.palette.common.white, 0.5),
  borderStyle: 'solid',
}));

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-0.5),
  right: theme.spacing(-0.5),
  padding: theme.spacing(0.5),
  width: `${theme.spacing(7)}!important`,
  height: `${theme.spacing(7)}!important`,
  marginInline: theme.spacing(0.5),
  marginBlock: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  border: `${theme.spacing(0.5)} solid ${alpha(
    theme.palette.common.white,
    0.5,
  )}`,

  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.9),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
}));

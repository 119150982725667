import { useEffect, useRef, useState } from 'react';

import { ClickAwayListener } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

import useLocalStorage from '@hooks/useLocalStorage';

import { StyledStack } from '@layouts/components/cookies-notice/styles';
import Transition from 'react-transition-group/Transition';

const COOKIE_WIDTH_SIZE = 78;
const COOKIE_HEIGHT_SIZE = 100;

export default function CookiesNotice() {
  const { value: acceptedCookies, setValue: setAcceptedCookies } =
    useLocalStorage<boolean | null>('cookies');

  const [showNotice, setShowNotice] = useState(false);
  const nodeRef = useRef(null);
  const handleDismissal = function (accepted: boolean) {
    accepted && setAcceptedCookies(true);
    setShowNotice(false);
  };

  useEffect(() => {
    acceptedCookies || setShowNotice(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: we need to use Slide component from MUI since the component is abstracted over transition it is more declarative and easier to use
  return (
    <Transition
      timeout={{ enter: 0, exit: 600 }}
      in={showNotice}
      nodeRef={nodeRef}
    >
      {(state) =>
        state !== 'exited' && (
          <ClickAwayListener onClickAway={() => handleDismissal(false)}>
            <StyledStack
              direction="row"
              spacing={6}
              className={`state-${state}`}
            >
              <img
                src="https://assets.knowz.com/assets/images/cookie.svg"
                alt="cookies"
                width={COOKIE_WIDTH_SIZE}
                height={COOKIE_HEIGHT_SIZE}
              />
              <Stack>
                <Text variant="displayXs">We use Cookies!</Text>

                <Text variant="textSm">
                  We use cookies to ensure that we give you the best experience
                  on our website. If you continue to use this site we will
                  assume that you are happy with it.
                </Text>

                <Stack
                  sx={{ mt: 4, justifyContent: 'flex-end' }}
                  direction="row"
                  spacing={2}
                >
                  <Button
                    href="https://knowz.com/privacy-policy"
                    target="_blank"
                  >
                    Cookies Policy
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleDismissal(true)}
                  >
                    OK
                  </Button>
                </Stack>
              </Stack>
            </StyledStack>
          </ClickAwayListener>
        )
      }
    </Transition>
  );
}

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  height: 'auto',
  padding: theme.spacing(3.5),
  // overflow: 'hidden',

  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'background-color 0.4s',
  maxWidth: '100%',
  minHeight: 'initial',
  padding: theme.spacing(2),
  color: theme.palette['Colors/Text/text-secondary (700)'],

  '&:hover': {
    transition: 'background-color 0s',
    backgroundColor:
      theme.palette['Component colors/Utility/Brand/utility-brand-50_alt'],
  },

  '&.Mui-selected': {
    backgroundColor:
      theme.palette['Component colors/Utility/Brand/utility-brand-100_alt'],

    color: theme.palette['Colors/Text/text-secondary_hover'],

    '& svg': {
      color:
        theme.palette[
          'Component colors/Components/Breadcrumbs/breadcrumb-brand-icon-fg_hover'
        ],
    },
  },
}));

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  marginInlineEnd: theme.spacing(6),
  marginBlock: theme.spacing(4),
}));

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 6, 5, 6),
}));

export const StyledHeaderIconBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
}));

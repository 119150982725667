import { live } from '@lib/agent/endpoints/live';

import { useTranslation } from '@desygner/ui-common-translation';

import { LiveLiveRequestType } from '@shared-types/live';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export default function useMutateLiveToken() {
  const { t } = useTranslation();

  const {
    mutateAsync: mutateLiveTokenAsync,
    isPending: isMutateLiveTokenPending,
  } = useMutation({
    mutationKey: ['createLiveToken'],
    mutationFn: async (body: LiveLiveRequestType) =>
      await live.createLiveToken(body),
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error('Error while creating live token', error);
    },
  });

  return {
    isMutateLiveTokenPending,
    mutateLiveTokenAsync,
  };
}

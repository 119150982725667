import { useTheme } from '@mui/material';

import StyledBox from '@components/workspace/setup/sections/steps/components/image-preview/styles';

import ImageRoundedIcon from '~icons/knowz-iconify/img-rounded';

export default function ImagePreview() {
  const theme = useTheme();

  return (
    <StyledBox>
      <ImageRoundedIcon
        fontSize="1.14em"
        color={theme.palette.forground.quaternary}
      />
    </StyledBox>
  );
}

import { alpha, iconButtonClasses, Theme } from '@mui/material';

const customIconButtonClasses = {
  containedPrimary: 'MuiIconButton-colorContainedPrimary',
  outlinedPrimary: 'MuiIconButton-colorOutlinedPrimary',
  outlinedSecondary: 'MuiIconButton-colorOutlinedSecondary',
  tertiaryPrimary: 'MuiIconButton-colorTertiaryPrimary',
  tertiarySecondary: 'MuiIconButton-colorTertiarySecondary',
} as const;

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      defaultProps: {
        size: 'medium',
        color: 'primary',
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          variants: [
            {
              props: {
                size: 'xlarge',
              },
              style: {
                width: theme.spacing(12),
                height: theme.spacing(12),
                svg: {
                  width: theme.spacing(5),
                  height: theme.spacing(5),
                },
                [`&.${iconButtonClasses.root}`]: {
                  borderRadius: theme.shape.borderRadius * 2,
                },
              },
            },
            {
              props: {
                size: 'xxlarge',
              },
              style: {
                width: theme.spacing(14),
                height: theme.spacing(14),
                svg: {
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                },
                [`&.${iconButtonClasses.root}`]: {
                  borderRadius: theme.shape.borderRadius * 2.5,
                },
              },
            },
          ],
          [`&.${iconButtonClasses.root}`]: {
            borderRadius: 'unset',
            '&:focus-visible': {
              outline: `2px solid ${theme.palette['Colors/Effects/Focus rings/focus-ring']}`,
              outlineOffset: '2px',
            },
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
          [`&.${iconButtonClasses.sizeSmall}`]: {
            width: theme.spacing(9),
            height: theme.spacing(9),
            borderRadius: theme.shape.borderRadius * 2,
            svg: {
              width: theme.spacing(5),
              height: theme.spacing(5),
            },
          },
          [`&.${iconButtonClasses.sizeMedium}`]: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            borderRadius: theme.shape.borderRadius * 2,
            svg: {
              width: theme.spacing(5),
              height: theme.spacing(5),
            },
          },
          [`&.${iconButtonClasses.sizeLarge}`]: {
            width: theme.spacing(11),
            height: theme.spacing(11),
            borderRadius: theme.shape.borderRadius * 2,
            svg: {
              width: theme.spacing(5),
              height: theme.spacing(5),
            },
          },
          [`&.${customIconButtonClasses.containedPrimary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Primary/button-primary-fg'
                ],
            },
            backgroundColor:
              theme.palette[
                'Component colors/Components/Buttons/Primary/button-primary-bg_hover'
              ],
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Primary/button-primary-bg'
                ],
            },
            boxShadow: `0px 1px 2px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)}, 
            0px -2px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner'], 0.05)} inset, 
            0px 0px 0px 1px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner-border'], 0.18)} inset`,
            '&::before': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              borderRadius: 'inherit',
              boxShadow: `inset 0px 0px 1px 2px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.02)}`,
            },
            [`&.${iconButtonClasses.disabled}`]: {
              backgroundColor: theme.palette.background.disabled,
              border: `1px solid ${theme.palette.border.disabledSubtle}`,
              boxShadow: `0 1px 2px 0 ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)}`,
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
          [`&.${iconButtonClasses.colorSecondary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary/button-secondary-fg_hover'
                ],
            },
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary/button-secondary-bg_hover'
                ],
            },
            backgroundColor:
              theme.palette[
                'Component colors/Components/Buttons/Secondary/button-secondary-bg'
              ],
            border: `1px solid ${theme.palette['Component colors/Components/Buttons/Secondary/button-secondary-border']}`,
            boxShadow: `
              0px 1px 2px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)},
              0px -2px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner'], 0.05)} inset,
              0px 0px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner-border'], 0.18)} inset
            `,
            [`&.${iconButtonClasses.disabled}`]: {
              backgroundColor: theme.palette.background.primary,
              border: `1px solid ${theme.palette.border.disabledSubtle}`,
              boxShadow: `0 1px 2px 0 ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)}`,
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
          [`&.${customIconButtonClasses.outlinedPrimary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary color/button-secondary-color-fg'
                ],
            },
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg_hover'
                ],
            },
            backgroundColor:
              theme.palette[
                'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg'
              ],
            border: `1px solid ${theme.palette['Component colors/Components/Buttons/Secondary color/button-secondary-color-border']}`,
            boxShadow: `
              0px 1px 2px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)},
              0px -2px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner'], 0.05)} inset,
              0px 0px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner-border'], 0.18)} inset
            `,
            [`&.${iconButtonClasses.disabled}`]: {
              backgroundColor: theme.palette.background.primary,
              border: `1px solid ${theme.palette.border.disabledSubtle}`,
              boxShadow: `0 1px 2px 0 ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)}`,
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
          [`&.${customIconButtonClasses.outlinedSecondary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary/button-secondary-fg'
                ],
            },
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Secondary/button-secondary-bg_hover'
                ],
            },
            backgroundColor:
              theme.palette[
                'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg'
              ],
            border: `1px solid ${theme.palette['Component colors/Components/Buttons/Secondary/button-secondary-border']}`,
            boxShadow: `
              0px 1px 2px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)},
              0px -2px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner'], 0.05)} inset,
              0px 0px 0px 0px ${alpha(theme.palette['Colors/Effects/Shadows/shadow-skeumorphic-inner-border'], 0.18)} inset
            `,
            [`&.${iconButtonClasses.disabled}`]: {
              backgroundColor: theme.palette.background.primary,
              border: `1px solid ${theme.palette.border.disabledSubtle}`,
              boxShadow: `0 1px 2px 0 ${alpha(theme.palette['Colors/Effects/Shadows/shadow-xs'], 0.05)}`,
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
          [`&.${customIconButtonClasses.tertiaryPrimary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg'
                ],
            },
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-bg_hover'
                ],
            },
            [`&.${iconButtonClasses.disabled}`]: {
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
          [`&.${customIconButtonClasses.tertiarySecondary}`]: {
            svg: {
              color:
                theme.palette[
                  'Component colors/Components/Buttons/Tertiary/button-tertiary-fg'
                ],
            },
            '&:hover': {
              backgroundColor:
                theme.palette[
                  'Component colors/Components/Buttons/Tertiary/button-tertiary-bg_hover'
                ],
            },
            [`&.${iconButtonClasses.disabled}`]: {
              svg: {
                color: theme.palette.forground.disabled,
              },
            },
          },
        },
      },
    },
  };
}

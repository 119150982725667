import Box from '@mui/material/Box';

import Header from '@components/workspace/setup/components/header';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import Form from '@components/workspace/setup/sections/steps/create-workspace/form';

import { useTranslation } from '@desygner/ui-common-translation';

import CubeIcon from '~icons/knowz-iconify/cube';

export default function CreateWorkspace() {
  const { t } = useTranslation();

  return (
    <Box>
      <Header
        Icon={
          <FeaturedIcon>
            <CubeIcon />
          </FeaturedIcon>
        }
        title={t('page.workspace.createWorkspaceModal.step1.title', {
          defaultValue: 'Create your Knowledge Base',
        })}
        subtitle={t('page.workspace.createWorkspaceModal.step1.description', {
          defaultValue:
            'Knowledge Bases define what Knowz knows, each one can be a rich source of customized data you can share with others or keep just to yourself.',
        })}
      />
      <Form />
    </Box>
  );
}

import { SearchScopeType } from '@providers/AppSettings';

import DataflowMainIcon from '~icons/knowz-iconify/dataflow-main-rounded';
import DataflowIcon from '~icons/knowz-iconify/dataflow-rounded';
import DataflowSidesIcon from '~icons/knowz-iconify/dataflow-sides-rounded';

type KnowledgeSourceIconType = {
  mode: SearchScopeType;
};

export default function KnowledgeSourceIcon({ mode }: KnowledgeSourceIconType) {
  return (
    <>
      {mode === 'web_only' && <DataflowSidesIcon />}
      {mode === 'kb_only' && <DataflowMainIcon />}
      {mode === 'web_kb' && <DataflowIcon />}
    </>
  );
}

import ModeDropdown from '@components/mode-dropdown';

import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
};

export default function RHFSearchModeSelect({ name }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => {
        return <ModeDropdown onChange={onChange} />;
      }}
    />
  );
}

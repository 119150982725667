import { threads } from '@lib/agent';

import { GenerateIconsRequestType } from '@shared-types/threads';

import { useMutation } from '@tanstack/react-query';

export default function useGenerateIcons() {
  return useMutation({
    mutationKey: ['generateIcons'],
    mutationFn: async ({ prompt, threadId }: GenerateIconsRequestType) => {
      const { data } = await threads.generateIcons({ prompt, threadId });
      return data;
    },
  });
}

import ConnectorButton from '@components/workspace/setup/sections/steps/connectors/connector-button';
import { StyledStack } from '@components/workspace/setup/sections/steps/connectors/tabs/panels/styles';

import ConfluenceIcon from '~icons/knowz-iconify/confluence-color-tone';
import GlobIcon from '~icons/knowz-iconify/glob-color-tone';
import MailIcon from '~icons/knowz-iconify/mail-color-tone';
import MsSharepointIcon from '~icons/knowz-iconify/ms-sharepoint-color-tone';
import WeBrandIcon from '~icons/knowz-iconify/we-brand-color-tone';

export default function Num2() {
  return (
    <StyledStack>
      <ConnectorButton
        ConnectorIcon={<MailIcon />}
        label="Mail"
        onClick={() => {
          alert('Mail');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<GlobIcon />}
        label="Glob"
        onClick={() => {
          alert('Glob');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<WeBrandIcon />}
        label="WeBrand"
        onClick={() => {
          alert('WeBrand');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<MsSharepointIcon />}
        label="MsSharepoint"
        onClick={() => {
          alert('MsSharepoint');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<ConfluenceIcon />}
        label="Confluence"
        onClick={() => {
          alert('Confluence');
        }}
      />
    </StyledStack>
  );
}

import Text from '@components/text';

export default function Num2() {
  return (
    <Text
      variant="textSm"
      weight="semibold"
    >
      Sync files
    </Text>
  );
}

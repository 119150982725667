import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tab, { tabClasses } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondaryAlt,
  border: '1px solid',
  borderColor: theme.palette.border.secondary,
  borderRadius: theme.shape.borderRadius * 2,
  height: theme.spacing(9),
  minHeight: theme.spacing(9),
  width: 'fit-content',
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  zIndex: 1,
  color: 'text.quaternary',
  minHeight: theme.spacing(9),
  height: theme.spacing(9),
  padding: theme.spacing(2, 3),
  [`&.${tabClasses.selected}`]: {
    color: 'text.secondary',
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  paddingBlockStart: theme.spacing(4),
}));

import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import PlusIcon from '~icons/knowz-iconify/plus';

import {
  StyledButtonBase,
  StyledStack,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/styles';
import Workspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces';

export default function OwnedWorkspaces() {
  const { setQueryParam } = useQueryParams();
  const { t } = useTranslation();
  function handleOpenWorkspaceSetup() {
    setQueryParam('action', 'setup-workspace');
  }

  return (
    <>
      <Workspaces />
      <StyledStack>
        <StyledButtonBase
          disableRipple
          disableTouchRipple
          onClick={handleOpenWorkspaceSetup}
        >
          <PlusIcon />
          &nbsp;
          {t('page.workspace.createButton.panel', {
            default: 'New Knowledge Base',
          })}
        </StyledButtonBase>
      </StyledStack>
    </>
  );
}

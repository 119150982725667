import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import Text from '@components/text';

export const StyledFormWrapper = styled(Box)(({ theme }) => ({
  paddingBlockStart: theme.spacing(6),
  paddingInline: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingBlockStart: theme.spacing(6),
    paddingInline: theme.spacing(6),
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  width: theme.spacing(75),
  color: theme.palette.text.secondary,
}));

import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

import Markdown from 'react-markdown';

export const StyledMarkdown = styled(Markdown)(({ theme }) => ({
  '*': {
    revert: 'all',
  },
  h2: {
    fontSize: theme.typography.textLg.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.8,
  },
  h3: {
    fontSize: theme.typography.textMd.fontSize,
    fontWeight: theme.typography.textMd.fontWeight,
    lineHeight: 1.8,
  },
  a: {
    color: theme.palette.mode === 'dark' && theme.palette.primary.light,
  },
  p: {
    fontSize: theme.typography.textMd.fontSize,
    lineHeight: 2,
  },
  strong: {
    fontSize: theme.typography.textMd.fontSize,
    fontWeight: theme.typography.textMd.fontWeight,
    lineHeight: 1.8,
  },
}));

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  fontWeight: theme.typography.textMd.fontWeight,
  fontSize: theme.typography.textMd.fontSize,
  color:
    theme.palette.mode === 'light'
      ? theme.palette.brand.blue.main
      : theme.palette.primary.light,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  ':disabled': {
    color: theme.palette.text.disabled,
  },
}));

import AppSettingsProvider from '@providers/AppSettings';
import AuthProvider from '@providers/AuthProvider';
import PortalProvider from '@providers/PortalProvider';
import ReactQueryProvider from '@providers/ReactQueryProvider';
import ReduxProvider from '@providers/ReduxProvider';
import ThemeProvider from '@providers/ThemeProvider';
import ThemeSettingsProvider from '@providers/ThemeSettingsProvider';
import TranslationProvider from '@providers/TranslationProvider';
import WebSocketProvider from '@providers/WebSocketProvider';
import WorkspaceProvider from '@providers/WorkspaceProvider';

import { ConfirmProvider } from 'material-ui-confirm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IoProvider } from 'socket.io-react-hook';

const SITE_KEY = String(import.meta.env.VITE_RECAPTCHA_KEY);

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <ReactQueryProvider>
      <ReduxProvider>
        <IoProvider>
          <WebSocketProvider>
            <ThemeSettingsProvider>
              <ThemeProvider>
                <TranslationProvider>
                  <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
                    <AuthProvider>
                      <AppSettingsProvider>
                        <WorkspaceProvider>
                          <ConfirmProvider
                            defaultOptions={{
                              confirmationButtonProps: {
                                variant: 'contained',
                              },
                              cancellationButtonProps: {
                                variant: 'outlined',
                              },
                            }}
                          >
                            <PortalProvider>{children}</PortalProvider>
                          </ConfirmProvider>
                        </WorkspaceProvider>
                      </AppSettingsProvider>
                    </AuthProvider>
                  </GoogleReCaptchaProvider>
                </TranslationProvider>
              </ThemeProvider>
            </ThemeSettingsProvider>
          </WebSocketProvider>
        </IoProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  );
}

import ButtonBase from '@mui/material/ButtonBase';

import StyledBox from '@components/workspace/setup/sections/steps/generate-icon/components/icon/button/styles';

import CheckCircleRounded from '~icons/knowz-iconify/check-circle-rounded';

type Props = {
  icon: string;
  selectedIcon: string;
  handleSetSelectedIcon: (icon: string) => void;
};

export default function Button({
  handleSetSelectedIcon,
  icon,
  selectedIcon,
}: Props) {
  return (
    <ButtonBase
      disableRipple
      disableTouchRipple
      onClick={() => handleSetSelectedIcon(icon)}
      sx={{
        img: {
          borderRadius: 1,
        },
      }}
    >
      <StyledBox>
        {selectedIcon === icon && <CheckCircleRounded fontSize="2em" />}
      </StyledBox>
      <img
        src={icon}
        alt="hey"
        loading="lazy"
        height={120}
        width="100%"
      />
    </ButtonBase>
  );
}

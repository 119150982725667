//? A temp component that is not used in the app is here for more testing purposes

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Input from '@components/input';

import useAppSelector from '@hooks/useAppSelector';

import { setSearchQuery } from '@state/slices/live-session';

import SendIcon from '~icons/knowz-iconify/send';

import { useRoomContext } from '@livekit/components-react';
import { useDispatch } from 'react-redux';

export default function Temp() {
  const dispatch = useDispatch();
  const searchQuery = useAppSelector((state) => state.liveSession.searchQuery);
  const room = useRoomContext();

  return (
    <Stack
      gap={2}
      direction="row"
    >
      <Input
        placeholder="type your query here"
        value={searchQuery}
        onChange={(e) => {
          dispatch(setSearchQuery(e.target.value));
        }}
      />
      <IconButton
        onClick={() => {
          const encodedData = new TextEncoder().encode(searchQuery);
          room.localParticipant.publishData(encodedData, {
            reliable: true,
            topic: 'search',
          });
          dispatch(setSearchQuery(''));
        }}
        color="containedPrimary"
      >
        <SendIcon />
      </IconButton>
    </Stack>
  );
}

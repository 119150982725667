import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { convertPxToRem } from '@lib/fonts';

import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import WorkspacesPopover from '@layouts/main/sections/toolbar/components/workspaces-popover';
import CONFIG from '@layouts/main/sections/toolbar/config';
import {
  StyledList,
  StyledMobileToolbar,
} from '@layouts/main/sections/toolbar/mini/mobile/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';

export default function MobileToolbar() {
  const { me } = useAuth();
  const { setupMode } = useWorkspace();

  return (
    <StyledMobileToolbar disableGutters>
      <Box sx={{ px: convertPxToRem(CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON) }}>
        <StyledList
          disablePadding
          direction="row"
          gap={2}
          component={Stack}
        >
          {(!CONFIG.IS_WORKSPACE_SELECTOR_ALPHA || me?.alpha) && (
            <WorkspacesPopover type="mobile" />
          )}
          {CONFIG.TOOLBAR_ITEMS.filter(({ isAlpha }) => me?.alpha || !isAlpha)
            .filter(({ setupMode: singleSetupMode }) =>
              singleSetupMode.includes(setupMode),
            )
            .map(({ icon, url }, i) => (
              <StyledListItem
                key={i}
                sx={{ marginBlockEnd: 0 }}
                disablePadding
                disableGutters
                component={StyledNavLink}
                to={url}
              >
                <IconButton color="tertiarySecondary">{icon}</IconButton>
              </StyledListItem>
            ))}
        </StyledList>
      </Box>
      <ToolbarExpansionButton />
    </StyledMobileToolbar>
  );
}

import StyledStack from '@components/settings/components/tabs/general/components/field-group/styles';
import Text from '@components/text';

type Props = {
  label: string;
  isItDesktop?: boolean;
};

const STATIC_WIDTH = 109;

export default function FieldGroup({
  label,
  children,
  isItDesktop = true,
}: React.PropsWithChildren<Props>) {
  return (
    <StyledStack
      direction={isItDesktop ? 'row' : 'column'}
      gap={isItDesktop ? 8 : 1}
      sx={{ alignItems: 'flex-start' }}
    >
      <Text
        component="label"
        sx={{
          width: STATIC_WIDTH,
          color: (theme) => theme.palette['Colors/Text/text-secondary (700)'],
        }}
        variant="textSm"
        weight="medium"
      >
        {label}
      </Text>
      {children}
    </StyledStack>
  );
}

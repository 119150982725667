import Text from '@components/text';
import {
  StyledBox,
  StyledStack,
} from '@components/workspace/setup/sections/steps/connectors/tabs/tab-item/num-3/styles';

import StarIcon from '~icons/knowz-iconify/star';

export default function Num3() {
  return (
    <StyledStack
      direction="row"
      gap={2}
    >
      <Text
        variant="textSm"
        weight="semibold"
      >
        Export Knowz
      </Text>
      <StyledBox>
        <StarIcon />
      </StyledBox>
    </StyledStack>
  );
}

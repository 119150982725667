import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import useAuth from '@hooks/useAuth';
import useGeolocation from '@hooks/useGeoLocation';
import useLocalStorage from '@hooks/useLocalStorage';

import { ActionMap } from '@shared-types/utils';

export type CustomStyleType = {
  id?: number;
  responseStyle: string;
  isUpToDate: boolean;
};

export type SearchScopeType = 'web_only' | 'web_kb' | 'kb_only';

export type AppSettingsContextType = {
  userGeolocation: {
    lat: number;
    lng: number;
  };
  userSpecificSettings: {
    [key: string]: {
      shouldSaveUploadsToKnowledgeBase: boolean;
      shouldPlayAudioAutomatically: boolean;
      searchScope: SearchScopeType;
      useCustomStyle: boolean;
      customStyle: CustomStyleType;
    } | null;
  } | null;
  handleSetUploadedToKnowledgeBase: (shouldSave: boolean) => void;
  handleSetPlayAudioAutomatically: (shouldPlay: boolean) => void;
  handleSetSearchScope: (scope: SearchScopeType) => void;
  handleSetUseCustomStyle: (useStyle: boolean) => void;
  handleSetCustomStyle: (style: CustomStyleType) => void;
};

enum Types {
  SET_USER_GEOLOCATION = 'SET_USER_GEOLOCATION',
  SET_UPLOADED_TO_KNOWLEDGE_BASE = 'SET_UPLOADED_TO_KNOWLEDGE_BASE',
  SET_PLAY_AUDIO_AUTOMATICALLY = 'SET_PLAY_AUDIO_AUTOMATICALLY',
  SET_SEARCH_SCOPE = 'SET_SEARCH_SCOPE',
  SET_USE_CUSTOM_STYLE = 'SET_USE_CUSTOM_STYLE',
  SET_CUSTOM_STYLE = 'SET_CUSTOM_STYLE',
}

type SearchSettingsState = {
  shouldSaveUploadsToKnowledgeBase: boolean;
  shouldPlayAudioAutomatically: boolean;
  searchScope: SearchScopeType;
  useCustomStyle: boolean;
  customStyle: CustomStyleType;
};

type AppSettingsState = {
  userGeolocation: {
    lat: number;
    lng: number;
  };
  userSpecificSettings: {
    [key: string]: SearchSettingsState;
  } | null;
};

const initialState: AppSettingsState = {
  userGeolocation: {
    lat: 0,
    lng: 0,
  },
  userSpecificSettings: null,
};

type AppSettingsPayload = {
  [Types.SET_USER_GEOLOCATION]: {
    lat: number;
    lng: number;
  };
  [Types.SET_UPLOADED_TO_KNOWLEDGE_BASE]: boolean;
  [Types.SET_PLAY_AUDIO_AUTOMATICALLY]: boolean;
  [Types.SET_SEARCH_SCOPE]: SearchScopeType;
  [Types.SET_USE_CUSTOM_STYLE]: boolean;
  [Types.SET_CUSTOM_STYLE]: CustomStyleType;
};

export type AppSettingsActions =
  ActionMap<AppSettingsPayload>[keyof ActionMap<AppSettingsPayload>];

export const AppSettingsContext = createContext<AppSettingsContextType | null>(
  null,
);

export default function AppSettingsProvider({
  children,
}: React.PropsWithChildren) {
  const { me } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { position } = useGeolocation({ lat: 0, lng: 0 });

  const { value: userSpecificSettings, setValue: setUserSpecificSettings } =
    useLocalStorage<{
      [key: string]: {
        shouldSaveUploadsToKnowledgeBase: boolean;
        shouldPlayAudioAutomatically: boolean;
        searchScope: SearchScopeType;
        useCustomStyle: boolean;
        customStyle: CustomStyleType;
      };
    }>('userSpecificSettings', initialState.userSpecificSettings);

  useEffect(() => {
    dispatch({ type: Types.SET_USER_GEOLOCATION, payload: position });

    if (!me?.uuid) return;

    //? If user specific settings already exist, do nothing
    if (userSpecificSettings && userSpecificSettings[me?.uuid]) return;

    setUserSpecificSettings({
      ...userSpecificSettings,
      [me?.uuid]: {
        shouldSaveUploadsToKnowledgeBase: false,
        shouldPlayAudioAutomatically: false,
        searchScope: 'web_kb',
        useCustomStyle: false,
        customStyle: {
          responseStyle: '',
          isUpToDate: false,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, position, userSpecificSettings]);

  const handleSetUploadedToKnowledgeBase = useCallback(
    (shouldSave: boolean) => {
      if (!me?.uuid) return;
      setUserSpecificSettings({
        ...userSpecificSettings,
        [me.uuid]: {
          ...userSpecificSettings[me.uuid],
          shouldSaveUploadsToKnowledgeBase: shouldSave,
        },
      });
      dispatch({
        type: Types.SET_UPLOADED_TO_KNOWLEDGE_BASE,
        payload: shouldSave,
      });
    },
    [me?.uuid, setUserSpecificSettings, userSpecificSettings],
  );

  const handleSetPlayAudioAutomatically = useCallback(
    (shouldPlay: boolean) => {
      if (!me?.uuid) return;
      setUserSpecificSettings({
        ...userSpecificSettings,
        [me.uuid]: {
          ...userSpecificSettings[me.uuid],
          shouldPlayAudioAutomatically: shouldPlay,
        },
      });

      dispatch({
        type: Types.SET_PLAY_AUDIO_AUTOMATICALLY,
        payload: shouldPlay,
      });
    },
    [me?.uuid, setUserSpecificSettings, userSpecificSettings],
  );

  const handleSetSearchScope = useCallback(
    (scope: SearchScopeType) => {
      if (!me?.uuid) return;
      setUserSpecificSettings({
        ...userSpecificSettings,
        [me.uuid]: {
          ...userSpecificSettings[me.uuid],
          searchScope: scope,
        },
      });
      dispatch({
        type: Types.SET_SEARCH_SCOPE,
        payload: scope,
      });
    },
    [me?.uuid, setUserSpecificSettings, userSpecificSettings],
  );

  const handleSetUseCustomStyle = useCallback(
    (useStyle: boolean) => {
      if (!me?.uuid) return;

      setUserSpecificSettings({
        ...userSpecificSettings,
        [me.uuid]: {
          ...userSpecificSettings[me.uuid],
          useCustomStyle: useStyle,
        },
      });
      dispatch({
        type: Types.SET_USE_CUSTOM_STYLE,
        payload: useStyle,
      });
    },
    [me?.uuid, setUserSpecificSettings, userSpecificSettings],
  );

  const handleSetCustomStyle = useCallback(
    (customStyle: CustomStyleType) => {
      if (!me?.uuid) return;
      setUserSpecificSettings({
        ...userSpecificSettings,
        [me.uuid]: {
          ...userSpecificSettings[me.uuid],
          customStyle: {
            ...userSpecificSettings[me.uuid].customStyle,
            ...customStyle,
          },
        },
      });
      dispatch({
        type: Types.SET_CUSTOM_STYLE,
        payload: customStyle,
      });
    },
    [me?.uuid, setUserSpecificSettings, userSpecificSettings],
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      userSpecificSettings,
      handleSetUploadedToKnowledgeBase,
      handleSetSearchScope,
      handleSetPlayAudioAutomatically,
      handleSetUseCustomStyle,
      handleSetCustomStyle,
    }),
    [
      userSpecificSettings,
      handleSetUploadedToKnowledgeBase,
      handleSetSearchScope,
      state,
      handleSetPlayAudioAutomatically,
      handleSetUseCustomStyle,
      handleSetCustomStyle,
    ],
  );

  return (
    <AppSettingsContext.Provider value={memoizedValue}>
      {children}
    </AppSettingsContext.Provider>
  );
}

function reducer(state: AppSettingsState, action: AppSettingsActions) {
  switch (action.type) {
    case Types.SET_USER_GEOLOCATION: {
      if (
        state.userGeolocation.lat === action.payload.lat &&
        state.userGeolocation.lng === action.payload.lng
      ) {
        return state;
      }

      return {
        ...state,
        userGeolocation: action.payload,
      };
    }
    case Types.SET_UPLOADED_TO_KNOWLEDGE_BASE: {
      return {
        ...state,
        shouldSaveUploadsToKnowledgeBase: action.payload,
      };
    }
    case Types.SET_SEARCH_SCOPE: {
      return {
        ...state,
        searchScope: action.payload,
      };
    }
    case Types.SET_PLAY_AUDIO_AUTOMATICALLY: {
      return {
        ...state,
        shouldPlayAudioAutomatically: action.payload,
      };
    }
    case Types.SET_USE_CUSTOM_STYLE: {
      return {
        ...state,
        useCustomStyle: action.payload,
      };
    }
    case Types.SET_CUSTOM_STYLE: {
      return {
        ...state,
        customStyle: action.payload,
      };
    }
  }
}

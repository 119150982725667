import { styled } from '@mui/material';

import Modal from '@components/modal';

const StyledModal = styled(Modal)(({ theme }) => ({
  overflow: 'auto',
  borderRadius: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(3),
  },
}));

export default StyledModal;

import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import General from '@components/settings/components/tabs/general';
import Search from '@components/settings/components/tabs/search';

export type Props = {
  handleTabChange: (
    event: React.SyntheticEvent,
    newValue: number | false,
  ) => void;
  tab: number | false;
};

export default function Mobile({ handleTabChange, tab }: Props) {
  const tabs = useMemo(
    () => [
      //TODO: Add more tabs here
      <General
        onClose={(event: React.SyntheticEvent) => handleTabChange(event, false)}
      />,
      <Search
        onClose={(event: React.SyntheticEvent) => handleTabChange(event, false)}
      />,
    ],
    [handleTabChange],
  );

  return (
    <Drawer
      anchor="right"
      open={tab !== false}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          height: '100%',
        },
      }}
      variant="temporary"
      ModalProps={{
        disablePortal: true,
        keepMounted: true,
      }}
    >
      <Box sx={{ height: '100%', width: '100%' }}>{tabs[tab]}</Box>
    </Drawer>
  );
}

import * as Yup from 'yup';

const generateIconSchema = Yup.object().shape({
  prompt: Yup.string()
    .trim()
    .max(255, "Description can't be more than 255 characters")
    .required("Description can't be empty"),
});

export default generateIconSchema;

import { useRef, useState, useSyncExternalStore } from 'react';

import friendlyNameManager from '@components/pages/live-session/utils/getFriendlyName';

import useAppDispatch from '@hooks/useAppDispatch';
import useTimer from '@hooks/useTimer';

import {
  addFeed,
  addTranscript,
  setFeedLabels,
  setSentiments,
} from '@state/slices/live-session';

import { FeedBlockType, SentimentLabelsType } from '@shared-types/live';

import { DataPacket_Kind, Participant, Room, RoomEvent } from 'livekit-client';

type SpeechToTextFinalDataType = {
  type: 'stt_sentence';
  text: string;
  index: number;
  start: number;
  end: number;
  speaker: string;
};

type SemanticSearchDataType = {
  type: 'semantic_search';
  index: number;
  result: SentimentLabelsType;
};

type SpeechToTextTempDataType = {
  type: 'stt_temp';
  text: string;
  index: number;
};

type FeedBlockDataType = FeedBlockType & {
  type: 'new_feed_block';
};

type RoomTopicType =
  | SpeechToTextFinalDataType['type']
  | SemanticSearchDataType['type']
  | SpeechToTextTempDataType['type']
  | FeedBlockDataType['type']
  | 'stt_consolidated_buffer'
  | 'search' // TODO: temp
  | 'stt_bot_buffer';

export default function useLiveKitTranscriber(room: Room) {
  const [interimTranscript, setInterimTranscript] = useState<string | null>(
    null,
  );
  const { current: textDecoderRef } = useRef(new TextDecoder());

  const { minutes, seconds } = useTimer();

  const dispatch = useAppDispatch();

  function subscriber(onStoreChange: VoidFunction) {
    function handleDataReceived(
      payload: Uint8Array,
      _participant?: Participant,
      _kind?: DataPacket_Kind,
      topic?: string,
    ) {
      const strData = textDecoderRef.decode(payload);
      const parsedData = JSON.parse(strData) as unknown;

      if (process.env.NODE_ENV === 'development') {
        console.log({ topic, parsedData });
      }

      switch (topic as RoomTopicType) {
        case 'stt_temp': {
          const typedParsedData = parsedData as SpeechToTextTempDataType;

          const text = typedParsedData.text;

          setInterimTranscript(text);
          onStoreChange();
          break;
        }
        case 'stt_sentence': {
          const {
            text,
            index,
            speaker: speakerReference,
          } = parsedData as SpeechToTextFinalDataType;

          dispatch(
            addTranscript({
              // NOTE: this is a temp workaround for naming Knowz AI, and disabling voice match speaker for it,

              // BUT, there is a caveat into this PR, if a user voice match speaker 1 for instance to Knowz AI that would lock for ever!

              // we can do a lot of things to avoid this situation like do not allow user to pick up a name like Knowz AI in voice match window!

              isItKnowzAI: /knowz ai/i.test(speakerReference),
              speaker: {
                friendlyName:
                  friendlyNameManager.setFriendlyName(speakerReference),
                reference: speakerReference,
              },
              content: text,
              id: index.toString(),
              time: {
                // TODO: move away from useTimer and use start & end fields instead
                minutes: minutes.toString().padStart(2, '0'),
                seconds: seconds.toString().padStart(2, '0'),
              },
            }),
          );

          if (interimTranscript) {
            const from = text.length;
            setInterimTranscript(
              interimTranscript.substring(from).replace(/^\.+/, '').trim(),
            );
          }

          onStoreChange();
          break;
        }
        case 'semantic_search': {
          const { index, result } = parsedData as SemanticSearchDataType;

          dispatch(
            setSentiments({
              historyBlockId: index.toString(),
              sentiments: result,
            }),
          );

          break;
        }
        case 'new_feed_block': {
          const { blockId, blockTagName, feedItems, transcriptIndex } =
            parsedData as FeedBlockType;
          if (feedItems.length === 0) return;

          dispatch(
            addFeed({ transcriptIndex, blockId, blockTagName, feedItems }),
          );

          dispatch(
            setFeedLabels({
              historyBlockId: transcriptIndex,
              feeds: feedItems.map(({ subTitle }) => ({
                id: transcriptIndex,
                blockTagName: subTitle,
              })),
            }),
          );
          break;
        }
        case 'stt_consolidated_buffer': {
          // console.log('stt_consolidated_buffer', parsedData);

          break;
        }
        case 'stt_bot_buffer': {
          // console.log('stt_bot_buffer', parsedData);
          break;
        }
        default: {
          console.error('Unknown topic', topic);
        }
      }
    }

    room.on(RoomEvent.DataReceived, handleDataReceived);

    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
    };
  }

  function getSnapshot() {
    return room.state === 'connected' ? interimTranscript : null;
  }

  const transcript = useSyncExternalStore(subscriber, getSnapshot);

  return {
    transcript,
  };
}

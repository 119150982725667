import { useTheme } from '@mui/material';

import Animation from '@components/workspace/setup/sections/steps/generate-icon/components/icon/generator/animation';
import {
  StyledGradientBox,
  StyledStack,
} from '@components/workspace/setup/sections/steps/generate-icon/components/icon/placeholder/styles';

import SmartStarIcon from '~icons/knowz-iconify/smart-star';

const ICON_PLACEHOLDER_COUNT = 4;

export default function Placeholder({
  status,
  children,
}: {
  children: React.ReactNode;
  status: 'pending' | 'finished' | 'idle';
}) {
  const theme = useTheme();

  if (status === 'idle') {
    return Array.from({ length: ICON_PLACEHOLDER_COUNT }, (_, index) => (
      <StyledGradientBox key={index}>
        <StyledStack>
          <SmartStarIcon color={theme.palette.forground.quaternary} />
        </StyledStack>
      </StyledGradientBox>
    ));
  }

  if (status === 'pending') {
    return Array.from({ length: ICON_PLACEHOLDER_COUNT }, (_, index) => (
      <Animation
        key={index}
        loadingStatus={status}
      />
    ));
  }

  return children;
}

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import SEARCH_MODE_CONFIG from '@components/mode-dropdown/config';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useLocalStorage from '@hooks/useLocalStorage';

import { modeUpdated } from '@state/slices/search-result';

import { SearchModeType } from '@shared-types/search-result/types';

export default function ModeSelector() {
  const dispatch = useAppDispatch();

  // TODO: consider moving to AppSettingsState
  const { value: defaultSearchMode, setValue: setDefaultSearchMode } =
    useLocalStorage<SearchModeType>('defaultSearchMode', 'fluid');

  function handleSearchModeChange(e: SelectChangeEvent) {
    const mode = e.target.value as SearchModeType;
    setDefaultSearchMode(mode);
    dispatch(modeUpdated(mode));
  }

  return (
    <Select
      value={defaultSearchMode}
      onChange={handleSearchModeChange}
      variant="outlined"
    >
      {Object.entries(SEARCH_MODE_CONFIG.SEARCH_MODES)
        .reverse()
        .map(([id, mode]) => (
          <MenuItem
            key={`default-search-mode-${id}`}
            value={id}
          >
            <Text variant="textSm">{mode.title}</Text>
          </MenuItem>
        ))}
    </Select>
  );
}

import { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { ProviderType } from '@components/pages/sso/oauth-fields';

export interface ProviderProps {
  value: string | null;
  onChange: (value: ProviderType | null) => void;
  data: ProviderType[];
}

export const titleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  );

export default function Provider({ value, data, onChange }: ProviderProps) {
  const [open, setOpen] = useState(false);
  const opts = Object.keys(data).map((id) => ({ id, name: titleCase(id) }));

  return (
    <Autocomplete
      open={open}
      size="small"
      value={opts.find((option) => option.id === value) || null}
      onChange={(_event, value) =>
        onChange(value ? { id: value.id, ...data[value.id] } : null)
      }
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option === value}
      options={opts}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select provider"
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment,
          }}
        />
      )}
    />
  );
}

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  borderRadius: '50%',
  backgroundColor:
    theme.palette[
      'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg'
    ],
  svg: {
    fontSize: '0.74em',
  },
}));

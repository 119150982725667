import { styled } from '@mui/material';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
}));

import { styled } from '@mui/material';
import Card from '@mui/material/Card';

const StyledImageCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.main,
  padding: theme.spacing(2),
  height: 150,
}));

export default StyledImageCard;

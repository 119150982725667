import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import CONFIG from '@layouts/main/sections/toolbar/config';

const STATIC_WIDTH_FOR_ALIGNMENT = 250;
const ALL_PADDINGS_AROUND_ENTERPRISE_SOLUTION = 24;

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

export const StyledStaticStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth:
    STATIC_WIDTH_FOR_ALIGNMENT -
    (CONFIG.TOOLBAR_MINI.DESKTOP.WIDTH -
      ALL_PADDINGS_AROUND_ENTERPRISE_SOLUTION),
}));

export const StyledStaticBox = styled(Box)(() => ({
  minWidth: STATIC_WIDTH_FOR_ALIGNMENT,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  margin: '0 auto 0 0',
  [theme.breakpoints.up('md')]: {
    margin: '0 auto',
  },
}));

import Alert from '@mui/material/Alert';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';

import { StyledStack } from '@components/dropdown/styles';
import Text from '@components/text';

import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import PlusIcon from '~icons/knowz-iconify/plus';

import LoadingDropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/components/LoadingDropdown';
import { StyledChip } from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/styles';
import Dropdown from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/dropdown';
import useGetOwnedWorkspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces/workspaces/hooks/useGetOwnedWorkspaces';
import StyledAvatar from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/styles';

export default function Workspaces() {
  const { ownedWorkspacesData, isWorkspaceFetching, hasWorkspaceError } =
    useGetOwnedWorkspaces();
  const { t } = useTranslation();
  const { setQueryParam } = useQueryParams();

  function handleOpenWorkspaceModalSetup() {
    setQueryParam('action', 'setup-workspace');
  }

  if (isWorkspaceFetching) {
    return <LoadingDropdown />;
  }

  if (hasWorkspaceError) {
    return (
      <Alert severity="error">
        {t('response.errors.code.520', {
          default: 'Unknown error. Please try again later.',
        })}
      </Alert>
    );
  }

  return (
    <Dropdown options={ownedWorkspacesData}>
      <MenuItem
        disableRipple
        disableTouchRipple
        onClick={handleOpenWorkspaceModalSetup}
      >
        <StyledStack direction="row">
          <ListItemIcon>
            <StyledAvatar>
              <PlusIcon />
            </StyledAvatar>
          </ListItemIcon>
          <StyledStack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Text variant="textSm">
              {t('page.workspace.createButton.dropdown', {
                default: 'Create New',
              })}
            </Text>
            <StyledChip
              size="small"
              label="0"
            />
          </StyledStack>
        </StyledStack>
      </MenuItem>
    </Dropdown>
  );
}

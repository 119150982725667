import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useTranslation } from '@desygner/ui-common-translation';

import { toast } from 'react-toastify';

type Props = {
  handleLogout?: VoidFunction;
  withIcon?: boolean;
};

export default function LogoutButton({ handleLogout, withIcon }: Props) {
  const { t } = useTranslation();

  async function logoutOnClick() {
    window.localStorage.removeItem('token');
    toast.success(
      t('response.success.logout', {
        defaultValue: 'You have successfully logged out',
      }),
    );

    handleLogout && handleLogout();
  }

  if (withIcon) {
    return (
      <ListItemButton
        onClick={logoutOnClick}
        sx={{ justifyContent: 'start', gap: 3 }}
      >
        <LogoutIcon
          color="error"
          fontSize="small"
        />
        <ListItemText
          primary={t('layout.auth.logout', {
            defaultValue: 'Logout',
          })}
          primaryTypographyProps={{
            variant: 'textMd',
          }}
        />
      </ListItemButton>
    );
  }

  return (
    <Button
      onClick={logoutOnClick}
      size="small"
      variant="outlined"
      sx={{
        borderColor: (theme) =>
          theme.palette[
            'Component colors/Components/Buttons/Secondary/button-secondary-fg'
          ],
        color: (theme) =>
          theme.palette[
            'Component colors/Components/Buttons/Secondary/button-secondary-fg'
          ],
      }}
    >
      {t('layout.auth.logout', {
        defaultValue: 'Logout',
      })}
    </Button>
  );
}

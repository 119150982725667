import ConnectorButton from '@components/workspace/setup/sections/steps/connectors/connector-button';
import { StyledStack } from '@components/workspace/setup/sections/steps/connectors/tabs/panels/styles';

import BoxIcon from '~icons/knowz-iconify/box-color-tone';
import DropboxIcon from '~icons/knowz-iconify/dropbox-color-tone';
import OneDriveIcon from '~icons/knowz-iconify/onedrive-color-tone';

export default function Num1() {
  return (
    <StyledStack>
      <ConnectorButton
        ConnectorIcon={<DropboxIcon />}
        label="Dropbox"
        onClick={() => {
          alert('Dropbox');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<OneDriveIcon />}
        label="OneDrive"
        onClick={() => {
          alert('OneDrive');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<BoxIcon />}
        label="Box"
        onClick={() => {
          alert('Box');
        }}
      />
    </StyledStack>
  );
}

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {
  StyledBox,
  StyledDot,
} from '@components/workspace/setup/sections/steps/generate-icon/components/icon/generator/animation/styles';
import {
  StyledGradientBox,
  StyledStack,
} from '@components/workspace/setup/sections/steps/generate-icon/components/icon/placeholder/styles';

import SmartStarIcon from '~icons/knowz-iconify/smart-star';

import { motion } from 'framer-motion';

type Props = {
  loadingStatus: 'pending';
};

const NUMBER_OF_DOTS = 3;

export default function Animation({ loadingStatus }: Props) {
  const theme = useTheme();

  const VARIANTS = {
    hidden: {
      x: '-100%',
    },
    pending: {
      x: [
        '-100%',
        '-90%',
        '-85%',
        '-80%',
        '-70%',
        '-60%',
        '-55%',
        '-40%',
        '-35%',
        '-25%',
        '-15%',
      ],
      transition: {
        duration: 10,
        delay: 1,
      },
    },
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledGradientBox
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <StyledBox
          component={motion.div}
          variants={VARIANTS}
          initial="hidden"
          animate={loadingStatus}
        />
        <StyledStack gap={1}>
          <SmartStarIcon color={theme.palette.forground.quaternary} />
          <Stack
            direction="row"
            gap={1}
          >
            {Array.from({ length: NUMBER_OF_DOTS }).map((_, index) => (
              <StyledDot key={index} />
            ))}
          </Stack>
        </StyledStack>
      </StyledGradientBox>
    </Box>
  );
}

import {
  menuItemClasses,
  MenuItemOwnProps,
  MenuItemProps,
  Theme,
} from '@mui/material';

type Props = MenuItemProps & {
  ownerState: MenuItemOwnProps;
};

export default function MenuItem(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: (props: Props) => {
          const ownerState = props.ownerState as {
            color: keyof Pick<
              Theme['palette'],
              'primary' | 'error' | 'success' | 'warning' | 'info'
            >;
          };
          const color = ownerState.color;
          return {
            color: color
              ? theme.palette[ownerState.color].main
              : theme.palette.text.primary,
            padding: theme.spacing(1.5, 2),
            '&:hover': {
              borderRadius: theme.shape.borderRadius * 1.25,
            },
            [`&.${menuItemClasses.selected}`]: {
              backgroundColor: theme.palette.utility.brand100Alt,
            },
          };
        },
      },
    },
  };
}

import { useTheme } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

import ChevSelectorVertical from '~icons/knowz-iconify/chev-selector-vertical';
import WindowGradIcon from '~icons/knowz-iconify/window-grad';

import usePopover from '@layouts/main/sections/toolbar/components/workspaces-popover/hooks/usePopover';
import StyledButton from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/trigger-button/styles';
import useGetWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspacesById';

type Props = {
  buttonType: 'iconButton' | 'button';
};

const SIZE = 43;

export default function TriggerButton({ buttonType }: Props) {
  const { handleOpen } = usePopover();
  const { refetchWorkspaces } = useGetWorkspaces();
  const theme = useTheme();
  function handleOpenPopover(event: React.MouseEvent<HTMLButtonElement>) {
    handleOpen(event);
    refetchWorkspaces();
  }

  if (buttonType === 'button') {
    return (
      <StyledButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        disableElevation
        fullWidth
        size="medium"
        variant="text"
        color="inherit"
        onClick={handleOpenPopover}
        aria-label="trigger knowledge base popover"
        startIcon={
          <WindowGradIcon
            width={SIZE}
            height={SIZE}
          />
        }
        sx={{
          alignItems: 'center', //TODO: remove after adding the below TODO
        }}
        endIcon={<ChevSelectorVertical color={theme.palette.text.disabled} />}
      >
        <Stack
          sx={{
            textAlign: 'left',
          }}
        >
          <Text
            variant="textMd"
            weight="semibold"
          >
            Knowledge Base
          </Text>
          {/* <EllipsisText
            variant="textSm"
            weight="semibold"
            color="text.disabled"
            lineClamp={1}
            title={'You nameYou nameYou name'}
          >
            TODO: Replace with actual name
            You nameYou nameYou name
          </EllipsisText> */}
        </Stack>
      </StyledButton>
    );
  }

  return (
    <MuiIconButton
      onClick={handleOpenPopover}
      aria-label="trigger knowledge base popover"
    >
      <WindowGradIcon />
    </MuiIconButton>
  );
}

import { OutlinedInputProps, Theme } from '@mui/material';

type InputOwnerStateType = {
  ownerState: OutlinedInputProps;
};

export default function Input(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState: { multiline } }: InputOwnerStateType) => ({
          borderRadius: theme.shape.borderRadius * 2,
          backgroundColor: theme.palette.background.secondary,
          height: multiline ? 'auto' : theme.spacing(10),
          gap: theme.spacing(1),
          svg: {
            fontSize: theme.typography.pxToRem(19),
            flexShrink: 0,
            color: theme.palette.text.disabled,
          },
        }),
        input: {
          fontSize: theme.typography.textSm.fontSize,
          fontWeight: theme.typography.textSm.fontWeight,
          padding: theme.spacing(2.375),
        },
        inputAdornedStart: {
          paddingLeft: theme.spacing(2),
        },
        inputAdornedEnd: {
          paddingInlineEnd: theme.spacing(2),
        },
      },
    },
  };
}

import { useRef, useState } from 'react';

import CONFIG from '@components/pages/knowledge-base/components/uppy-upload/config';
import {
  StyledDashboard,
  StyledDragDrop,
} from '@components/pages/knowledge-base/components/uppy-upload/styles';
import convertKBToMB from '@components/pages/knowledge-base/components/uppy-upload/utils/convertKBToMB';
import getFileNameWithoutExtension from '@components/pages/knowledge-base/components/uppy-upload/utils/getFileNameWithoutExtension';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';

import { storage } from '@lib/agent';
import getCreationOwnerHeader from '@lib/getSetupModeSession';
import { getDecodedToken } from '@lib/jwt';

import { queryClient } from '@providers/ReactQueryProvider';

import useAuth from '@hooks/useAuth';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { SourceWithMetaDataType } from '@shared-types/sources';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.css';

import AwsS3 from '@uppy/aws-s3';
import { Uppy } from '@uppy/core';
import RemoteSources from '@uppy/remote-sources';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

type ContextType =
  | {
      entityType: string;
      entityId?: number;
      usage: string;
    }
  | undefined;

type createUppyInstanceType = {
  folderId: number | null; //? null means we are on the root folder
};

const DRAG_DROP_HEIGHT = 300;
const DASHBOARD_HEIGHT = 270;

export default function UppyUpload() {
  const { t } = useTranslation();

  const dragDropRef = useRef<{ container?: HTMLDivElement } | null>(null);

  const dashboardRef = useRef<{ container?: HTMLDivElement } | null>(null);

  const { getQueryParamByKey } = useQueryParams();

  const { folderData } = useGetFolderDataByPath({
    path: getQueryParamByKey('path'),
  });

  const { getNamespacedQueryKey, setupMode } = useWorkspace();

  const { me } = useAuth();

  const currentPage = parseInt(getQueryParamByKey('page', '1'));

  const folderId = folderData == null ? null : folderData.id;

  const queryKey = getNamespacedQueryKey('sources', {
    page: currentPage,
    ...(folderId && { folderId }),
  });

  const { handleCloseAddKnowledgeBaseModal } = useKnowledgeBaseData();

  const [uppy] = useState<Uppy | null>(
    createUppyInstance({
      folderId,
    }).on('complete', (result) => {
      toast.success(
        t('response.success.uploadedSource', {
          defaultValue: 'You have successfully uploaded a source.',
        }),
      );

      result.successful?.forEach((item) => {
        queryClient.cancelQueries({
          queryKey,
        });

        const lastSources =
          queryClient.getQueryData<SourceWithMetaDataType | null>(queryKey);
        if (lastSources == null) return;

        const updatedData: SourceWithMetaDataType = {
          ...lastSources,
          data: [
            {
              fileType: item.extension,
              isDigested: false,
              isDigestedFail: false,
              folderId: folderId!,
              totalMemberships: 0,
              createdAt: new Date().toISOString(),
              id: lastSources.data.length + 1,
              s3Bucket: '',
              isCollected: false,
              path: '',
              sourceConfiguration: null,
              examplesMemberships: [],
              name: getFileNameWithoutExtension(item.name as string),
              owner: {
                ownerType: setupMode === 'on' ? 'workspace' : 'membership',
                id: uuidv4(),
                name: '',
                profilePicture: '',
              },
              inCache: true,
            },
            ...lastSources.data,
          ],
        };

        queryClient.setQueryData(queryKey, () => updatedData);
      });
      handleCloseAddKnowledgeBaseModal();
    }),
  );

  return (
    <>
      <StyledDragDrop
        ref={dragDropRef as never}
        note={`${t('page.knowledgeBase.upload.note', {
          defaultValue: 'audio, text and docs, up to',
        })} ${convertKBToMB(
          me?.paid ? CONFIG.MAX_FILE_SIZE.PAID : CONFIG.MAX_FILE_SIZE.NOT_PAID,
        )} MB`}
        uppy={uppy as never}
        allowMultipleFiles
        locale={
          {
            strings: {
              browse: t('page.knowledgeBase.upload.browseFiles', {
                defaultValue: 'Click to upload',
              }),
              dropHereOr: 'Drop files here or %{browse}',
            },
          } as never
        }
        width="100%"
        height={DRAG_DROP_HEIGHT}
      />
      <StyledDashboard
        disableLocalFiles={true}
        ref={dashboardRef as never}
        uppy={uppy as never}
        proudlyDisplayPoweredByUppy={false}
        width="100%"
        height={DASHBOARD_HEIGHT}
      />
    </>
  );
}

function createUppyInstance({ folderId }: createUppyInstanceType) {
  const TOKEN = window.localStorage.getItem('token');
  const isAPaidUser = getDecodedToken(TOKEN)?.paid;

  const MAX_FILE_SIZE = isAPaidUser
    ? CONFIG.MAX_FILE_SIZE.PAID
    : CONFIG.MAX_FILE_SIZE.NOT_PAID;

  return new Uppy({
    restrictions: {
      allowedFileTypes: [...CONFIG.FILE_TYPES],
      maxFileSize: MAX_FILE_SIZE,
      maxNumberOfFiles: CONFIG.MAX_NUMBER_OF_FILES,
    },
  })
    .use(RemoteSources, {
      companionUrl: import.meta.env.VITE_COMPANION_URL,
      sources: CONFIG.REMOTE_SOURCES,
      companionHeaders: {
        Authorization: `Bearer ${TOKEN}` || '',
        'entity-id': folderId === null ? 'root' : folderId.toString(),
        'Knowz-Creation-Owner': getCreationOwnerHeader(),
      },
    })
    .use(AwsS3, {
      //TODO: we need to handle shouldUseMultipart for large files
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      getUploadParameters: async (file) => {
        const name = file.data.name;
        const size = file.data.size;
        const sizeInKB = size / 1024;
        const type = file.type;

        const { data } = await storage.createPolicy<ContextType>({
          name: name,
          size: sizeInKB,
          mimeType: type,
          context:
            folderId === null
              ? undefined
              : {
                  entityType: 'folder',
                  entityId: folderId,
                  usage: 'source',
                },
        });
        return {
          method: 'PUT',
          fields: [],
          headers: {
            'Content-Type': type,
          },
          url: data.url,
        };
      }, //TODO
    });
}

import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';
import Text from '@components/text';

type Props = {
  title: string;
  subTitle?: string;
};

export default function Heading({ title, subTitle }: Props) {
  const { handleSetIntegrateAssistantsDrawer } = useAssistantsDrawer();

  return (
    <>
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          <Text
            variant="textSm"
            weight="semibold"
          >
            {title}
          </Text>
          {subTitle && <Text variant="textMd">{subTitle}</Text>}
        </Stack>
        <IconButton
          color="tertiarySecondary"
          onClick={() => handleSetIntegrateAssistantsDrawer(null)}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
    </>
  );
}

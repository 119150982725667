import { alpha, Theme } from '@mui/material';

import { DARK_COLORS, LIGHT_COLORS } from '@theme/figma';

const NEUTRAL = {
  paper: '#FFFFFF',
  lightGrey: '#F3F5F7',
  border: '#e8e8e8',
  grey: '#D1D3D6',
  darkGrey: '#6C757D',
  black: '#212529',
};

const PRIMARY = {
  lighter: '#E8F2FC',
  light: '#5BA1ED',
  main: '#3249FF',
  dark: '#2C3E65',
  darker: '#232E4C',
  contrastText: NEUTRAL.paper,
};

const INFO = {
  light: '#EBF3FF',
  main: '#0068FF',
  dark: '#2341e0',
  contrastText: NEUTRAL.paper,
};

const WARNING = {
  light: '#FFF1D6',
  main: '#FFC738',
  dark: '#f98621',
  contrastText: NEUTRAL.black,
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
  950: '#4E1D09',
};

const SUCCESS = {
  light: '#C6ECE2',
  main: '#00AB7D',
  dark: '#005228',
  contrastText: NEUTRAL.paper,
};

const ERROR = {
  lighter: '#FFD6D6',
  light: '#FFD6D6',
  main: '#fe3217',
  dark: '#F02911',
  darker: '#CA0000',
  contrastText: NEUTRAL.paper,
};

const BRAND = {
  darkBlue: {
    main: '#2C3E65',
    light: '#F2F4F8',
  },
  blue: {
    main: '#3C1AF9',
    light: '#E8F2FC',
  },
  purple: {
    main: '#773DFF',
    light: '#EDE5FF',
  },
  green: {
    main: '#00C394',
    light: '#EBFFFA',
  },
  lightBlue: {
    main: '#5BA1ED',
    light: '#E8F2FC',
  },
};

const LIGHT_GREY = {
  0: '#ededed',
  25: '#FDFDFD',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E9EAEB',
  300: '#D5D7DA',
  400: '#A4A7AE',
  500: '#717680',
  600: '#535862',
  700: '#414651',
  800: '#252B37',
  900: '#181D27',
  950: '#0A0D12',

  500_8: alpha('#656f81', 0.08),
  500_12: alpha('#656f81', 0.12),
  500_16: alpha('#656f81', 0.16),
  500_24: alpha('#656f81', 0.24),
  500_32: alpha('#656f81', 0.32),
  500_48: alpha('#656f81', 0.48),
  500_56: alpha('#656f81', 0.56),
  500_80: alpha('#656f81', 0.8),
};

const DARK_GREY = {
  0: '#ededed',
  25: '#FAFAFA',
  50: '#F7F7F7',
  100: '#F0F0F1',
  200: '#ECECED',
  300: '#CECFD2',
  400: '#94979C',
  500: '#85888E',
  600: '#61656C',
  700: '#373A41',
  800: '#22262F',
  900: '#13161B',
  950: '#0C0E12',
};

//? for backward compatibility we need to keep the old gradients once we migrated fully we remove it
export function createGradient(color1: string, color2: string, deg: string) {
  return `linear-gradient(${deg}, ${color1}, ${color2})`;
}

const GRADIENTS = (deg: 'to left' | 'to bottom' | 'to top' | 'to right') => ({
  primary: createGradient(PRIMARY.light, PRIMARY.main, deg),
  neutral: createGradient(NEUTRAL.darkGrey, NEUTRAL.lightGrey, deg),
  info: createGradient(INFO.light, INFO.main, deg),
  success: createGradient(SUCCESS.light, SUCCESS.main, deg),
  warning: createGradient(WARNING.light, WARNING.main, deg),
  error: createGradient(ERROR.light, ERROR.main, deg),
  black: createGradient('#000', LIGHT_GREY[600], deg),
});

//? new one
const createBrandGradients = {
  secondary: ({
    alpha,
  }: {
    alpha: Theme['palette']['createBrandGradients']['secondary'];
  }) => {
    return `
      linear-gradient(0deg, ${alpha}, ${alpha}),
      linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
      linear-gradient(90deg, rgba(83, 177, 237, 0.45) 3.75%, rgba(81, 163, 238, 0.45) 39.47%, rgba(64, 55, 247, 0.45) 74.2%, rgba(60, 26, 249, 0.45) 96.25%)
    `;
  },
};

const COMMON = {
  common: { black: '#000', white: NEUTRAL.paper },
  primary: PRIMARY,
  info: INFO,
  neutral: NEUTRAL,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  gradients: GRADIENTS,
  createBrandGradients,
  brand: BRAND,
};

const LIGHT_BORDER_COLORS = {
  primary: LIGHT_COLORS['Colors/Border/border-primary'],
  secondary: LIGHT_COLORS['Colors/Border/border-secondary'],
  tertiary: LIGHT_COLORS['Colors/Border/border-tertiary'],
  disabled: LIGHT_COLORS['Colors/Border/border-disabled'],
  disabledSubtle: LIGHT_COLORS['Colors/Border/border-disabled_subtle'],
  brand: LIGHT_COLORS['Colors/Border/border-brand'],
  brandAlt: LIGHT_COLORS['Colors/Border/border-brand_alt'],
  error: LIGHT_COLORS['Colors/Border/border-error'],
  errorSubtle: LIGHT_COLORS['Colors/Border/border-error_subtle'],
};

const DARK_BORDER_COLORS = {
  primary: DARK_COLORS['Colors/Border/border-primary'],
  secondary: DARK_COLORS['Colors/Border/border-secondary'],
  tertiary: DARK_COLORS['Colors/Border/border-tertiary'],
  disabled: DARK_COLORS['Colors/Border/border-disabled'],
  disabledSubtle: DARK_COLORS['Colors/Border/border-disabled_subtle'],
  brand: DARK_COLORS['Colors/Border/border-brand'],
  brandAlt: DARK_COLORS['Colors/Border/border-brand_alt'],
  error: DARK_COLORS['Colors/Border/border-error'],
  errorSubtle: DARK_COLORS['Colors/Border/border-error_subtle'],
};

const LIGHT_FORGROUND_COLORS = {
  primary: LIGHT_COLORS['Colors/Foreground/fg-primary (900)'],
  secondary: LIGHT_COLORS['Colors/Foreground/fg-secondary (700)'],
  secondaryHover: LIGHT_COLORS['Colors/Foreground/fg-secondary_hover'],
  tertiary: LIGHT_COLORS['Colors/Foreground/fg-tertiary (600)'],
  tertiaryHover: LIGHT_COLORS['Colors/Foreground/fg-tertiary_hover'],
  quaternary: LIGHT_COLORS['Colors/Foreground/fg-quaternary (500)'],
  quaternaryHover: LIGHT_COLORS['Colors/Foreground/fg-quaternary_hover'],
  quinary: LIGHT_COLORS['Colors/Foreground/fg-quinary (400)'],
  quinaryHover: LIGHT_COLORS['Colors/Foreground/fg-quinary_hover'],
  senary: LIGHT_COLORS['Colors/Foreground/fg-senary (300)'],
  white: LIGHT_COLORS['Colors/Foreground/fg-white'],
  disabled: LIGHT_COLORS['Colors/Foreground/fg-disabled'],
  disabledSubtle: LIGHT_COLORS['Colors/Foreground/fg-disabled_subtle'],
  brandPrimary: LIGHT_COLORS['Colors/Foreground/fg-brand-primary (600)'],
  brandPrimaryAlt: LIGHT_COLORS['Colors/Foreground/fg-brand-primary_alt'],
  brandSecondary: LIGHT_COLORS['Colors/Foreground/fg-brand-secondary (500)'],
  errorPrimary: LIGHT_COLORS['Colors/Foreground/fg-error-primary'],
  errorSecondary: LIGHT_COLORS['Colors/Foreground/fg-error-secondary'],
  warningPrimary: LIGHT_COLORS['Colors/Foreground/fg-warning-primary'],
  warningSecondary: LIGHT_COLORS['Colors/Foreground/fg-warning-secondary'],
  successPrimary: LIGHT_COLORS['Colors/Foreground/fg-success-primary'],
  successSecondary: LIGHT_COLORS['Colors/Foreground/fg-success-secondary'],
};
const DARK_FORGROUND_COLORS = {
  primary: DARK_COLORS['Colors/Foreground/fg-primary (900)'],
  secondary: DARK_COLORS['Colors/Foreground/fg-secondary (700)'],
  secondaryHover: DARK_COLORS['Colors/Foreground/fg-secondary_hover'],
  tertiary: DARK_COLORS['Colors/Foreground/fg-tertiary (600)'],
  tertiaryHover: DARK_COLORS['Colors/Foreground/fg-tertiary_hover'],
  quaternary: DARK_COLORS['Colors/Foreground/fg-quaternary (500)'],
  quaternaryHover: DARK_COLORS['Colors/Foreground/fg-quaternary_hover'],
  quinary: DARK_COLORS['Colors/Foreground/fg-quinary (400)'],
  quinaryHover: DARK_COLORS['Colors/Foreground/fg-quinary_hover'],
  senary: DARK_COLORS['Colors/Foreground/fg-senary (300)'],
  white: DARK_COLORS['Colors/Foreground/fg-white'],
  disabled: DARK_COLORS['Colors/Foreground/fg-disabled'],
  disabledSubtle: DARK_COLORS['Colors/Foreground/fg-disabled_subtle'],
  brandPrimary: DARK_COLORS['Colors/Foreground/fg-brand-primary (600)'],
  brandPrimaryAlt: DARK_COLORS['Colors/Foreground/fg-brand-primary_alt'],
  brandSecondary: DARK_COLORS['Colors/Foreground/fg-brand-secondary (500)'],
  errorPrimary: DARK_COLORS['Colors/Foreground/fg-error-primary'],
  errorSecondary: DARK_COLORS['Colors/Foreground/fg-error-secondary'],
  warningPrimary: DARK_COLORS['Colors/Foreground/fg-warning-primary'],
  warningSecondary: DARK_COLORS['Colors/Foreground/fg-warning-secondary'],
  successPrimary: DARK_COLORS['Colors/Foreground/fg-success-primary'],
  successSecondary: DARK_COLORS['Colors/Foreground/fg-success-secondary'],
};

const DARK_UTILITY_COLORS = {
  blue50: DARK_COLORS['Component colors/Utility/Blue/utility-blue-50'],
  blue100: DARK_COLORS['Component colors/Utility/Blue/utility-blue-100'],
  blue200: DARK_COLORS['Component colors/Utility/Blue/utility-blue-200'],
  blue300: DARK_COLORS['Component colors/Utility/Blue/utility-blue-300'],
  blue400: DARK_COLORS['Component colors/Utility/Blue/utility-blue-400'],
  blue500: DARK_COLORS['Component colors/Utility/Blue/utility-blue-500'],
  blue600: DARK_COLORS['Component colors/Utility/Blue/utility-blue-600'],
  blue700: DARK_COLORS['Component colors/Utility/Blue/utility-blue-700'],
  blueDark50:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-50'],
  blueDark100:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-100'],
  blueDark200:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-200'],
  blueDark300:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-300'],
  blueDark400:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-400'],
  blueDark500:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-500'],
  blueDark600:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-600'],
  blueDark700:
    DARK_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-700'],
  blueLight50:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-50'],
  blueLight100:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-100'],
  blueLight200:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-200'],
  blueLight300:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-300'],
  blueLight400:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-400'],
  blueLight500:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-500'],
  blueLight600:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-600'],
  blueLight700:
    DARK_COLORS['Component colors/Utility/Blue light/utility-blue-light-700'],
  brand50: DARK_COLORS['Component colors/Utility/Brand/utility-brand-50'],
  brand50Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-50_alt'],
  brand100: DARK_COLORS['Component colors/Utility/Brand/utility-brand-100'],
  brand100Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-100_alt'],
  brand200: DARK_COLORS['Component colors/Utility/Brand/utility-brand-200'],
  brand200Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-200_alt'],
  brand300: DARK_COLORS['Component colors/Utility/Brand/utility-brand-300'],
  brand400: DARK_COLORS['Component colors/Utility/Brand/utility-brand-400'],
  brand400Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-400_alt'],
  brand500: DARK_COLORS['Component colors/Utility/Brand/utility-brand-500'],
  brand500Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-500_alt'],
  brand600: DARK_COLORS['Component colors/Utility/Brand/utility-brand-600'],
  brand600Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-600_alt'],
  brand700: DARK_COLORS['Component colors/Utility/Brand/utility-brand-700'],
  brand700Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-700_alt'],
  brand800: DARK_COLORS['Component colors/Utility/Brand/utility-brand-800'],
  brand800Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-800_alt'],
  brand900: DARK_COLORS['Component colors/Utility/Brand/utility-brand-900'],
  brand900Alt:
    DARK_COLORS['Component colors/Utility/Brand/utility-brand-900_alt'],
  error50: DARK_COLORS['Component colors/Utility/Error/utility-error-50'],
  error100: DARK_COLORS['Component colors/Utility/Error/utility-error-100'],
  error200: DARK_COLORS['Component colors/Utility/Error/utility-error-200'],
  error300: DARK_COLORS['Component colors/Utility/Error/utility-error-300'],
  error400: DARK_COLORS['Component colors/Utility/Error/utility-error-400'],
  error500: DARK_COLORS['Component colors/Utility/Error/utility-error-500'],
  error600: DARK_COLORS['Component colors/Utility/Error/utility-error-600'],
  error700: DARK_COLORS['Component colors/Utility/Error/utility-error-700'],
  fuchsia50: DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-50'],
  fuchsia100:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-100'],
  fuchsia200:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-200'],
  fuchsia300:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-300'],
  fuchsia400:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-400'],
  fuchsia500:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-500'],
  fuchsia600:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-600'],
  fuchsia700:
    DARK_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-700'],
  gray50: DARK_COLORS['Component colors/Utility/Gray/utility-gray-50'],
  gray100: DARK_COLORS['Component colors/Utility/Gray/utility-gray-100'],
  gray200: DARK_COLORS['Component colors/Utility/Gray/utility-gray-200'],
  gray300: DARK_COLORS['Component colors/Utility/Gray/utility-gray-300'],
  gray400: DARK_COLORS['Component colors/Utility/Gray/utility-gray-400'],
  gray500: DARK_COLORS['Component colors/Utility/Gray/utility-gray-500'],
  gray600: DARK_COLORS['Component colors/Utility/Gray/utility-gray-600'],
  gray700: DARK_COLORS['Component colors/Utility/Gray/utility-gray-700'],
  gray800: DARK_COLORS['Component colors/Utility/Gray/utility-gray-800'],
  gray900: DARK_COLORS['Component colors/Utility/Gray/utility-gray-900'],
  grayBlue50:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-50'],
  grayBlue100:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-100'],
  grayBlue200:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-200'],
  grayBlue300:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-300'],
  grayBlue400:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-400'],
  grayBlue500:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-500'],
  grayBlue600:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-600'],
  grayBlue700:
    DARK_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-700'],
  indigo50: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-50'],
  indigo100: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-100'],
  indigo200: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-200'],
  indigo300: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-300'],
  indigo400: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-400'],
  indigo500: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-500'],
  indigo600: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-600'],
  indigo700: DARK_COLORS['Component colors/Utility/Indigo/utility-indigo-700'],
  orange50: DARK_COLORS['Component colors/Utility/Orange/utility-orange-50'],
  orange100: DARK_COLORS['Component colors/Utility/Orange/utility-orange-100'],
  orange200: DARK_COLORS['Component colors/Utility/Orange/utility-orange-200'],
  orange300: DARK_COLORS['Component colors/Utility/Orange/utility-orange-300'],
  orange400: DARK_COLORS['Component colors/Utility/Orange/utility-orange-400'],
  orange500: DARK_COLORS['Component colors/Utility/Orange/utility-orange-500'],
  orange600: DARK_COLORS['Component colors/Utility/Orange/utility-orange-600'],
  orange700: DARK_COLORS['Component colors/Utility/Orange/utility-orange-700'],
  orangeDark50:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-50'],
  orangeDark100:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-100'],
  orangeDark200:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-200'],
  orangeDark300:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-300'],
  orangeDark400:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-400'],
  orangeDark500:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-500'],
  orangeDark600:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-600'],
  orangeDark700:
    DARK_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-700'],
  pink50: DARK_COLORS['Component colors/Utility/Pink/utility-pink-50'],
  pink100: DARK_COLORS['Component colors/Utility/Pink/utility-pink-100'],
  pink200: DARK_COLORS['Component colors/Utility/Pink/utility-pink-200'],
  pink300: DARK_COLORS['Component colors/Utility/Pink/utility-pink-300'],
  pink400: DARK_COLORS['Component colors/Utility/Pink/utility-pink-400'],
  pink500: DARK_COLORS['Component colors/Utility/Pink/utility-pink-500'],
  pink600: DARK_COLORS['Component colors/Utility/Pink/utility-pink-600'],
  pink700: DARK_COLORS['Component colors/Utility/Pink/utility-pink-700'],
  purple50: DARK_COLORS['Component colors/Utility/Purple/utility-purple-50'],
  purple100: DARK_COLORS['Component colors/Utility/Purple/utility-purple-100'],
  purple200: DARK_COLORS['Component colors/Utility/Purple/utility-purple-200'],
  purple300: DARK_COLORS['Component colors/Utility/Purple/utility-purple-300'],
  purple400: DARK_COLORS['Component colors/Utility/Purple/utility-purple-400'],
  purple500: DARK_COLORS['Component colors/Utility/Purple/utility-purple-500'],
  purple600: DARK_COLORS['Component colors/Utility/Purple/utility-purple-600'],
  purple700: DARK_COLORS['Component colors/Utility/Purple/utility-purple-700'],
  success50: DARK_COLORS['Component colors/Utility/Success/utility-success-50'],
  success100:
    DARK_COLORS['Component colors/Utility/Success/utility-success-100'],
  success200:
    DARK_COLORS['Component colors/Utility/Success/utility-success-200'],
  success300:
    DARK_COLORS['Component colors/Utility/Success/utility-success-300'],
  success400:
    DARK_COLORS['Component colors/Utility/Success/utility-success-400'],
  success500:
    DARK_COLORS['Component colors/Utility/Success/utility-success-500'],
  success600:
    DARK_COLORS['Component colors/Utility/Success/utility-success-600'],
  success700:
    DARK_COLORS['Component colors/Utility/Success/utility-success-700'],
  warning50: DARK_COLORS['Component colors/Utility/Warning/utility-warning-50'],
  warning100:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-100'],
  warning200:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-200'],
  warning300:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-300'],
  warning400:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-400'],
  warning500:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-500'],
  warning600:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-600'],
  warning700:
    DARK_COLORS['Component colors/Utility/Warning/utility-warning-700'],
};

const LIGHT_UTILITY_COLORS = {
  brand50: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-50'],
  brand50Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-50_alt'],
  brand100: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-100'],
  brand100Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-100_alt'],
  brand200: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-200'],
  brand200Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-200_alt'],
  brand300: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-300'],
  brand400: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-400'],
  brand400Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-400_alt'],
  brand500: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-500'],
  brand500Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-500_alt'],
  brand600: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-600'],
  brand600Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-600_alt'],
  brand700: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-700'],
  brand700Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-700_alt'],
  brand800: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-800'],
  brand800Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-800_alt'],
  brand900: LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-900'],
  brand900Alt:
    LIGHT_COLORS['Component colors/Utility/Brand/utility-brand-900_alt'],
  blue50: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-50'],
  blue100: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-100'],
  blue200: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-200'],
  blue300: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-300'],
  blue400: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-400'],
  blue500: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-500'],
  blue600: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-600'],
  blue700: LIGHT_COLORS['Component colors/Utility/Blue/utility-blue-700'],
  blueDark50:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-50'],
  blueDark100:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-100'],
  blueDark200:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-200'],
  blueDark300:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-300'],
  blueDark400:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-400'],
  blueDark500:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-500'],
  blueDark600:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-600'],
  blueDark700:
    LIGHT_COLORS['Component colors/Utility/Blue dark/utility-blue-dark-700'],
  blueLight50:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-50'],
  blueLight100:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-100'],
  blueLight200:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-200'],
  blueLight300:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-300'],
  blueLight400:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-400'],
  blueLight500:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-500'],
  blueLight600:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-600'],
  blueLight700:
    LIGHT_COLORS['Component colors/Utility/Blue light/utility-blue-light-700'],
  error50: LIGHT_COLORS['Component colors/Utility/Error/utility-error-50'],
  error100: LIGHT_COLORS['Component colors/Utility/Error/utility-error-100'],
  error200: LIGHT_COLORS['Component colors/Utility/Error/utility-error-200'],
  error300: LIGHT_COLORS['Component colors/Utility/Error/utility-error-300'],
  error400: LIGHT_COLORS['Component colors/Utility/Error/utility-error-400'],
  error500: LIGHT_COLORS['Component colors/Utility/Error/utility-error-500'],
  error600: LIGHT_COLORS['Component colors/Utility/Error/utility-error-600'],
  error700: LIGHT_COLORS['Component colors/Utility/Error/utility-error-700'],
  fuchsia50:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-50'],
  fuchsia100:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-100'],
  fuchsia200:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-200'],
  fuchsia300:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-300'],
  fuchsia400:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-400'],
  fuchsia500:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-500'],
  fuchsia600:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-600'],
  fuchsia700:
    LIGHT_COLORS['Component colors/Utility/Fuchsia/utility-fuchsia-700'],
  gray50: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-50'],
  gray100: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-100'],
  gray200: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-200'],
  gray300: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-300'],
  gray400: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-400'],
  gray500: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-500'],
  gray600: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-600'],
  gray700: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-700'],
  gray800: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-800'],
  gray900: LIGHT_COLORS['Component colors/Utility/Gray/utility-gray-900'],
  grayBlue50:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-50'],
  grayBlue100:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-100'],
  grayBlue200:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-200'],
  grayBlue300:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-300'],
  grayBlue400:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-400'],
  grayBlue500:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-500'],
  grayBlue600:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-600'],
  grayBlue700:
    LIGHT_COLORS['Component colors/Utility/Gray blue/utility-gray-blue-700'],
  indigo50: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-50'],
  indigo100: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-100'],
  indigo200: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-200'],
  indigo300: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-300'],
  indigo400: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-400'],
  indigo500: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-500'],
  indigo600: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-600'],
  indigo700: LIGHT_COLORS['Component colors/Utility/Indigo/utility-indigo-700'],
  orange50: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-50'],
  orange100: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-100'],
  orange200: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-200'],
  orange300: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-300'],
  orange400: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-400'],
  orange500: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-500'],
  orange600: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-600'],
  orange700: LIGHT_COLORS['Component colors/Utility/Orange/utility-orange-700'],
  orangeDark50:
    LIGHT_COLORS['Component colors/Utility/Orange dark/utility-orange-dark-50'],
  orangeDark100:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-100'
    ],
  orangeDark200:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-200'
    ],
  orangeDark300:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-300'
    ],
  orangeDark400:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-400'
    ],
  orangeDark500:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-500'
    ],
  orangeDark600:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-600'
    ],
  orangeDark700:
    LIGHT_COLORS[
      'Component colors/Utility/Orange dark/utility-orange-dark-700'
    ],
  pink50: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-50'],
  pink100: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-100'],
  pink200: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-200'],
  pink300: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-300'],
  pink400: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-400'],
  pink500: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-500'],
  pink600: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-600'],
  pink700: LIGHT_COLORS['Component colors/Utility/Pink/utility-pink-700'],
  purple50: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-50'],
  purple100: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-100'],
  purple200: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-200'],
  purple300: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-300'],
  purple400: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-400'],
  purple500: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-500'],
  purple600: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-600'],
  purple700: LIGHT_COLORS['Component colors/Utility/Purple/utility-purple-700'],
  success50:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-50'],
  success100:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-100'],
  success200:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-200'],
  success300:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-300'],
  success400:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-400'],
  success500:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-500'],
  success600:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-600'],
  success700:
    LIGHT_COLORS['Component colors/Utility/Success/utility-success-700'],
  warning50:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-50'],
  warning100:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-100'],
  warning200:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-200'],
  warning300:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-300'],
  warning400:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-400'],
  warning500:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-500'],
  warning600:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-600'],
  warning700:
    LIGHT_COLORS['Component colors/Utility/Warning/utility-warning-700'],
};

const palette = {
  light: {
    ...COMMON,
    ...LIGHT_COLORS,
    mode: 'light',
    divider: LIGHT_COLORS['Colors/Border/border-secondary'],
    text: {
      primary: LIGHT_COLORS['Colors/Text/text-primary (900)'],
      secondary: '#414651',
      tertiary: LIGHT_COLORS['Colors/Text/text-tertiary (600)'],
      quaternary: LIGHT_COLORS['Colors/Text/text-quaternary (500)'],
      disabled: NEUTRAL.darkGrey,
    },
    border: LIGHT_BORDER_COLORS,
    forground: LIGHT_FORGROUND_COLORS,
    background: {
      primary: LIGHT_COLORS['Colors/Background/bg-primary'],
      primaryAlt: LIGHT_COLORS['Colors/Background/bg-primary_alt'],
      paper: NEUTRAL.paper,
      secondary: LIGHT_COLORS['Colors/Background/bg-primary'],
      secondaryAlt: LIGHT_COLORS['Colors/Background/bg-secondary_alt'],
      default: NEUTRAL.paper,
      card: {
        main: '#e6e6e6',
        light: LIGHT_COLORS['Colors/Background/bg-primary'],
      },
      disabled: LIGHT_COLORS['Colors/Background/bg-disabled'],
    },
    grey: LIGHT_GREY,
    utility: LIGHT_UTILITY_COLORS,
  },
  dark: {
    ...COMMON,
    ...DARK_COLORS,
    mode: 'dark',
    divider: DARK_COLORS['Colors/Border/border-secondary'],
    text: {
      primary: DARK_COLORS['Colors/Text/text-primary (900)'],
      secondary: '#CECFD2',
      tertiary: DARK_COLORS['Colors/Text/text-tertiary (600)'],
      quaternary: DARK_COLORS['Colors/Text/text-quaternary (500)'],
      disabled: NEUTRAL.darkGrey,
    },
    border: DARK_BORDER_COLORS,
    forground: DARK_FORGROUND_COLORS,
    background: {
      primary: DARK_COLORS['Colors/Background/bg-primary'],
      primaryAlt: DARK_COLORS['Colors/Background/bg-primary_alt'],
      paper: NEUTRAL.black,
      secondary: DARK_COLORS['Colors/Background/bg-primary'],
      secondaryAlt: DARK_COLORS['Colors/Background/bg-secondary_alt'],
      default: '#141414',
      card: {
        main: '#323232',
        light: DARK_COLORS['Colors/Background/bg-secondary_alt'],
      },
      disabled: DARK_COLORS['Colors/Background/bg-disabled'],
    },
    grey: DARK_GREY,
    utility: DARK_UTILITY_COLORS,
  },
} as const;

export default palette;

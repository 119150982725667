import { createContext, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useWorkspace from '@hooks/useWorkspace';

import Content from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content';
import Tabs from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs';
import Footer from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/footer';
import Header from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/header';
import Popover from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/popover';
import TriggerButton from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/trigger-button';
import useGetWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspacesById';
import paths from '@router/paths';

type PopoverContextType = {
  isPopoverOpen: boolean;
  handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
  handleNavigateToSelectedWorkspace: VoidFunction;
  handleSetSelectedWorkspaceId: (id: string) => void;
};

export const PopoverContext = createContext<PopoverContextType | null>(null);

export default function PopoverProvider({ children }: React.PropsWithChildren) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null,
  );
  const { handleSetWorkspace } = useWorkspace();
  const { workspaceData } = useGetWorkspaces();
  const navigateTo = useNavigate();
  const { pathname } = useLocation();
  const isPopoverOpen = Boolean(anchorEl);

  function handleOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleNavigateToSelectedWorkspace = useCallback(() => {
    if (pathname !== paths.knowledgeBase.pathname) {
      navigateTo(paths.knowledgeBase.pathname);
    }

    handleSetWorkspace(
      workspaceData?.find(
        (workspace) => workspace?.id === selectedWorkspaceId,
      ) || null,
    );
  }, [
    handleSetWorkspace,
    navigateTo,
    pathname,
    selectedWorkspaceId,
    workspaceData,
  ]);

  function handleSetSelectedWorkspaceId(id: string) {
    setSelectedWorkspaceId(id);
  }

  const memoizedValue = useMemo(
    () => ({
      isPopoverOpen,
      handleOpen,
      handleClose,
      anchorEl,
      handleNavigateToSelectedWorkspace,
      handleSetSelectedWorkspaceId,
    }),
    [anchorEl, handleNavigateToSelectedWorkspace, isPopoverOpen],
  );

  return (
    <PopoverContext.Provider value={memoizedValue}>
      {children}
    </PopoverContext.Provider>
  );
}

PopoverProvider.TriggerButton = TriggerButton;
PopoverProvider.Popover = Popover;
PopoverProvider.Popover.Header = Header;
PopoverProvider.Popover.Content = Content;
PopoverProvider.Popover.Footer = Footer;
PopoverProvider.Popover.Content.Tabs = Tabs;

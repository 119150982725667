import { alpha, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';

import CONFIG from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/config';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: CONFIG.DROPDOWN.WIDTH,
  marginBlockStart: theme.spacing(3),
  backgroundColor: theme.palette.background.card.main,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  borderTopLeftRadius: theme.shape.borderRadius * 2,
}));

export const StyledAutocompletePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBlockStart: theme.spacing(2),
  maxHeight: CONFIG.DROPDOWN.HEIGHT,
  border: '1px solid',
  borderColor: theme.palette.border.secondary,
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.primary,
}));

export const StyledListbox = styled('ul')(() => ({
  maxHeight: CONFIG.DROPDOWN.HEIGHT,
}));

export const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
  backgroundColor: theme.palette.background.primary,
  width: `${CONFIG.DROPDOWN.WIDTH}px!important`,
  borderBottomRightRadius: theme.shape.borderRadius * 2,
  borderBottomLeftRadius: theme.shape.borderRadius * 2,
}));

export const StyledStackListItem = styled((props) => (
  <Stack
    {...props}
    component={ListItem}
    direction="row"
    gap={2}
  />
))(({ theme }) => ({
  height: theme.spacing(9.5),
  borderRadius: theme.shape.borderRadius * 1.4,
  marginBlock: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
})) as typeof ListItem;

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { StyledContainerForFixedHeader } from '@components/pages/styles';

import Header from '@layouts/main/sections/header';

export default function KnowledgeBaseLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <Stack>
      <Header ToolbarToggle />
      <Container maxWidth={false}>
        <Stack
          sx={{
            flexDirection: {
              xs: 'none',
              md: 'row',
            },
            justifyContent: 'center',
            minHeight: '100%',
          }}
        >
          <StyledContainerForFixedHeader
            maxWidth={false}
            disableGutters
          >
            {children}
          </StyledContainerForFixedHeader>
        </Stack>
      </Container>
    </Stack>
  );
}

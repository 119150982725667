import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiPopover from '@mui/material/Popover';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

import KnowledgeSourceIcon from '@components/search-form/knowledge-source-icon';
import {
  StyledFormControlLabel,
  StyledRadio,
} from '@components/search-form/scope-selector/style';
import Text from '@components/text';

import { SearchScopeType } from '@providers/AppSettings';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import useAuth from '@hooks/useAuth';

import { scopeUpdated } from '@state/slices/search-result';

export default function ScopeSelector() {
  const { userSpecificSettings } = useAppSettings();
  const searchScope = useAppSelector((state) => state.searchResult.scope);
  const { me } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !me ||
      !userSpecificSettings ||
      !userSpecificSettings[me.uuid]?.searchScope
    ) {
      return;
    }

    dispatch(scopeUpdated(userSpecificSettings[me.uuid]!.searchScope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleScopeSelect(selectedMode: SearchScopeType) {
    dispatch(scopeUpdated(selectedMode));
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        size="small"
        onClick={handleOpenPopover}
        color="tertiarySecondary"
      >
        <KnowledgeSourceIcon mode={searchScope} />
      </IconButton>

      <MuiPopover
        open={open}
        aria-label="search scope popover"
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 205,
          horizontal: 88,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { sx: { p: 0 } } }}
      >
        <Stack
          sx={{ pb: 2 }}
          gap={2}
        >
          <Text
            sx={{ px: 3, pt: 2.5 }}
            variant="textMd"
            weight="semibold"
          >
            Where to search?
          </Text>

          <Divider />

          <RadioGroup
            sx={{ gap: 2 }}
            value={searchScope}
            onChange={(e) => {
              handleScopeSelect(e.target.value as SearchScopeType);
            }}
          >
            <StyledFormControlLabel
              value="web_kb"
              control={<StyledRadio />}
              label="Web & Knowledge Base"
            />

            <StyledFormControlLabel
              value="kb_only"
              control={<StyledRadio />}
              label="Knowledge Base Only"
            />

            <StyledFormControlLabel
              value="web_only"
              control={<StyledRadio />}
              label="Web Only"
            />
          </RadioGroup>
        </Stack>
      </MuiPopover>
    </Box>
  );
}

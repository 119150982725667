const FILE_TYPES = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
};

const AVATAR = {
  QUALITY: 0.5,
  FORMAT: 'image/jpeg',
};

const MODAL = {
  MOBILE: {
    WIDTH: '100%',
    HEIGHT: '100%',
  },
  DESKTOP: {
    WIDTH: 688,
    HEIGHT: 'auto',
  },
};

const CONFIG = {
  FILE_TYPES,
  FILE_SIZE_LIMIT: 20 * 1024 * 1024, // 20MB
  THUMBNAIL_SIZE: 250,
  MODAL,
  AVATAR,
} as const;

export default CONFIG;

/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  padding: theme.spacing(2, 2.5),

  [`& .${formControlLabelClasses.label}`]: {
    ...theme.typography.textSm,
  },
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  height: 22,
}));

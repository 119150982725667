import { KnowzStepDefinitionType } from '@lib/step/types';

const LoopWhileStepDefinition: KnowzStepDefinitionType = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'container',
    type: 'loop-while',
    name: 'Do While / While',
    sequence: [],
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'condition',
      name: 'Condition for ending the loop',
      description: '',
      usage: 'definition',
      variant: 'code',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'isDoWhile',
      name: 'True for do-while, false for while',
      description: '',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['boolean'],
      defaultValue: true,
    },
  ],
};

export default LoopWhileStepDefinition;

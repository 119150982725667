import { useState } from 'react';

import Box from '@mui/material/Box';
import { TabProps } from '@mui/material/Tab';

import {
  StyledBox,
  StyledTab,
  StyledTabs,
} from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/styles';
import InvitedToWorkspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/invited-to-workspaces';
import OwnedWorkspaces from '@layouts/main/sections/toolbar/components/workspaces-popover/sections/content/tabs/tab-panels/sections/owned-workspaces';

function a11yProps(index: number): TabProps {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const TABS = [
  {
    name: 'Your Knowledge Bases',
  },
  {
    name: 'Invited to',
  },
];

const TAB_PANELS = [<OwnedWorkspaces />, <InvitedToWorkspaces />];

export default function Tabs() {
  const [tabValue, setTabValue] = useState(0);

  function handleTabChange(_event: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue);
  }

  return (
    <StyledBox>
      <StyledTabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
      >
        {TABS.map((tab, index) => (
          <StyledTab
            disableFocusRipple
            disableRipple
            disableTouchRipple
            key={index}
            {...a11yProps(index)}
            aria-label={tab.name}
            label={tab.name}
          />
        ))}
      </StyledTabs>
      {TAB_PANELS.map((panel, index) => (
        <Box
          key={index}
          role="tabpanel"
          hidden={tabValue !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {panel}
        </Box>
      ))}
    </StyledBox>
  );
}

import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '234px',
  },
}));

export default StyledBox;

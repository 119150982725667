import friendlyNameManager from '@components/pages/live-session/utils/getFriendlyName';

import { threads } from '@lib/agent';

import { RootType } from '@state/store';

import {
  FeedBlockType,
  FeedLabelsType,
  SentimentLabelsType,
  TranscriptType,
} from '@shared-types/live';
import { LiveHistoryBlockType } from '@shared-types/threads';

import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

type FeedsType = FeedBlockType & { time: TranscriptType['time'] };

type LiveSessionStateType = {
  threadId?: string;
  status: 'loading' | 'active';
  transcripts: TranscriptType[];
  feeds: FeedsType[];
  searchQuery: string; //TODO: tempo
  voiceMatch: Record<
    string,
    {
      name: string | null;
    }
  > | null;
};

const initialState: LiveSessionStateType = {
  threadId: undefined,
  status: 'loading',
  transcripts: [],
  searchQuery: '', //TODO: tempo
  feeds: [],
  voiceMatch: null,
};

const liveSession = createSlice({
  name: 'liveSession',
  initialState,
  reducers: {
    setThreadId: (
      _state,
      action: PayloadAction<LiveSessionStateType['threadId']>,
    ) => ({
      ...initialState,
      threadId: action.payload,
    }),

    setActive: (state) => ({
      ...state,
      status: 'active',
    }),

    setTranscripts: (
      state,
      action: PayloadAction<LiveSessionStateType['transcripts']>,
    ) => ({
      ...state,
      transcripts: action.payload,
    }),

    setFeeds: (
      state,
      action: PayloadAction<LiveSessionStateType['feeds']>,
    ) => ({
      ...state,
      feeds: action.payload,
    }),

    addFeed: (state, action: PayloadAction<Omit<FeedBlockType, 'time'>>) => {
      const { transcriptIndex } = action.payload;

      const index = state.transcripts.findLastIndex(
        (t) => t.id === transcriptIndex,
      );

      if (index < 0) return state;

      return {
        ...state,
        feeds: [
          ...state.feeds,
          {
            ...action.payload,
            time: state.transcripts[index]!.time,
          },
        ],
      };
    },

    addTranscript: (
      state,
      action: PayloadAction<Omit<TranscriptType, 'labels'>>,
    ) => {
      const { id } = action.payload;

      return {
        ...state,
        transcripts: [
          ...state.transcripts.filter((transcript) => transcript.id !== id),
          {
            ...action.payload,
            labels: {
              sentiments: null,
              feeds: null,
            },
          },
        ],
      };
    },

    setSentiments: (
      state,
      action: PayloadAction<{
        historyBlockId: string;
        sentiments: SentimentLabelsType;
      }>,
    ) => {
      const { historyBlockId, sentiments } = action.payload;
      const index = state.transcripts.findLastIndex(
        (t) => t.id === historyBlockId,
      );

      if (index > -1) {
        state.transcripts[index].labels.sentiments = sentiments;
      }

      return state;
    },

    setFeedLabels: (
      state,
      action: PayloadAction<{
        historyBlockId: string;
        feeds: FeedLabelsType;
      }>,
    ) => {
      const { historyBlockId, feeds } = action.payload;
      const index = state.transcripts.findLastIndex(
        (t) => t.id === historyBlockId,
      );

      if (index > -1) {
        state.transcripts[index].labels.feeds = feeds;
      }

      return state;
    },

    setVoiceMatch: (
      state,
      action: PayloadAction<LiveSessionStateType['voiceMatch']>,
    ) => {
      return {
        ...state,
        voiceMatch: {
          ...state.voiceMatch,
          ...action.payload,
        },
      };
    },

    //TODO: temporary for testing
    setSearchQuery: (state, action: PayloadAction<string>) => ({
      ...state,
      searchQuery: action.payload,
    }),
  },
});

export const onThreadIdListener = createListenerMiddleware();
onThreadIdListener.startListening({
  predicate: ({ type }) => type === liveSession.actions.setThreadId.type,
  effect: (_, { dispatch }) => {
    dispatch(loadThread());
  },
});

const loadThread = createAsyncThunk(
  'liveSession/loadThread',
  async (_, { getState, dispatch }) => {
    const {
      liveSession: { threadId },
    } = getState() as RootType;

    if (!threadId) {
      dispatch(liveSession.actions.setActive());
      return;
    }

    // TODO: do this with tanstack
    const { data } = await threads.getHistoryBlockById<LiveHistoryBlockType>({
      id: threadId!,
      size: '500', // TOdO: paginate
      order: 'ASC',
    });

    const { data: threadData } = await threads.getThreadById(threadId!);
    const startingTime = new Date(threadData.createdAt || null);
    const transcripts: LiveSessionStateType['transcripts'] = [];
    const feeds: LiveSessionStateType['feeds'] = [];

    for (const block of data) {
      const diff = new Date(block.createdAt).getTime() - startingTime.getTime();
      const minutes = Math.floor(diff / 60000) + '';
      const seconds = ((diff % 60000) / 1000).toFixed(0) + '';
      const time = {
        minutes: minutes.padStart(2, '0'),
        seconds: seconds.padStart(2, '0'),
      };

      const sentiments = block.match?.map(({ classification, sentiment }) => ({
        classification,
        sentiment,
      })) as SentimentLabelsType;

      let feedsLabels: FeedLabelsType | null = null;

      if (block.feed?.length) {
        feedsLabels = [];
        for (const feed of block.feed) {
          feed.feedItems.forEach((feedItem) => {
            feedsLabels?.push({
              blockTagName: feedItem.subTitle,
              id: feedItem.feedId,
            });
          });
          feeds.push({
            ...feed,
            time,
          });
        }
      }

      transcripts.push({
        id: block.id,
        content: block.text,
        isItKnowzAI: /knowz ai/i.test(block.speaker.reference),
        speaker: {
          reference: block.speaker.reference,
          friendlyName: friendlyNameManager.setFriendlyName(
            block.speaker.reference,
          ),
        },
        time,
        labels: { sentiments, feeds: feedsLabels },
      });
    }

    dispatch(liveSession.actions.setTranscripts(transcripts));
    dispatch(liveSession.actions.setFeeds(feeds));
    dispatch(liveSession.actions.setActive());
    dispatch(
      liveSession.actions.setVoiceMatch({
        ...threadData.live.speakers,
      }),
    );
  },
);

export const {
  setThreadId,
  addTranscript,
  addFeed,
  setFeedLabels,
  setSentiments,
  setVoiceMatch,
  setSearchQuery, //TODO: temporary for testing
} = liveSession.actions;

export default liveSession.reducer;

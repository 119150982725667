import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Input from '@components/input';
import LogoutButton from '@components/logout-button';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import {
  HEADER_HEIGHT,
  MAX_MODAL_HEIGHT,
} from '@components/settings/components/modal-wrapper';
import Avatar from '@components/settings/components/tabs/general/components/avatar';
import FieldGroup from '@components/settings/components/tabs/general/components/field-group';
import useMutateMe from '@components/settings/components/tabs/general/hooks/useMutateMe';
import userFormSchema from '@components/settings/components/tabs/general/schemas';
import CONFIG from '@components/settings/config';
import Text from '@components/text';
import ThemeMode from '@components/theme-mode';

import { signUp } from '@lib/auth/fingerprint';
import { setSession } from '@lib/jwt';

import useAuth from '@hooks/useAuth';
import useCreateRefreshToken from '@hooks/useGetRefreshToken';
import useResponsive from '@hooks/useResponsive';
import useWorkspace from '@hooks/useWorkspace';

import ArrowLeftIcon from '~icons/knowz-iconify/arrow-left';
import EditRoundedIcon from '~icons/knowz-iconify/edit-rounded';

import { yupResolver } from '@hookform/resolvers/yup';
import paths from '@router/paths';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  onClose: () => void;
};

const SAVE_DEBOUNCE = 3000;

export default function General({ onClose }: Props) {
  const navigateTo = useNavigate();
  const { resetWorkspaceToIndividualRole, setupMode } = useWorkspace();
  const { me, handleUpdateMe } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isItDesktop = useResponsive('up', 'md');

  const hasSecuredAccount =
    me && (me.auth_type === 'email' || me.auth_type === 'oauth2');

  const { mutateAsync: mutateMeAsync, isPending: isMutateMePending } =
    useMutateMe({
      onError: () => {
        toast.error(
          t('component.settings.general.updateProfile.response.error', {
            defaultValue: 'An error occurred while updating your profile',
          }),
        );
      },
      onSuccess: () => {
        toast.success(
          t('component.settings.general.updateProfile.response.success', {
            defaultValue: 'Your profile has been updated',
          }),
        );
      },
    });

  const { mutateCreateRefreshTokenAsync } = useCreateRefreshToken(
    (variables) => {
      const token = variables.data.token;
      setSession(token);
      handleUpdateMe(token);
    },
  );

  const methods = useForm({
    resolver: yupResolver(userFormSchema),
    defaultValues: {
      firstName: me?.first_name || '',
      lastName: me?.last_name || '',
    },
  });

  const {
    getValues,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
    reset,
  } = methods;

  const saveTimeout = useRef<NodeJS.Timeout | null>(null);

  const persistUpdate = useCallback(async () => {
    if (!isDirty || !isValid) return;

    if (isMutateMePending) {
      saveTimeout.current && clearTimeout(saveTimeout.current);
      saveTimeout.current = setTimeout(persistUpdate, SAVE_DEBOUNCE);
    } else {
      const data = getValues();
      await mutateMeAsync({
        firstName: data.firstName,
        lastName: data.lastName,
      });

      await mutateCreateRefreshTokenAsync();
      reset(data);
    }
  }, [
    isDirty,
    isValid,
    isMutateMePending,
    getValues,
    mutateMeAsync,
    mutateCreateRefreshTokenAsync,
    reset,
  ]);

  useEffect(() => {
    const subscription = watch(() => {
      handleSubmit(() => ({}))();
      saveTimeout.current && clearTimeout(saveTimeout.current);
      saveTimeout.current = setTimeout(persistUpdate, SAVE_DEBOUNCE);
    });

    saveTimeout.current && clearTimeout(saveTimeout.current);
    saveTimeout.current = setTimeout(persistUpdate, SAVE_DEBOUNCE);

    return () => subscription.unsubscribe();
  }, [handleSubmit, persistUpdate, watch]);

  async function handleLogout() {
    onClose();
    window.localStorage.removeItem('token');
    resetWorkspaceToIndividualRole();

    if (setupMode !== null) {
      navigateTo(paths.home.pathname);
    }

    if (!executeRecaptcha) return;
    const recaptchaToken = await executeRecaptcha();
    const response = await signUp(recaptchaToken);

    if (response?.data.status === 'Ok') {
      window.localStorage.removeItem('fingerPrintId');
      handleUpdateMe(response.data.token);
    }
  }

  if (!hasSecuredAccount) {
    return (
      <Stack>
        <CONFIG.FormHeader title="Free Account">
          {!isItDesktop && (
            <IconButton
              color="tertiarySecondary"
              onClick={onClose}
            >
              <ArrowLeftIcon />
            </IconButton>
          )}
        </CONFIG.FormHeader>

        <Stack
          gap={4}
          width="100%"
          sx={{
            padding: 4,
            overflow: 'auto',
            maxHeight: `calc(${MAX_MODAL_HEIGHT} - ${HEADER_HEIGHT})`,
          }}
        >
          {/* <Card>Add Credit Card Here</Card>

          <Divider /> */}

          <FieldGroup label="Theme">
            <ThemeMode />
          </FieldGroup>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack>
      <CONFIG.FormHeader title="General">
        {!isItDesktop && (
          <IconButton
            color="tertiarySecondary"
            onClick={onClose}
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
      </CONFIG.FormHeader>

      <Stack
        gap={4}
        width="100%"
        sx={{
          px: 6,
          py: 5,
          overflow: 'auto',
          maxHeight: `calc(${MAX_MODAL_HEIGHT} - ${HEADER_HEIGHT})`,
        }}
      >
        {/* <Card>Add Credit Card Here</Card> */}
        <FieldGroup label="Your Photo">
          <Stack
            sx={{ width: '100%' }}
            gap={3}
          >
            <Text
              variant="textSm"
              color={theme.palette['Colors/Text/text-tertiary (600)']}
            >
              {t('component.settings.general.updateProfile.avatar.message', {
                defaultValue: 'Visible to your teammates in workspaces',
              })}
            </Text>

            <Avatar />
          </Stack>
        </FieldGroup>

        <FormProvider methods={methods}>
          <Stack gap={4}>
            <FieldGroup
              isItDesktop={isItDesktop}
              label="First Name"
            >
              <RHFInputField
                name="firstName"
                error={!!errors.firstName?.message}
                helperText={errors.firstName?.message}
                endAdornment={<EditRoundedIcon width={13} />}
              />
            </FieldGroup>

            <FieldGroup
              isItDesktop={isItDesktop}
              label="Last Name"
            >
              <RHFInputField
                name="lastName"
                error={!!errors.lastName?.message}
                helperText={errors.lastName?.message}
                endAdornment={<EditRoundedIcon width={13} />}
              />
            </FieldGroup>

            <FieldGroup
              isItDesktop={isItDesktop}
              label="Email"
            >
              <Input
                disabled
                fullWidth
                defaultValue={me?.email}
              />
            </FieldGroup>
          </Stack>
        </FormProvider>

        <Divider />

        <FieldGroup label="Theme">
          <ThemeMode />
        </FieldGroup>

        <Divider />

        <FieldGroup label="Logout">
          <LogoutButton handleLogout={handleLogout} />
        </FieldGroup>
      </Stack>
    </Stack>
  );
}

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid2';

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingInline: theme.spacing(4),
  paddingBlockStart: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    paddingInline: theme.spacing(6),
    paddingBlockStart: theme.spacing(6),
  },
}));

export default StyledGrid;

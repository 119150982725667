import { useMemo } from 'react';
import React from 'react';

import Alert from '@mui/material/Alert';

import useGetHistoryBlocksByThreadIdLazily from '@components/pages/hooks/useGetHistoryBlocksByThreadIdLazily';
import LoadingSkeleton from '@components/pages/search/components/loading-skeleton';
import AnswerBlock from '@components/pages/search/components/shared-blocks/answer';
import FollowupsBlock from '@components/pages/search/components/shared-blocks/followups';
import InfoBlock from '@components/pages/search/components/shared-blocks/info';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import LinksBlock from '@components/pages/search/components/shared-blocks/links';
import PhotosBlock from '@components/pages/search/components/shared-blocks/photos';
import PromptBlock from '@components/pages/search/components/shared-blocks/prompt';
import SinglePhotosCol from '@components/pages/search/components/shared-blocks/single-photos-col';
import GeneralMessage from '@components/pages/search/components/shared-blocks/tos';
import KnowledgeInfoCard from '@components/source-preview';

import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import { HistoryBlocksDataType } from '@shared-types/search-result/history-blocks';
import { SourceLinksType } from '@shared-types/search-result/streaming';

export default function HistoryBlocks() {
  const { getQueryParamByKey } = useQueryParams();

  const { t } = useTranslation();

  const {
    historyBlocksQueryData,
    isHistoryBlocksLoading,
    isHistoryBlocksError,
  } = useGetHistoryBlocksByThreadIdLazily<HistoryBlocksDataType>({
    threadId: getQueryParamByKey('t'),
  });

  const userHistoryBlocks = useMemo(
    () => {
      const assistantItems = (historyBlocksQueryData || []).filter(
        (item) => item.role !== 'user',
      );
      const userItems = (historyBlocksQueryData || []).filter(
        (item) => item.role === 'user',
      );

      const updatedAssistantItems = assistantItems.map(
        (assistantItem, index) => {
          const matchingUserItem = userItems[index];
          if (matchingUserItem) {
            return {
              ...assistantItem,
              file: matchingUserItem.file,
            };
          }
          return assistantItem;
        },
      );

      return updatedAssistantItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyBlocksQueryData?.length],
  );

  if (isHistoryBlocksLoading) {
    return <LoadingSkeleton />;
  }

  if (isHistoryBlocksError) {
    return (
      <Alert severity="error">
        {t('response.errors.code.520', {
          defaultMessage: 'Unknown error. Please try again later.',
        })}
      </Alert>
    );
  }
  return userHistoryBlocks?.map((item, index: number, originalArray) => {
    if (item.tos != null) {
      return (
        <GeneralMessage
          key={index}
          content={item.tos}
          heading={t('page.search.sections.answer', {
            defaultValue: 'Terms of Service',
          })}
        />
      );
    }

    const layoutData = item.layout;
    const photosData = layoutData.photos;
    const uploadedImageKey = item.file;
    const infoData = layoutData.info;
    const lastFollowup = originalArray.length - 1 === index;
    const hasAnswer = Boolean(
      layoutData.answer && layoutData.answer.response.length > 0,
    );
    const hasAtLeastOneInfoCard = Boolean(infoData.length > 0); //? for now we get one info card
    const userSearchData = layoutData.links.filter(
      (link) => link?.kind === 'usersearch#result',
    ) as Array<SourceLinksType>;

    return (
      <React.Fragment key={index}>
        <Layout
          key={index}
          answer={
            hasAnswer ? (
              <AnswerBlock
                type="history"
                answer={layoutData.answer!.response}
                id={layoutData.answer!.id}
                links={userSearchData}
              />
            ) : undefined
          }
          info={
            <InfoBlock
              text={hasAtLeastOneInfoCard ? infoData[0].response : null}
              links={userSearchData}
            />
          }
          followups={
            lastFollowup ? (
              <FollowupsBlock followups={item.followups} />
            ) : undefined
          }
          prompt={<PromptBlock prompt={layoutData.userQuery} />}
          photos={
            <PhotosBlock
              hasAnswer={hasAnswer}
              hasInfo={hasAtLeastOneInfoCard}
              photosData={photosData}
            />
          }
          links={
            <LinksBlock
              links={layoutData.links}
              uploadedImageKey={uploadedImageKey}
            />
          }
          singlePhotosCol={
            <SinglePhotosCol
              hasAnswer={hasAnswer}
              hasInfo={hasAtLeastOneInfoCard}
              photosData={photosData}
            />
          }
        />
        <KnowledgeInfoCard />
      </React.Fragment>
    );
  });
}

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';
import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import useGenerateIcons from '@components/workspace/setup/hooks/useGenerateIcons';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import ImagePreview from '@components/workspace/setup/sections/steps/components/image-preview';
import Generator from '@components/workspace/setup/sections/steps/generate-icon/components/icon/generator';
import Upload from '@components/workspace/setup/sections/steps/generate-icon/components/upload';
import generateIconSchema from '@components/workspace/setup/sections/steps/generate-icon/schema';
import {
  StyledBoxWrapper,
  StyledHeaderBox,
  StyledStackDescriptionField,
  StyledStackIcons,
  StyledStackWrapper,
  StyledUploadBox,
} from '@components/workspace/setup/sections/steps/generate-icon/styles';

import useResponsive from '@hooks/useResponsive';
import useUploadByLinks from '@hooks/useUploadByLinks';

import { useTranslation } from '@desygner/ui-common-translation';

import { GenerateIconsRequestType } from '@shared-types/threads';

import SmartStarIcon from '~icons/knowz-iconify/smart-star';

import { faker } from '@faker-js/faker';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSocket, useSocketEvent } from 'socket.io-react-hook';

type MessageType = {
  type: 'imageGen';
  imageUrls: string[];
  timeTaken: null;
};

const DESKTOP_MAX_ROWS = 7.25;
const MOBILE_MAX_ROWS = 5;

export default function GenerateIcon() {
  const { t } = useTranslation();
  const { mutateAsync: mutateGenerateIconsAsync } = useGenerateIcons();
  const { mutateAsync: mutateUploadByLinksAsync } = useUploadByLinks();
  const isItDesktop = useResponsive('up', 'md');

  const {
    generatedIconsDescription,
    handleSetGeneratedIcon,
    handleSetGeneratedIconDescription,
    handleSetIconGenerationStatus,
    selectedIcon,
    handleGoToPreviousStep,
    // handleSetSelectedIcon,
    uploadedAvatar,
  } = useWorkspaceSetup();

  const { socket } = useSocket(import.meta.env.VITE_KNOWZ_WEB_SOCKET_URL, {
    enabled: true,
  });
  const methods = useForm({
    resolver: yupResolver(generateIconSchema),
    defaultValues: {
      prompt: generatedIconsDescription,
    },
  });

  const { handleSubmit, watch } = methods;
  const isFieldEmpty = watch('prompt') === '';

  useSocketEvent(socket, 'message', {
    onMessage: async (message: MessageType) => {
      if (message.type === 'imageGen') {
        handleSetGeneratedIcon(message.imageUrls);
        handleSetIconGenerationStatus('finished');
      }
    },
  });

  async function generateIcons({
    prompt,
  }: Pick<GenerateIconsRequestType, 'prompt'>) {
    handleSetGeneratedIconDescription(prompt);
    const randomUUID = faker.string.uuid(); //? generate a random UUID for the thread no matter what
    socket.emit('subscribe', `thread_${randomUUID}`);
    await mutateGenerateIconsAsync({
      prompt,
      threadId: randomUUID,
    });
    handleSetIconGenerationStatus('pending');
  }

  async function handleNext() {
    await mutateUploadByLinksAsync({
      context: {
        usage: 'profile_picture',
      },
      handler: 'curl',
      links: [
        {
          url: uploadedAvatar
            ? URL.createObjectURL(uploadedAvatar)
            : selectedIcon,
        },
      ],
    });
  }

  return (
    <>
      <Header
        Icon={<ImagePreview />}
        title={t('page.workspace.createWorkspaceModal.step2.title', {
          defaultValue: 'Customize your Knowledge Base',
        })}
        subtitle={t('page.workspace.createWorkspaceModal.step2.description', {
          defaultValue:
            'Make your Knowledge Base instantly recognizable with a unique icon - use your logo, branding elements, a photo, or let Knowz create one for you.',
        })}
      />
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(generateIcons)}
      >
        <StyledBoxWrapper>
          <StyledStackWrapper>
            <StyledStackDescriptionField
              direction="column"
              gap={2.5}
            >
              <Stack
                gap={3}
                sx={{ width: '100%' }}
              >
                <Stack
                  direction="row"
                  gap={2.5}
                >
                  <FeaturedIcon size="small">
                    <SmartStarIcon />
                  </FeaturedIcon>
                  <StyledHeaderBox>
                    <Text
                      variant="textMd"
                      weight="semibold"
                    >
                      {t(
                        'page.workspace.step2.subSteps.step1.generateIcon.segment1',
                        {
                          defaultValue: 'Generate an icon',
                        },
                      )}
                    </Text>
                    <Text
                      variant="textSm"
                      color="text.disabled"
                    >
                      {t(
                        'page.workspace.step2.subSteps.step1.generateIcon.segment2',
                        {
                          defaultValue:
                            'Describe what your icon to look like, this will use credits.',
                        },
                      )}
                    </Text>
                  </StyledHeaderBox>
                </Stack>

                <RHFInputField
                  multiline
                  maxRows={isItDesktop ? DESKTOP_MAX_ROWS : MOBILE_MAX_ROWS}
                  minRows={isItDesktop ? DESKTOP_MAX_ROWS : MOBILE_MAX_ROWS}
                  placeholder="Add description and press enter or just press generate and Knowz will create an image to use as your icon..."
                  name="prompt"
                />
              </Stack>
              {isItDesktop && <Upload />}
            </StyledStackDescriptionField>
            <StyledStackIcons>
              <Stack
                direction={isItDesktop ? 'column' : 'row'}
                gap={1.5}
                sx={{
                  ...(isItDesktop
                    ? {
                        overflowY: 'scroll',
                      }
                    : {
                        overflowX: 'scroll',
                      }),
                }}
              >
                <Generator />
              </Stack>
              <Button
                fullWidth
                variant="contained"
                disabled={isFieldEmpty}
                onClick={handleSubmit(generateIcons)}
              >
                {t(
                  'page.workspace.createWorkspaceModal.step2.subSteps.step2.buttons.generate',
                  {
                    defaultValue: 'Generate',
                  },
                )}
              </Button>
            </StyledStackIcons>
          </StyledStackWrapper>
        </StyledBoxWrapper>
      </FormProvider>
      {!isItDesktop && (
        <StyledUploadBox>
          <Upload />
        </StyledUploadBox>
      )}
      <ButtonWrapper>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ButtonBase
            disableRipple
            disableTouchRipple
          >
            {t('page.workspace.createWorkspaceModal.skipButton', {
              defaultValue: 'Skip step',
            })}
          </ButtonBase>
          <Stack
            direction="row"
            gap={3}
          >
            <Button
              size="large"
              onClick={handleGoToPreviousStep}
            >
              {t('page.workspace.createWorkspaceModal.backButton', {
                defaultValue: 'Back',
              })}
            </Button>
            <Button
              onClick={handleNext}
              size="large"
              disabled={!selectedIcon && !uploadedAvatar} //TODO: if user selects a generated icon or uploads an icon we enable this button
              variant="contained"
            >
              {t('page.workspace.createWorkspaceModal.nextButton', {
                defaultValue: 'Next',
              })}
            </Button>
          </Stack>
        </Stack>
      </ButtonWrapper>
    </>
  );
}

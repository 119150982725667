import breakpoints from '@theme/breakpoints';

export const BASE_FONT_SIZE = 16;

export function convertPxToRem(number: number | string) {
  if (typeof number === 'string') {
    number = parseInt(number);
  }

  return `${(number / BASE_FONT_SIZE).toFixed(2)}rem`;
}

export function responsiveFontSize({
  xs,
  sm,
  md,
  lg,
}: {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}) {
  return {
    ...(xs && {
      [`@media screen and (min-width:${breakpoints.values.xs}px)`]: {
        fontSize: convertPxToRem(xs),
      },
    }),
    ...(sm && {
      [`@media screen and (min-width:${breakpoints.values.sm}px)`]: {
        fontSize: convertPxToRem(sm),
      },
    }),
    ...(md && {
      [`@media screen and (min-width:${breakpoints.values.md}px)`]: {
        fontSize: convertPxToRem(md),
      },
    }),
    ...(lg && {
      [`@media screen and (min-width:${breakpoints.values.lg}px)`]: {
        fontSize: convertPxToRem(lg),
      },
    }),
  };
}

import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import LoginIcon from '@mui/icons-material/Login';
import Button, { ButtonProps } from '@mui/material/Button';

import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import StarIcon from '~icons/knowz-iconify/star';

import useUserFunnelType from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import paths from '@router/paths';

const SHARED_PROPS: ButtonProps = {
  fullWidth: true,
  size: 'medium',
  variant: 'contained',
};

export default function CreditActions() {
  const { userFunnelType } = useUserFunnelType();

  const { t } = useTranslation();
  const { setQueryParam } = useQueryParams();

  const buttons = useMemo(() => {
    if (userFunnelType === 'visitor') {
      return ['login', 'credits'];
    }

    if (userFunnelType === 'free') {
      return ['plan', 'credits'];
    }

    return ['more'];
  }, [userFunnelType]);

  return (
    <>
      {buttons.includes('login') && (
        <Button
          onClick={() => {
            setQueryParam('action', 'secure-account');
          }}
          startIcon={<LoginIcon />} // TODO: Replace with correct icon
          {...SHARED_PROPS}
        >
          {t('layout.toolbar.buyAICredit.cta.button.login')}
        </Button>
      )}

      {buttons.includes('plan') && (
        <Button
          component={Link}
          to={paths.upgradeAccount.pathname}
          {...SHARED_PROPS}
        >
          {t('layout.toolbar.buyAICredit.cta.button.plan')}
        </Button>
      )}

      {buttons.includes('credits') && (
        <Button
          component={Link}
          to={paths.upgradeAccount.pathname}
          startIcon={<StarIcon />}
          color="secondary"
          {...SHARED_PROPS}
        >
          {t('layout.toolbar.buyAICredit.cta.button.credits')}
        </Button>
      )}

      {buttons.includes('more') && (
        <Button
          component={Link}
          to={paths.upgradeAccount.pathname}
          startIcon={<StarIcon />}
          color="secondary"
          {...SHARED_PROPS}
        >
          {t('layout.toolbar.buyAICredit.cta.button.more')}
        </Button>
      )}
    </>
  );
}

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';

export function initializeGA() {
  if (import.meta.env.VITE_GA_TRACKING_ID) {
    ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID);
  } else {
    console.warn('GA_TRACKING_ID is not set, GA will not be initialized');
  }
}

export function initializeGTM() {
  if (
    import.meta.env.VITE_GTM_ID &&
    import.meta.env.VITE_GTM_AUTH &&
    import.meta.env.VITE_GTM_PREVIEW
  ) {
    TagManager.initialize({
      gtmId: import.meta.env.VITE_GTM_ID,
      auth: import.meta.env.VITE_GTM_AUTH,
      preview: import.meta.env.VITE_GTM_PREVIEW,
    });
  } else {
    console.warn('GTM_ID is not set, GTM will not be initialized');
  }
}

export function initializeClarity() {
  if (import.meta.env.VITE_CLARITY_ID) {
    clarity.init(import.meta.env.VITE_CLARITY_ID);
  } else {
    console.warn('CLARITY_ID is not set, Clarity will not be initialized');
  }
}

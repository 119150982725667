import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import IconButton from '@mui/material/IconButton';

import Text from '@components/text';
import StyledStack from '@components/user-authentication/components/form-heading/styles';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';

import useQueryParams from '@hooks/useQueryParams';

type Props = {
  heading: string;
  withBackButton?: boolean;
};

export default function FormHeading({
  heading,
  withBackButton = false,
}: Props) {
  const { handleBackStep } = useUserAuth();
  const { removeQueryParamByKey, getQueryParamByKey } = useQueryParams();

  //? the below function is used to handle back step, if subscription query param exists, we remove it and then handle back step
  async function handleBackStepAsync() {
    if (getQueryParamByKey('subscription')) {
      removeQueryParamByKey('subscription');
      await new Promise<void>((resolve) => {
        handleBackStep();
        resolve();
      });
    }

    handleBackStep();
  }

  return (
    <StyledStack
      direction="row"
      gap={2}
    >
      {withBackButton && (
        <IconButton
          color="tertiarySecondary"
          onClick={handleBackStepAsync}
        >
          <ArrowBackIosNewTwoToneIcon />
        </IconButton>
      )}
      <Text
        variant="textXl"
        weight="semibold"
        component="h2"
      >
        {heading}
      </Text>
    </StyledStack>
  );
}

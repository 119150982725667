import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import Dropdown from '@components/invitation-form/components/dropdown';
import ListItems from '@components/invitation-form/components/dropdown-list-items';
import FieldSetWrapper from '@components/invitation-form/components/FieldSetWrapper';
import CONFIG from '@components/invitation-form/config';
import emailSchema from '@components/invitation-form/schema';
import {
  StyledListItemsStack,
  StyledStackButtons,
  StyledStackFields,
} from '@components/invitation-form/styles';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

import useCreateInvitation from '@hooks/useCreateInvitation';

import { useTranslation } from '@desygner/ui-common-translation';

import { RoleType } from '@shared-types/memberships';

import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';
import MailIcon from '~icons/knowz-iconify/mail-rounded';
import PlusIcon from '~icons/knowz-iconify/plus';

import { faker } from '@faker-js/faker';
import { yupResolver } from '@hookform/resolvers/yup';
import PQueue from 'p-queue';
import { useForm } from 'react-hook-form';

const defaultValues = {
  email: '',
};

const pQ = new PQueue({ concurrency: 5 });

type Props = {
  onSubmitHandler?: VoidFunction;
};

export default function InvitationForm({ onSubmitHandler }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [role, setRole] = useState<RoleType>(CONFIG.DEFAULT_VALUE);
  const [formState, setFormState] = useState<
    'submitting' | 'submitted' | 'disabled' | null
  >(null);
  const { handleSetMemberToInvite, handleUpdateMember, membersToInvite } =
    useWorkspaceSetup();
  const methods = useForm({
    resolver: yupResolver(emailSchema),
    defaultValues,
  });
  const { createInvitationAsync } = useCreateInvitation();

  const {
    getValues,
    setError,
    resetField,
    handleSubmit,
    formState: { errors },
  } = methods;

  async function onSubmit() {
    setFormState('submitting');
    membersToInvite.forEach((member) => {
      pQ.add(
        async () =>
          await createInvitationAsync({
            email: member.email,
            role: member.role,
          })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              handleUpdateMember({
                ...member,
                isInvitationSent: true,
              });
              onSubmitHandler && onSubmitHandler();
            }),
      );
    });

    pQ.on('idle', () => {
      setFormState('submitted');
    });
  }

  function handleDropdownChange(role: RoleType) {
    setRole(role);
  }

  function handleAddAnotherSetOfFields() {
    //? check if the email is already there
    if (membersToInvite.some((member) => member.email === getValues('email'))) {
      return setError('email', {
        type: 'validate',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.duplication',
          {
            defaultValue: 'This email is already in the list',
          },
        ),
      });
    }
    handleSetMemberToInvite({
      id: faker.string.uuid(),
      email: getValues('email'),
      role,
      isInvitationSent: false,
    });
    resetField('email');
  }

  function handleDisableSendInvitesButtonImparatively(value: boolean) {
    setFormState(value ? 'disabled' : null);
  }

  return (
    <Container disableGutters>
      <FormProvider
        onSubmit={handleSubmit(handleAddAnotherSetOfFields)}
        methods={methods}
      >
        <Stack sx={{ alignItems: 'flex-start' }}>
          <Tooltip
            //title="sdas" TODO: add CTAs for the tooltip
            title={null}
            placement="top"
            arrow
          >
            <Stack
              direction="row"
              gap={1}
              sx={{ alignItems: 'center' }}
            >
              <Text
                variant="textMd"
                weight="semibold"
              >
                {t('page.workspace.createWorkspaceModal.step3.form.title', {
                  defaultValue: 'Invite team members',
                })}
              </Text>
              <HelpRoundedIcon
                color={theme.palette.forground.quaternary}
                fontSize="0.8em"
              />
            </Stack>
          </Tooltip>
          <Text
            variant="textSm"
            color="text.tertiary"
          >
            {t('page.workspace.createWorkspaceModal.step3.form.description', {
              defaultValue:
                ' Get your projects up and running faster by inviting your team to collaborate.',
            })}
          </Text>
        </Stack>
        <StyledStackFields gap={3}>
          <StyledListItemsStack gap={3}>
            <ListItems
              handleDisableSendInvitesButtonImparatively={
                handleDisableSendInvitesButtonImparatively
              }
            />
          </StyledListItemsStack>
          <FieldSetWrapper>
            <RHFInputField
              name="email"
              fullWidth
              error={!!errors.email?.message}
              type="text"
              startAdornment={<MailIcon />}
              placeholder={t(
                'page.workspace.createWorkspaceModal.step3.form.email.placeholder',
                {
                  defaultValue: 'Ex: member@gmail.com',
                },
              )}
            />
            <Dropdown
              role={CONFIG.DEFAULT_VALUE}
              roleItems={CONFIG.ROLE_ITEMS}
              onChange={handleDropdownChange}
            />
          </FieldSetWrapper>
        </StyledStackFields>
        <StyledStackButtons direction="row">
          <ButtonBase
            disableRipple
            disableTouchRipple
            onClick={handleSubmit(handleAddAnotherSetOfFields)}
          >
            <PlusIcon />
            Add another
          </ButtonBase>
          <LoadingButton
            size="small"
            disabled={formState === 'disabled' || formState === 'submitting'}
            variant="contained"
            onClick={onSubmit}
            startIcon={<MailIcon />}
          >
            {t(
              'page.workspace.createWorkspaceModal.step3.form.button.sendInvites',
              {
                defaultValues: 'Send Invites',
              },
            )}
          </LoadingButton>
        </StyledStackButtons>
      </FormProvider>
    </Container>
  );
}

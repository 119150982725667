import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useLocalStorage from '@hooks/useLocalStorage';

export type ThemeModeType = 'system' | 'dark' | 'light';

type ThemeSettingsContextType = {
  themeMode: ThemeModeType;
  toggleThemeMode: (value: ThemeModeType) => void;
};

export const ThemeSettingsContext =
  createContext<ThemeSettingsContextType | null>(null);

const DEFAULT_THEME_MODE = 'system';

export default function ThemeSettingsProvider({
  children,
}: React.PropsWithChildren) {
  const { value: themeMode, setValue: setThemeMode } =
    useLocalStorage<ThemeModeType>('mode', DEFAULT_THEME_MODE);

  const [systemPrefersDark, setSystemPrefersDark] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  const calculatedThemeMode =
    themeMode === 'system' ? (systemPrefersDark ? 'dark' : 'light') : themeMode;

  const toggleThemeMode = useCallback(
    (value: ThemeModeType) => {
      setThemeMode(value);
    },
    [setThemeMode],
  );

  const memoizedValue = useMemo(
    () => ({ themeMode: calculatedThemeMode, toggleThemeMode }),
    [calculatedThemeMode, toggleThemeMode],
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    function handleChange(event: MediaQueryListEvent) {
      setSystemPrefersDark(event.matches);
    }

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <ThemeSettingsContext.Provider value={memoizedValue}>
      {children}
    </ThemeSettingsContext.Provider>
  );
}

import ConnectorButton from '@components/workspace/setup/sections/steps/connectors/connector-button';
import WithStarIcon from '@components/workspace/setup/sections/steps/connectors/tabs/panels/components/with-star-icon';
import { StyledStack } from '@components/workspace/setup/sections/steps/connectors/tabs/panels/styles';

import BoxIcon from '~icons/knowz-iconify/box-color-tone';
import ConfluenceIcon from '~icons/knowz-iconify/confluence-color-tone';
import DropboxIcon from '~icons/knowz-iconify/dropbox-color-tone';
import GlobIcon from '~icons/knowz-iconify/glob-color-tone';
import MailIcon from '~icons/knowz-iconify/mail-color-tone';
import MsSharepointIcon from '~icons/knowz-iconify/ms-sharepoint-color-tone';
import OneDriveIcon from '~icons/knowz-iconify/onedrive-color-tone';
import SlackIcon from '~icons/knowz-iconify/slack-color-tone';
import TeamsIcon from '~icons/knowz-iconify/teams-color-tone';
import WeBrandIcon from '~icons/knowz-iconify/we-brand-color-tone';

export default function Num0() {
  return (
    <StyledStack>
      <ConnectorButton
        ConnectorIcon={<DropboxIcon />}
        label="Dropbox"
        onClick={() => {
          alert('Dropbox');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<OneDriveIcon />}
        label="OneDrive"
        onClick={() => {
          alert('OneDrive');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<BoxIcon />}
        label="Box"
        onClick={() => {
          alert('Box');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<MailIcon />}
        label="Mail"
        onClick={() => {
          alert('Mail');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<GlobIcon />}
        label="Glob"
        onClick={() => {
          alert('Glob');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<WeBrandIcon />}
        label="WeBrand"
        onClick={() => {
          alert('WeBrand');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<MsSharepointIcon />}
        label="MsSharepoint"
        onClick={() => {
          alert('MsSharepoint');
        }}
      />
      <ConnectorButton
        ConnectorIcon={<ConfluenceIcon />}
        label="Confluence"
        onClick={() => {
          alert('Confluence');
        }}
      />
      <WithStarIcon>
        <ConnectorButton
          ConnectorIcon={<SlackIcon />}
          label="Slack"
          onClick={() => {
            alert('Slack');
          }}
        />
      </WithStarIcon>
      <WithStarIcon>
        <ConnectorButton
          ConnectorIcon={<TeamsIcon />}
          label="Teams"
          onClick={() => {
            alert('Teams');
          }}
        />
      </WithStarIcon>
    </StyledStack>
  );
}

//TODO: add this file to steps folder and call it index.tsx
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import Connectors from '@components/workspace/setup/sections/steps/connectors';
import CreateWorkspace from '@components/workspace/setup/sections/steps/create-workspace';
import Cropper from '@components/workspace/setup/sections/steps/cropper';
import GenerateIcon from '@components/workspace/setup/sections/steps/generate-icon';
import InviteMembers from '@components/workspace/setup/sections/steps/invite-members';
import Upload from '@components/workspace/setup/sections/steps/upload';
import { ActiveStepType } from '@components/workspace/setup/types';

type Props = { [key in ActiveStepType]: JSX.Element };

export default function Steps(steps: Props) {
  const { activeStep } = useWorkspaceSetup();

  return steps[activeStep];
}

Steps.CreateWorkspace = CreateWorkspace;
Steps.Upload = Upload;
Steps.GenerateIcon = GenerateIcon;
Steps.Cropper = Cropper;
Steps.InviteMembers = InviteMembers;
Steps.Connectors = Connectors;

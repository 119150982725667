import { forwardRef } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CircularProgress from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar/circular-progress';
import { StyledBox } from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar/styles';

type Props = {
  onClick: VoidFunction;
  size: number;
  src?: string;
  alt?: string;
  credits?: number;
};

const CreditCircularProgressWithAvatar = forwardRef<HTMLDivElement, Props>(
  ({ onClick, size, alt, src, credits }, ref) => {
    return (
      <StyledBox ref={ref}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '56px',
            width: '56px',
          }}
        >
          <CircularProgress
            size={56}
            value={credits}
          />
        </Box>
        <IconButton
          size="xxlarge"
          onClick={onClick}
        >
          <Avatar
            sx={{ width: size, height: size }}
            src={src}
            alt={alt}
          />
        </IconButton>
      </StyledBox>
    );
  },
);

CreditCircularProgressWithAvatar.displayName =
  'CreditCircularProgressWithAvatar';

export default CreditCircularProgressWithAvatar;

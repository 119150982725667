import CONFIG from '@components/pages/assistants/sections/drawer/add-assistants/config';

import { styles, threads } from '@lib/agent';
import { getDecodedToken } from '@lib/jwt';

import { AppSettingsContextType } from '@providers/AppSettings';

import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import useAuth from '@hooks/useAuth';

import { useTranslation } from '@desygner/ui-common-translation';

import { CreateAssistantThreadType } from '@shared-types/assistants';
import { CreateSearchThreadType } from '@shared-types/search-result/types';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const MAX_THREAD_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

function truncateThreadName(name: string) {
  return name.length > MAX_THREAD_NAME_LENGTH
    ? name.slice(0, MAX_THREAD_NAME_LENGTH - DOTS_LENGTH) + '...'
    : name;
}

const createOneSearchThread = async (
  { userSpecificSettings, handleSetCustomStyle }: AppSettingsContextType,
  threadArgs: CreateSearchThreadType,
) => {
  threadArgs.name = truncateThreadName(threadArgs.name);
  const user = getDecodedToken(window.localStorage.getItem('token'));
  if (!user || !userSpecificSettings?.[user.uuid]) return;

  const { customStyle, searchScope, useCustomStyle } =
    userSpecificSettings[user.uuid]!;

  if (useCustomStyle && customStyle.responseStyle) {
    if (!customStyle.id) {
      await persistCustomStyle();
    } else if (!customStyle.isUpToDate) {
      await updateCustomStyle();
    }

    threadArgs.style = customStyle.id;
  }

  if (searchScope === 'web_only') {
    threadArgs.allKnowledgeBase = true;
  } else if (searchScope === 'web_kb') {
    threadArgs.publicKnowledge = true;
  } else if (searchScope === 'kb_only') {
    threadArgs.publicKnowledge = false;
  }

  return await threads.createOneThread(threadArgs);

  async function persistCustomStyle() {
    const {
      data: { id },
    } = await styles.create({
      instructions: '',
      voice: CONFIG.COMMUNICATION_STYLES[0].suggestions[0],
      personality: CONFIG.COMMUNICATION_STYLES[1].suggestions[0],
      responseStyle: customStyle.responseStyle,
    });

    customStyle.id = id;
    customStyle.isUpToDate = true;
    handleSetCustomStyle(customStyle);
  }

  async function updateCustomStyle() {
    await styles.updateOne(customStyle.id!, {
      responseStyle: customStyle.responseStyle,
    });

    customStyle.isUpToDate = true;
    handleSetCustomStyle(customStyle);
  }
};

const createOneAssistantThread = async (
  threadArgs: CreateAssistantThreadType,
) => {
  threadArgs.name = truncateThreadName(threadArgs.name);
  return await threads.createOneThread(threadArgs);
};

export function useCreateOneSearchThread() {
  const { t } = useTranslation();
  const appSettings = useAppSettings();
  const searchScope = useAppSelector((state) => state.searchResult.scope);
  const { me } = useAuth();

  return useMutation({
    mutationFn: (threadArgs: CreateSearchThreadType) => {
      if (!me) {
        throw new Error('User not found');
      }

      if (!appSettings.userSpecificSettings?.[me.uuid]) {
        throw new Error('User settings not found');
      }

      const newUserSettings = {
        ...appSettings,
        userSpecificSettings: {
          [me.uuid]: {
            ...appSettings.userSpecificSettings[me.uuid],
            searchScope,
          },
        },
      } as AppSettingsContextType;

      return createOneSearchThread(newUserSettings, threadArgs);
    },
    mutationKey: ['createOneSearchThread'],
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(error);
    },
  });
}

export function useCreateOneAssistantThread() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createOneAssistantThread,
    mutationKey: ['createOneAssistantThread'],
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(error);
    },
  });
}

import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledGradientBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(37.5),
  height: theme.spacing(30),
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.createBrandGradients.secondary({
    alpha:
      theme.palette.mode === 'light'
        ? alpha(theme.palette['Component colors/Alpha/alpha-white-100'], 0.5)
        : alpha(theme.palette['Component colors/Alpha/alpha-white-100'], 0.6),
  }),
  borderRadius: theme.shape.borderRadius,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  width: theme.spacing(16),
  height: theme.spacing(16),
  background: 'rgba(12, 14, 18, 0.3)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `0.75px solid ${alpha(theme.palette['Component colors/Alpha/alpha-black-100'], 0.12)}`,
}));

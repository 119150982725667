import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import InvitationForm from '@components/invitation-form';
import ButtonWrapper from '@components/workspace/setup/components/button-wrapper';
import Header from '@components/workspace/setup/components/header';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import FeaturedIcon from '@components/workspace/setup/sections/steps/components/featured-icon';
import StyledBoxWrapper from '@components/workspace/setup/sections/steps/invite-members/styles';

import { useTranslation } from '@desygner/ui-common-translation';

import CubeIcon from '~icons/knowz-iconify/cube';

export default function InviteMembers() {
  const { handleGoToPreviousStep, handleGoToStep } = useWorkspaceSetup();
  const { t } = useTranslation();

  function handleNext() {
    handleGoToStep('connectors');
  }

  return (
    <>
      <Header
        Icon={
          <FeaturedIcon>
            <CubeIcon />
          </FeaturedIcon>
        }
        title={t('page.workspace.createWorkspaceModal.step3.title', {
          defaultValue: 'Knowledge Base Invitations',
        })}
        subtitle={t('page.workspace.createWorkspaceModal.step3.subtitle', {
          defaultValue:
            'Knowz makes collaboration easy- invite coworkers, family, or friends to create and share Knowledge Bases tailored to your needs.',
        })}
      />
      <StyledBoxWrapper>
        <InvitationForm />
      </StyledBoxWrapper>
      <ButtonWrapper>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ButtonBase
            disableRipple
            disableTouchRipple
            // onClick={handleSkipStep}
          >
            {t('page.workspace.createWorkspaceModal.skipButton', {
              defaultValue: 'Skip step',
            })}
          </ButtonBase>
          <Stack
            direction="row"
            gap={3}
          >
            <Button
              size="large"
              onClick={handleGoToPreviousStep}
            >
              {t('page.workspace.createWorkspaceModal.backButton', {
                defaultValue: 'Back',
              })}
            </Button>
            <Button
              onClick={handleNext}
              size="large"
              variant="contained"
            >
              {t('page.workspace.createWorkspaceModal.nextButton', {
                defaultValue: 'Next',
              })}
            </Button>
          </Stack>
        </Stack>
      </ButtonWrapper>
    </>
  );
}

import { styled } from '@mui/material';
import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';

export const StyledIconContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-around',
  height: 26,
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.grey[600],
  padding: 2,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  [`& .${formControlLabelClasses.label}`]: {
    flexGrow: 1,
  },
}));

export const StyledRadioLabel = styled(Stack)(({ theme }) => ({
  gap: 8,
  flexDirection: 'row',
  alignItems: 'center',
  svg: {
    color: theme.palette['Colors/Foreground/fg-quaternary (500)'],
  },
}));

import { RoleType } from '@shared-types/memberships';

const ROLE_ITEMS: Array<{
  value: RoleType;
  label: string;
  subtitle: string;
  content: string[];
}> = [
  {
    value: 'contributor',
    label: 'Contributor',
    subtitle:
      'Adds and manages their own content while respecting folder permissions.',
    content: [
      'Can add new data and sync files from connectors.',
      'Can edit and delete only their own shared data.',
      'Access to ring-fenced folders depends on permissions set by Admins. ',
    ],
  },
  {
    value: 'viewer',
    label: 'Viewer',
    subtitle:
      'Read-only access for those who just need to view and search content.',
    content: [
      'Can add new data and sync files from connectors',
      'Can edit and delete only their own shared data.',
      'Access to ring-fenced folders depends on permissions set by Admins.',
    ],
  },
  {
    value: 'editor',
    label: 'Editor',
    subtitle: 'Manage all content and permissions within the Knowledge Base.',
    content: [
      'Can create, edit, and delete all data.',
      'Can manage ring-fencing for folders, restricting edit and view permissions.',
      'Can invite new Contributors or Viewers.',
    ],
  },
  {
    value: 'owner',
    label: 'Owner',
    subtitle:
      'This is the Knowledge Base owner - they have super admin access and have all the powers of an editor plus they manage billing, invite and remove editors and can manage all data and files including those uploaded by editors.',
    content: [
      'Can create, edit, and delete all data in folders, overriding ring fencing set by other editors.',
      'Can manage ring-fencing for folders, restricting edit and view permissions.',
      'Can invite new Contributors or Viewers and Editors.',
      'Manage billing and Knowledge base settings.',
    ],
  },
];

const DEFAULT_VALUE: Extract<RoleType, 'contributor'> = 'contributor';

const CONFIG = {
  ROLE_ITEMS,
  DEFAULT_VALUE,
} as const;

export default CONFIG;

import { useMemo } from 'react';

import useAuth from '@hooks/useAuth';

import useGetWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspacesById';

export default function useGetOwnedWorkspaces() {
  const { me } = useAuth();

  const { workspaceData, isWorkspaceFetching, hasWorkspaceError } =
    useGetWorkspaces(me?.uuid);

  const ownedWorkspacesData = useMemo(
    () =>
      (workspaceData || []).filter((workspace) => workspace.role === 'owner'),
    [workspaceData],
  );

  return {
    ownedWorkspacesData,
    isWorkspaceFetching,
    hasWorkspaceError,
  };
}

import Divider from '@mui/material/Divider';

import {
  StyledFooterBox,
  StyledHeaderContainer,
  StyledHeaderIconBox,
} from '@components/settings/styles';
import { TabItemType } from '@components/settings/types';
import Text from '@components/text';

import VoiceIcon from '~icons/knowz-iconify/bubble-message-smile-rounded';
import AccountIcon from '~icons/knowz-iconify/credit-smart';
import KnowledgeIcon from '~icons/knowz-iconify/cube';
import SearchIcon from '~icons/knowz-iconify/search-smart';
import GeneralIcon from '~icons/knowz-iconify/settings';

enum SETTINGS_TAB_ENUM {
  GENERAL = 0,
  SEARCH = 1,
  ACCOUNT = 2,
  VOICE = 3,
  MICROPHONE = 4,
  KNOWLEDGE = 5,
}

export const TAB_ITEMS: TabItemType[] = [
  {
    id: SETTINGS_TAB_ENUM.GENERAL,
    label: 'General',
    icon: <GeneralIcon fontSize={20} />,
  },
  {
    id: SETTINGS_TAB_ENUM.SEARCH,
    label: 'Search',
    icon: <SearchIcon fontSize={20} />,
  },
  // {
  //   id: SettingsPage.ACCOUNT,
  //   label: 'Account',
  //   icon: <AccountIcon />,
  // },

  // {
  //   id: SettingsPage.VOICE,
  //   label: 'Voice',
  //   icon: <VoiceIcon />,
  // },
  // {
  //   id: SettingsPage.MICROPHONE,
  //   label: 'Microphone',
  //   icon: <VoiceIcon />,
  // },
  // {
  //   id: SettingsPage.KNOWLEDGE,
  //   label: 'Knowledge Base',
  //   icon: <KnowledgeIcon />,
  // },
];

type Props = {
  title: string;
  children?: React.ReactNode;
};
function FormHeader({ children, title }: Props) {
  return (
    <>
      <StyledHeaderContainer>
        <Text
          variant="textLg"
          weight="semibold"
        >
          {title}
        </Text>

        <StyledHeaderIconBox>{children}</StyledHeaderIconBox>
      </StyledHeaderContainer>
      <Divider />
    </>
  );
}

const CONFIG = {
  TAB_ITEMS,
  FormHeader,
};

export default CONFIG;

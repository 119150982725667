import { Theme } from '@mui/material';

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none!important',
          backgroundColor: theme.palette.background.primary,
          padding: theme.spacing(3),
          '&.MuiPaper-rounded': {
            borderRadius: theme.shape.borderRadius * 3,
          },
        },
      },
    },
  };
}

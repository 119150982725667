import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default StyledStack;

import { Box } from '@mui/material';

import StarIcon from '~icons/knowz-iconify/star';

export default function WithStarIcon({ children }: React.PropsWithChildren) {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          top: 8,
          right: 7,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          color: 'primary.main',
          fontSize: '0.75rem',
          svg: {
            filter: 'drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.45))',
          },
        }}
      >
        <StarIcon />
      </Box>
      {children}
    </Box>
  );
}

import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import WorkspacesPopover from '@layouts/main/sections/toolbar/components/workspaces-popover';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import Items from '@layouts/main/sections/toolbar/mini/desktop/Items';
import {
  StyledDesktopMiniToolbar,
  StyledList,
} from '@layouts/main/sections/toolbar/mini/desktop/styles';
import { StyledMiniDesktopToolbarGridItem } from '@layouts/main/sections/toolbar/styles';

export default function DesktopMiniToolbar() {
  const {
    toolbarExpansionStatus,
    handleDisplayExpandedButton,
    handleHideExpandedButton,
  } = useToolbar();

  const isToolbarExpanded = toolbarExpansionStatus === 'off';

  return (
    <StyledMiniDesktopToolbarGridItem
      component={Fade}
      in={isToolbarExpanded}
      onMouseEnter={handleDisplayExpandedButton}
      onMouseLeave={handleHideExpandedButton}
    >
      <StyledDesktopMiniToolbar disableGutters>
        <StyledList
          disablePadding
          component={Stack}
        >
          <WorkspacesPopover type="collapsed toolbar" />
          <Items />
        </StyledList>
        <ToolbarExpansionButton />
      </StyledDesktopMiniToolbar>
    </StyledMiniDesktopToolbarGridItem>
  );
}

import { styled, SvgIcon } from '@mui/material';

const StyledSvg = styled(SvgIcon)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  zIndex: -1,
}));

export default StyledSvg;
